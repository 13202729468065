import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux"; // REDUX
import { useTranslation } from "react-i18next";
import CustomBlock from "../../../customComponents/CustomBlock";
import { fr } from 'date-fns/esm/locale'
import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import subMonths from "date-fns/subMonths";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import isSameMonth from "date-fns/isSameMonth";
import isSameDay from "date-fns/isSameDay";
import toDate from "date-fns/toDate";
import format from "date-fns/format";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import './Calendar.css';
import { useState } from "react";
import { setAddTaskOpen, setAnalysisNumberPlanning, setAnalysisTypePlanning, setAssessmentNumber, setDateEndPlanning, setDateStartPlanning, setHasSaturdayPlanning, setHasSundayPlanning, setHourEndPlanning, setHourStartPlanning, setIsTaskSelected, setOldDateEndPlanning, setOldDateStartPlanning, setOldHourEndPlanning, setOldHourStartPlanning, setSelectedRestaurantPlanning, setTypePlanning } from "../../../../actions_reducers/actions/planning.action";
import CustomButton from "../../../customComponents/CustomButton";
import CustomScrollDiv from "../../../customComponents/CustomScrollBar";
import 'moment/locale/fr';
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);


const PlanningCalendar = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const modalRef = useRef(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMeetingsOpen, setModalMeetingsOpen] = useState(false);
    const [selectedWeek, setSelectedWeek] = useState(null);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setModalOpen(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [modalRef]);
    const isWeekInTask = (week, year, task) => {
        let weekStartTask = moment(task.dateStart).week();
        let weekEndTask = moment(task.dateEnd).week();
        let yearStartTask = moment(task.dateStart).year();
        let yearEndTask = moment(task.dateEnd).year();

        if (weekStartTask <= week && weekEndTask >= week && yearStartTask === year && yearEndTask === year) {
            return true;
        }
        else if (yearStartTask < year && yearEndTask === year && weekStartTask >= week && weekEndTask >= week) {
            return true;
        }
        else if (yearEndTask > year && yearStartTask === year && weekStartTask <= week) {
            return true;
        }
        else return false;
    }

    const resetTaskValues = () => {
        dispatch(setSelectedRestaurantPlanning(null));
        dispatch(setTypePlanning(null));
        dispatch(setAnalysisNumberPlanning(null));
        dispatch(setAssessmentNumber(null));
        dispatch(setHasSaturdayPlanning(false));
        dispatch(setHasSundayPlanning(false));
        dispatch(setDateStartPlanning(null));
        dispatch(setDateEndPlanning(null));
        dispatch(setOldDateStartPlanning(null));
        dispatch(setOldDateEndPlanning(null));
        dispatch(setHourStartPlanning(null));
        dispatch(setOldHourEndPlanning(null));
        dispatch(setOldHourStartPlanning(null));
        dispatch(setHourEndPlanning(null));
        dispatch(setAddTaskOpen(false));
        dispatch(setIsTaskSelected(false));
        dispatch(setAnalysisTypePlanning(null));
    }
    const updateSelectedAnalysis = (analysis, index) => {
        let values = analysis[index];
        dispatch(setIsTaskSelected(true));
        dispatch(setAddTaskOpen(true));
        dispatch(setSelectedRestaurantPlanning(values.restaurant_name));
        dispatch(setDateStartPlanning(values.dateStart));
        dispatch(setDateEndPlanning(values.dateEnd));
        dispatch(setOldDateStartPlanning(values.dateStart));
        dispatch(setOldDateEndPlanning(values.dateEnd));
        dispatch(setHourStartPlanning(values.hourStart));
        dispatch(setHourEndPlanning(values.hourEnd));
        dispatch(setOldHourStartPlanning(values.hourStart));
        dispatch(setOldHourEndPlanning(values.hourEnd));
        dispatch(setAnalysisNumberPlanning(values.analysisNumber));
        dispatch(setAssessmentNumber(values.assessmentNumber));
        dispatch(setHasSaturdayPlanning(values.hasSaturday));
        dispatch(setHasSundayPlanning(values.hasSunday));
        dispatch(setTypePlanning(values.type));
        dispatch(setAnalysisTypePlanning(values.analysisType));
    }

    const getContinuousAnalyzes = (analyzes) => {
        let tmpDiv = [];
        let result = [];
        for (let i = 0; i < analyzes.length; i++) {
            if (analyzes[i].analysisType === "Continue") {
                let restaurant_name = analyzes[i].restaurant_name;
                tmpDiv.push(
                    <div style={{ width: "29.33%", height: "100%", display: "flex", justifyContent: "center" }}>
                        <CustomButton
                            id={i}
                            width={"80%"} height={"60%"} marginLeft={"2%"} marginRight={"2%"}
                            color={"#949494"}
                            fontSize={"0.8vw"}
                            backgroundColor={"#B5DAF1"}
                            onClick={(e) => updateSelectedAnalysis(analyzes, e.target.id)}>
                            {restaurant_name}
                        </CustomButton>
                    </div>
                )
            }
        }
        let size = 3;
        for (let i = 0; i < tmpDiv.length; i += size) {
            result.push(<div style={{
                width: "100%",
                height: "25%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>{tmpDiv.slice(i, i + size)}</div>);
        }
        return result
    }

    const getClassicalAnalyzes = (analyzes) => {
        let tmpDiv = [];
        let result = [];
        for (let i = 0; i < analyzes.length; i++) {
            if (analyzes[i].analysisType === "Classique") {
                let restaurant_name = analyzes[i].restaurant_name;
                tmpDiv.push(
                    <div style={{ width: "29.33%", height: "100%", display: "flex", justifyContent: "center" }}>
                        <CustomButton
                            id={i}
                            width={"80%"} height={"60%"} marginLeft={"2%"} marginRight={"2%"}
                            color={"#949494"}
                            fontSize={"0.8vw"}
                            backgroundColor={"#B5DAF1"}
                            onClick={(e) => updateSelectedAnalysis(analyzes, e.target.id)}>
                            {restaurant_name}
                        </CustomButton>
                    </div>
                )
            }
        }
        let size = 3;
        for (let i = 0; i < tmpDiv.length; i += size) {
            result.push(<div style={{
                width: "100%",
                height: "25%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>{tmpDiv.slice(i, i + size)}</div>);
        }
        return result
    }
    const closeModal = () => {
        resetTaskValues();
        setModalOpen(false);
        setModalMeetingsOpen(false)
    }
    const shownAnalyzes = (analyzes) => {
        let result = analyzes;
        let currentClient = props.planningState.selectedClientDropdown
        let currentRestaurant = props.planningState.selectedRestaurantDropdown
        if (currentRestaurant != null)
            result = analyzes.filter(a => a.restaurant_name === currentRestaurant)
        else if (currentClient != null) {
            let restaurantList = JSON.parse(JSON.stringify(props.restaurants)).filter(a => a.clientName === currentClient);
            result = analyzes.filter(a => restaurantList.find(b => a.restaurant_name === b.restaurantName));
        }
        return result;
    }
    const getModalAnalyzes = () => {
        let week = selectedWeek;
        let year = moment(currentDate).year();
        const tasks = JSON.parse(JSON.stringify(props.tasks));
        let currentAnalyzesTmp = tasks.filter(a => isWeekInTask(week, year, a) && a.type === "Analyse");
        let currentAnalyzes = shownAnalyzes(currentAnalyzesTmp)
        let dateStart = moment().isoWeekYear(year).isoWeek(week).startOf('week').format("DD-MM-YYYY");
        let dateEnd = moment().isoWeekYear(year).isoWeek(week).endOf('week').format("DD-MM-YYYY");
        return (
            <CustomBlock
                height={"60vh"}
                width={"50vw"}
                top={"7vh"}
                left={"5vw"}
                position={"absolute"}
                backgroundColor={"#f0f0f0"}
                zIndex={201}
                paddingTop={"2%"}
                paddingRight={"2%"}
                paddingLeft={"2%"}
                paddingBottom={"2%"}>
                <div
                    ref={modalRef} style={{ width: "100%", height: "100%" }}>

                    <div style={{ height: "10%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", color: "#666666", fontWeight: "bold", fontSize: "1.1vw" }}>
                        {t('components.analyzesFromTo', { dateStart: dateStart, dateEnd: dateEnd })}
                    </div>
                    <div style={{ height: "10%", width: "100%", display: "flex", justifyContent: "center", color: "#666666", fontSize: "1.1vw" }}>
                        {t('components.continuousAnalyzes')}
                    </div>
                    <div style={{ height: "30%", width: "100%" }}>
                        <CustomScrollDiv update={Math.random()}>
                            {getContinuousAnalyzes(currentAnalyzes)}
                        </CustomScrollDiv>
                    </div>
                    <div style={{ height: "10%", width: "100%", display: "flex", justifyContent: "center", color: "#666666", fontSize: "1.1vw" }}>
                        {t('components.classicalAnalyzes')}
                    </div>
                    <div style={{ height: "30%", width: "100%" }}>
                        <CustomScrollDiv update={Math.random()}>
                            {getClassicalAnalyzes(currentAnalyzes)}
                        </CustomScrollDiv>

                    </div>
                    <div style={{ height: "10%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButton
                            width={"30%"}
                            height={"60%"}
                            color={"#fff"}
                            onClick={() => closeModal()}>{t('buttons.close')}</CustomButton>
                    </div>
                </div>
            </CustomBlock>
        )
    }
    const hasMeetingsType = (meetings, type) => {
        if (meetings.find(a => a.type === type))
            return true;
        else return false;
    }
    const getHeight = (meetings) => {
        let numberMeetings = 0;
        if (hasMeetingsType(meetings, "Installation"))
            numberMeetings += 1;
        if (hasMeetingsType(meetings, "Bilan"))
            numberMeetings += 1;
        if (hasMeetingsType(meetings, "Maintenance"))
            numberMeetings += 1;
        let heightTitle = "";
        let heightDiv = "";
        if (numberMeetings === 1) {
            heightTitle = "10%";
            heightDiv = "70%"
        }
        else if (numberMeetings === 2) {
            heightTitle = "10%";
            heightDiv = "30%"
        }
        else if (numberMeetings === 3) {
            heightTitle = "6%";
            heightDiv = "20%";
        }
        return {
            heightDiv: heightDiv,
            heightTitle: heightTitle
        }
    }
    const getMeetingsRows = (currentMeetings, type) => {
        let result = [];
        for (const [index, meeting] of currentMeetings.entries()) {
            if (meeting.type === type)
                result.push(
                    <div style={{ height: "20%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ height: "100%", width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomButton
                                id={index}
                                width={"90%"}
                                height={"4vh"}
                                color={"#949494"}
                                fontSize={"0.8vw"}
                                backgroundColor={"#ADD08C"}
                                onClick={(e) => updateSelectedAnalysis(currentMeetings, e.target.id)}>
                                {meeting.restaurant_name}
                            </CustomButton>
                        </div>
                        <div style={{ height: "100%", width: "20%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1vw", color: "#666666" }}>
                            {t('components.fromToHour', { hourStart: meeting.hourStart, hourEnd: meeting.hourEnd })}
                        </div>
                    </div>
                )
        }
        return result;
    }
    const getModalMeetings = () => {
        let day = moment(selectedDate).format("YYYY-MM-DD");
        const tasks = JSON.parse(JSON.stringify(props.tasks));
        let currentMeetings = tasks.filter(a => moment(a.dateStart).format("YYYY-MM-DD") === day && a.type !== "Analyse");
        let heightDiv = getHeight(currentMeetings).heightDiv;
        let heightTitle = getHeight(currentMeetings).heightTitle;
        return (
            <CustomBlock
                height={"60vh"}
                width={"50vw"}
                top={"7vh"}
                left={"5vw"}
                position={"absolute"}
                backgroundColor={"#f0f0f0"}
                zIndex={201}
                paddingTop={"2%"}
                paddingRight={"2%"}
                paddingLeft={"2%"}
                paddingBottom={"2%"}>
                <div style={{ height: "10%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", color: "#666666", fontWeight: "bold", fontSize: "1.1vw" }}>
                    {t('components.meetingsToDate', { date: moment(day).format("DD-MM-YYYY") })}
                </div>
                {hasMeetingsType(currentMeetings, "Installation") &&
                    <>
                        <div style={{ height: heightTitle, width: "100%", display: "flex", justifyContent: "center", color: "#666666", fontSize: "1.1vw" }}>
                            {t('components.installation')}
                        </div>
                        <div style={{ height: heightDiv, width: "100%" }}>
                            <CustomScrollDiv update={Math.random()}>
                                {getMeetingsRows(currentMeetings, "Installation")}
                            </CustomScrollDiv>
                        </div>
                    </>
                }
                {hasMeetingsType(currentMeetings, "Bilan") &&
                    <>
                        <div style={{ height: heightTitle, width: "100%", display: "flex", justifyContent: "center", color: "#666666", fontSize: "1.1vw" }}>
                            {t('components.assessment')}
                        </div>
                        <div style={{ height: heightDiv, width: "100%" }}>
                            <CustomScrollDiv update={Math.random()}>
                                {getMeetingsRows(currentMeetings, "Bilan")}
                            </CustomScrollDiv>
                        </div>
                    </>
                }
                {hasMeetingsType(currentMeetings, "Maintenance") &&
                    <>
                        <div style={{ height: heightTitle, width: "100%", display: "flex", justifyContent: "center", color: "#666666", fontSize: "1.1vw" }}>
                            {t('components.maintenance')}
                        </div>
                        <div style={{ height: heightDiv, width: "100%" }}>
                            <CustomScrollDiv update={Math.random()}>
                                {getMeetingsRows(currentMeetings, "Maintenance")}
                            </CustomScrollDiv>
                        </div>
                    </>
                }
                <div style={{ height: "10%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CustomButton
                        width={"30%"}
                        height={"60%"}
                        color={"#fff"}
                        onClick={() => closeModal()}>{t('buttons.close')}</CustomButton>
                </div>
            </CustomBlock>
        )
    }

    const getNumberTasksForWeek = (week, year) => {
        const tasks = JSON.parse(JSON.stringify(props.tasks));
        let currentAnalyzesTmp = tasks.filter(a => isWeekInTask(week, year, a) && a.type === "Analyse");
        let currentAnalyzes = shownAnalyzes(currentAnalyzesTmp)
        let numberTasks = currentAnalyzes.length;
        return numberTasks;
    }

    const header = () => {
        const dateFormat = "MMMM yyyy";
        return (
            <div className="header row flex-middle">
                <div className="column col-start">
                    <div className="icon" onClick={prevMonth}>
                        chevron_left
                    </div>
                </div>
                <div className="column col-center">
                    <span>{moment(currentDate).format(dateFormat)}</span>
                </div>
                <div className="column col-end">
                    <div className="icon" onClick={nextMonth}>
                        chevron_right
                    </div>
                </div>
            </div>
        );
    };
    const nextMonth = () => {
        setCurrentDate(addMonths(currentDate, 1));
    };
    const prevMonth = () => {
        setCurrentDate(subMonths(currentDate, 1));
    };

    const daysOfWeek = () => {
        const dateFormat = "E";
        const days = [];
        const monthStart = startOfMonth(currentDate);
        let startDate = startOfWeek(monthStart);
        for (let i = 1; i < 8; i++) {
            days.push(
                <div className="column col-center" key={i}>
                    {format(addDays(startDate, i), dateFormat, { locale: fr })}
                </div>
            );
        }
        return <div className="days row">{days}</div>;
    };
    const isRestaurantDisplayed = (restaurant_name) => {
        let currentClient = props.planningState.selectedClientDropdown
        let currentRestaurant = props.planningState.selectedRestaurantDropdown;
        if (restaurant_name === currentRestaurant) {
            return true;
        }
        else if (currentClient != null) {
            let restaurantList = JSON.parse(JSON.stringify(props.restaurants)).filter(a => a.clientName === currentClient);
            if (restaurantList.find(a => a.restaurantName === restaurant_name)) return true
            else return false
        }
        else if (currentRestaurant == null && currentClient == null) return true
        else return false
    }
    const hasMeetingDay = (day) => {
        const tasks = JSON.parse(JSON.stringify(props.tasks));
        let currentMeetings = tasks.filter(a =>
            moment(a.dateStart).format("YYYY-MM-DD") === moment(day).format("YYYY-MM-DD")
            &&
            a.type !== "Analyse"
            && isRestaurantDisplayed(a.restaurant_name)
        );
        if (currentMeetings.length > 0)
            return true;
        else return false;
    }
    const getDayMeetings = (day) => {
        const tasks = JSON.parse(JSON.stringify(props.tasks));
        let currentMeetings = tasks.filter(a => moment(a.dateStart).format("YYYY-MM-DD") === moment(day).format("YYYY-MM-DD") && a.type !== "Analyse");
        return t('components.meetings', { number: currentMeetings.length });
    }
    const showTasks = (type) => {
        if (props.planningState.selectedTaskType === "all")
            return true;
        else if (props.planningState.selectedTaskType === type)
            return true;
        else return false;
    }
    const cells = () => {
        const monthStart = startOfMonth(currentDate);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
        const dateFormat = "d";
        const rows = [];
        let days = [];
        let day = addDays(startDate, 1);
        let formattedDate = "";
        let year = moment(day).year();
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                days.push(
                    <div
                        className={`column cell ${!isSameMonth(day, monthStart)
                            ? "disabled"
                            : isSameDay(day, selectedDate)
                                ? "selected"
                                : ""
                            }`}
                        style={{ overflow: "visible" }}
                        key={day}
                        onClick={() => onDateClick(toDate(cloneDay))}
                    >
                        {hasMeetingDay(day) && showTasks("meetings") &&
                            <div
                                id={day}
                                style={{
                                    position: "absolute", color: "#fff", bottom: props.planningState.selectedTaskType === "meetings" ? "40%" : "12%", right: "5%", borderRadius: "5px", width: "90%", height: "25%", backgroundColor: "#90c261",
                                    display: "flex", justifyContent: "center", alignItems: "center", fontSize: "0.9vw", cursor: "pointer", zIndex: 1
                                }}
                                onClick={() => {
                                    setSelectedDate(day);
                                    setModalMeetingsOpen(true)
                                }}
                            >
                                {getDayMeetings(day)}
                            </div>
                        }
                        <div className="number" style={{ zIndex: 10 }}
                            onClick={() => {
                            }}>{formattedDate}</div>
                        <span className="bg">{formattedDate}</span>
                    </div>
                );

                day = addDays(day, 1);
            }
            rows.push(
                <div className="row" key={day}>
                    {getNumberTasksForWeek(moment(day).week() - 1, year) !== 0 && showTasks("analyzes") && <div
                        id={moment(day).week() - 1}
                        style={{
                            height: "25%", width: "100%", backgroundColor: "#68B4E3", position: "absolute", top: "30%", zIndex: 1,
                            color: "#fff", borderRadius: "5px", cursor: "pointer"
                        }}
                        onClick={(e) => {
                            setSelectedWeek(e.target.id);
                            setModalOpen(true)
                        }}>
                        {t('components.currentRestaurantsAnalyzes', { number: getNumberTasksForWeek(moment(day).week() - 1, year) })}
                    </div>}
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="body">{rows}</div>;
    };
    const onDateClick = (date) => {
        setSelectedDate(date)
        dispatch(setAddTaskOpen(!props.addTaskOpen))
    }
    return (
        <CustomBlock
            width={"100%"}
            height={"100%"}
            paddingTop={"1%"}>
            <div className="calendar">
                {modalOpen && getModalAnalyzes()}
                {modalMeetingsOpen && getModalMeetings()}
                <div style={{ color: "#666666" }}>{header()}</div>
                <div style={{ color: "#666666" }}>{daysOfWeek()}</div>
                <div style={{ color: "#666666" }}>{cells()}</div>
            </div>

        </CustomBlock>
    )
}

export default PlanningCalendar;
