import React from "react";
import CustomDropdown from "../../customComponents/CustomDropdown";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import 'moment/locale/fr';
import CustomBlock from "../../customComponents/CustomBlock";
import BarChart from "../../charts/BarChart";
import { getCategoryColor, getFoodsColors } from "../../customComponents/CustomColors";
import { setSelectedGraphValueSearch } from "../../../actions_reducers/actions/search.action";
import CustomScrollDiv from "../../customComponents/CustomScrollBar";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const SearchGraph = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const getAllDates = (startDate, endDate) => {
        const startDay = moment(startDate).format("YYYY-MM-DD"); //BEGIN GET ALL DATES
        const endDay = moment(endDate).format("YYYY-MM-DD");
        const allDates = [];
        var tmp = startDay;
        while (moment(tmp).isBefore(endDay)) {
            allDates.push(moment(tmp).format("YYYY-MM-DD"));
            tmp = moment(tmp).add(1, "days");
        }
        allDates.push(endDay);
        return allDates;
    };

    const getValueData = (value) => {
        switch (props.selectedGraphValue) {
            case 'mass':
                return value.mass;
            case 'cost':
                return value.cost;
            case 'co2Unit':
                return value.co2;
            case 'trays':
                return value.trays
            default:
                return value.mass;
        }
    }

    const getTooltip = (value, name) => {
        switch (props.selectedGraphValue) {
            case 'massByConv':
                return t(`foods.${name}`) + " : " + t('mass', { "value": { "mass": value, "unit": "g" } });
            case 'mass':
                return t(`foods.${name}`) + " : " + t('mass', { "value": { "mass": value, "unit": "kg", fixed: 1 } });
            case 'cost':
                return t(`foods.${name}`) + " : " + t('currency', { "value": { cost: value } });
            case 'co2Unit':
                return t(`foods.${name}`) + " : " + t('mass', { "value": { "mass": value, "unit": "kg", fixed: 1 } });
            case 'trays':
                return t(`foods.${name}`) + " : " + value + t('components.trays');
            default:
                return t(`foods.${name}`) + " : " + t('mass', { "value": { "mass": value, "unit": "g" } });
        }
    }
    const getDateName = (day, analysisNumber) => {
        let aggregate = props.aggregate;
        let date;
        if (aggregate === "day")
            date = moment(day.date).format("YYYY-MM-DD");
        else if (aggregate === "week")
            date = moment(day.date).week();
        if (aggregate === "month")
            date = moment(day.date).format("MMMM")
        else if (aggregate === "year")
            date = moment(day.date).format("YYYY")
        else if (aggregate === "analysis")
            date = t('components.analysisName', { analysisNumber: analysisNumber })
        return date
    }
    const getValues = (day, result, analysisNumber) => {
        let selectedCategories = props.selectedCategories;
        let selectedFoods = props.selectedFoods;

        for (const category of day.categories) {
            if (selectedCategories.find(a => a === category.category)) {
                let date = getDateName(day, analysisNumber);
                let value = getValueData(category);
                let index = result.findIndex(a => a.name === date);
                if (index === -1) {
                    let singleData = {
                        name: date,
                        x: date,
                        y: value,
                        tooltip: '',
                        color: getCategoryColor(category.category)
                    }
                    singleData[category.category] = value
                    singleData["trays" + category.category] = category.trays;
                    singleData['tooltip' + category.category] = getTooltip(value, category.category)
                    result.push(singleData)
                }
                else {
                    if (parseFloat(result[index][category.category]) + parseFloat(value) > result[index].y) result[index].y = parseFloat(result[index][category.category]) + parseFloat(value)
                    result[index]["trays" + category.category] = result[index]["trays" + category.category] ? result[index]["trays" + category.category] + category.trays : category.trays;
                    result[index][category.category] = result[index][category.category] ? parseFloat(result[index][category.category]) + parseFloat(value) : parseFloat(value);
                    result[index]['tooltip' + category.category] = getTooltip(result[index][category.category], category.category)
                }
            }
            for (const food of category.foods) {
                if (selectedFoods.find(a => a === food.nameFood)) {
                    let date = getDateName(day, analysisNumber);
                    let value = getValueData(food);
                    let index = result.findIndex(a => a.name === date);
                    if (index === -1) {
                        let singleData = {
                            name: date,
                            x: date,
                            y: value,
                            tooltip: '',
                            color: getFoodsColors(food.nameFood)
                        }
                        singleData[food.nameFood] = value
                        singleData["trays" + food.nameFood] = food.trays;
                        singleData['tooltip' + food.nameFood] = getTooltip(value, food.nameFood)
                        result.push(singleData)
                    }
                    else {
                        if (parseFloat(result[index][food.nameFood]) + parseFloat(value) > result[index].y) result[index].y = parseFloat(result[index][food.nameFood]) + parseFloat(value)
                        result[index][food.nameFood] = result[index][food.nameFood] ? parseFloat(result[index][food.nameFood]) + parseFloat(value) : parseFloat(value)
                        result[index]["trays" + food.nameFood] = result[index]["trays" + food.nameFood] ? result[index]["trays" + food.nameFood] + food.trays : food.trays;
                        result[index]['tooltip' + food.nameFood] = getTooltip(result[index][food.nameFood], food.nameFood)

                    }
                }
            }
        }
        return result;

    }
    const isValueShown = (analysis, type) => {
        if (type === 'kitchen') {
            if ((props.selectedService === 'total' || analysis.hour === props.selectedService))
                return true
        }
        else {
            if ((props.selectedService === 'total' || analysis.hour === props.selectedService)
                && (props.selectedCam === 'total' || analysis.camValue === props.selectedCam))
                return true;
        }

    }
    const addValueFreeDays = (result, samplesBin) => {
        let selectedCategories = props.selectedCategories;
        let selectedFoods = props.selectedFoods;
        for (let sample of samplesBin) {
            sample.mass = sample.mass * 1000;
            sample.co2 = sample.co2 * 1000;
            let date = getDateName({ date: sample.created_at });
            let value = getValueData(sample);
            if (selectedCategories.find(a => a === sample.category)) {
                let index = result.findIndex(a => a.name === date);
                if (index === -1) {
                    let singleData = {
                        name: date,
                        x: date,
                        y: value,
                        tooltip: '',
                        color: getCategoryColor(sample.created_at)
                    }
                    singleData[sample.category] = value
                    singleData['tooltip' + sample.category] = getTooltip(value, sample.category)
                    result.push(singleData)
                }
                else {
                    if (parseFloat(result[index][sample.category]) + parseFloat(value) > result[index].y) result[index].y = parseFloat(result[index][sample.category]) + parseFloat(value)
                    result[index][sample.category] = result[index][sample.category] ? parseFloat(result[index][sample.category]) + parseFloat(value) : parseFloat(value);
                    result[index]['tooltip' + sample.category] = getTooltip(result[index][sample.category], sample.category)

                }
            }
            if (selectedFoods.find(a => a === sample.nameFood)) {
                let index = result.findIndex(a => a.name === date);
                if (index === -1) {
                    let singleData = {
                        name: date,
                        x: date,
                        y: value,
                        tooltip: '',
                        color: getCategoryColor(sample.created_at)
                    }
                    singleData[sample.nameFood] = value
                    singleData['tooltip' + sample.nameFood] = getTooltip(value, sample.nameFood)
                    result.push(singleData)
                }
                else {
                    if (parseFloat(result[index][sample.nameFood]) + parseFloat(value) > result[index].y) result[index].y = parseFloat(result[index][sample.nameFood]) + parseFloat(value)
                    result[index][sample.nameFood] = result[index][sample.nameFood] ? parseFloat(result[index][sample.nameFood]) + parseFloat(value) : parseFloat(value);
                    result[index]['tooltip' + sample.nameFood] = getTooltip(result[index][sample.nameFood], sample.nameFood)

                }

            }
        }
        return result
    }
    const getDatas = (date, result) => {
        let analyzes = props.analyzes;
        let kitchenDatas = props.kitchenDatas;
        if (props.selectedRoom === 'total' || props.selectedRoom === 'restaurant') {
            for (const analysis of analyzes) {
                let day = analysis.days.find(a => moment(a.date).format("YYYY-MM-DD") === date);
                if (day) {
                    if (isValueShown(analysis))
                        result = getValues(day, result, analysis.analysisNumber)
                }
            }
        }
        if (props.selectedRoom === 'total' || props.selectedRoom === 'kitchen') {
            if (kitchenDatas.length > 0) {
                let days = [];
                for (const analysis of kitchenDatas) {
                    for (const day of analysis.days) {
                        days.push(day.date)
                    }
                }
                for (const analysis of kitchenDatas) {
                    let day = analysis.days.find(a => moment(a.date).format("YYYY-MM-DD") === date);
                    if (days.find(a => a === date) != null) {
                        if (day) {
                            if (isValueShown(analysis, 'kitchen'))
                                result = getValues(day, result, analysis.analysisNumber)
                        }
                    }
                    else if (props.aggregate !== "analysis") {
                        let freeDays = JSON.parse(JSON.stringify(props.samplesBin)).filter(a => moment(a.created_at).format("YYYY-MM-DD") === date);
                        result = addValueFreeDays(result, freeDays)
                    }
                }
            }
            else if (props.aggregate !== "analysis") {
                let freeDays = JSON.parse(JSON.stringify(props.samplesBin)).filter(a => moment(a.created_at).format("YYYY-MM-DD") === date);
                result = addValueFreeDays(result, freeDays)
            }
        }
        return result;
    }
    const getGraphDatas = () => {
        let dateStart = props.dateStart;
        let dateEnd = props.dateEnd;
        let result = [];
        let allKeys = JSON.parse(JSON.stringify(props.selectedCategories)).concat(props.selectedFoods)
        let allDates = getAllDates(dateStart, dateEnd);
        for (const date of allDates) {
            result = getDatas(date, result)
        }
        if (props.cumulativeTotal === false) {
            if (props.selectedGraphValue === "massByConv") {
                for (const item of result) {
                    item.y = 0;
                    for (const category of allKeys) {
                        if (item[category]) {
                            let value = parseFloat(item[category] / (parseFloat(item["trays" + category]) === parseFloat(0) ? props.averageTrays : parseFloat(item["trays" + category])))
                            if (item.y < value) {
                                item.y = value;
                            }
                            item[category] = value;
                            item["tooltip" + category] = getTooltip(item[category], category)
                        }
                    }
                }
            }
            else if (props.selectedGraphValue === "mass" || props.selectedGraphValue === "co2Unit") {
                for (let item of result) {
                    item.y = 0;
                    for (const category of allKeys) {
                        if (item[category]) {
                            let value = parseFloat(item[category]) / 1000
                            if (item.y < value) {
                                item.y = value;
                            }
                            item[category] = value;
                        }
                    }
                }
            }
        }
        else {
            let finalItem = {
                name: "Total",
                x: "Total",
                y: 0,
                tooltip: '',
                color: "#90c261",
                Total: 0
            }
            for (let item of result) {
                item.y = 0;
                for (const category of allKeys) {
                    if (item[category]) {
                        let value = parseFloat(item[category])
                        if (props.selectedGraphValue === "mass" || props.selectedGraphValue === "co2Unit")
                            value = parseFloat(item[category]) / 1000
                        finalItem.y = parseFloat(finalItem.y) + parseFloat(value);
                        finalItem.Total = parseFloat(finalItem.Total) + parseFloat(value);
                    }

                }
            }
            if (props.selectedGraphValue === "mass" || props.selectedGraphValue === "co2Unit")
                finalItem.tooltipTotal = getTooltip(finalItem.y * 1000, "Total")
            else
                finalItem.tooltipTotal = getTooltip(finalItem.y, "Total")
            allKeys = ["Total"]
            result = [finalItem]

        }
        return { result: result, keys: allKeys };
    }
    const updateSelectedGraphValue = (e) => {
        switch (e.target.id) {
            case t('components.massByConv', { unit: props.userState.massUnit === "lbs" ? "oz" : "g" }):
                dispatch(setSelectedGraphValueSearch('massByConv'))
                break;
            case t('components.mass', { unit: props.userState.massUnit === "g" ? "kg" : "lbs" }):
                dispatch(setSelectedGraphValueSearch('mass'))
                break;
            case t('components.cost', { unit: props.userState.costUnit }):
                dispatch(setSelectedGraphValueSearch('cost'))
                break;
            case t('components.co2Unit', { unit: props.userState.massUnit === "g" ? "kg" : "lbs" }):
                dispatch(setSelectedGraphValueSearch('co2Unit'))
                break;
            default: break;
        }
    }
    const getDisplayedValue = () => {
        if (props.selectedGraphValue === 'mass')
            return t('components.mass', { unit: props.userState.massUnit === "g" ? "kg" : "lbs" })
        else if (props.selectedGraphValue === 'cost')
            return t('components.cost', { unit: props.userState.costUnit })
        else if (props.selectedGraphValue === 'co2Unit')
            return t('components.co2Unit', { unit: props.userState.massUnit === "g" ? "kg" : "lbs" })
        else if (props.selectedGraphValue === 'massByConv')
            return t('components.massByConv', { unit: props.userState.massUnit === "lbs" ? "oz" : "g" })
    }
    const getDropdownGraphValue = () => {
        let values = [
            t('components.mass', { unit: props.userState.massUnit === "g" ? "kg" : "lbs" }),
            t('components.cost', { unit: props.userState.costUnit }),
            t('components.co2Unit', { unit: props.userState.massUnit === "g" ? "kg" : "lbs" }),
        ];
        if (props.userState.login === "admin")
            values.unshift(t('components.massByConv', { unit: props.userState.massUnit === "lbs" ? "oz" : "g" }))
        return (
            <CustomDropdown width={"20%"} height={"80%"}
                color={"#666666"}
                backgroundColor={"#D9D9D9"}
                arrowColor={"#666666"}
                values={values}
                displayedValue={getDisplayedValue()}
                onChange={updateSelectedGraphValue} />
        )
    }
    const getLegends = () => {
        let selectedFoods = props.selectedFoods;
        let selectedCategories = props.selectedCategories;
        let result = [];
        if (props.cumulativeTotal === false) {
            for (const cat of selectedCategories) {
                if (cat !== t('components.selectAll'))
                    result.push(
                        <div style={{ height: "3vh", width: "100%", display: "flex" }}>
                            <div style={{ width: "35%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ borderRadius: 15, width: "70%", height: "50%", backgroundColor: getCategoryColor(cat) }} />
                            </div>
                            <div style={{ width: "5%" }} />
                            <div style={{ width: "60%", height: "100%", fontSize: "0.6vw", display: "flex", alignItems: "center" }}>
                                {t(`foods.${cat}`)}
                            </div>
                        </div>
                    )
            }
            for (const food of selectedFoods) {
                if (food !== t('components.selectAll'))
                    result.push(
                        <div style={{ height: "3vh", width: "100%", display: "flex" }}>
                            <div style={{ width: "35%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ borderRadius: 15, width: "70%", height: "50%", backgroundColor: getFoodsColors(food) }} />
                            </div>
                            <div style={{ width: "5%" }} />
                            <div style={{ width: "60%", height: "100%", fontSize: "0.6vw", display: "flex", alignItems: "center" }}>
                                {t(`foods.${food}`)}
                            </div>
                        </div>
                    )
            }
        }
        else {

            result.push(
                <div style={{ height: "3vh", width: "100%", display: "flex" }}>
                    <div style={{ width: "35%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ borderRadius: 15, width: "70%", height: "50%", backgroundColor: getFoodsColors("Total") }} />
                    </div>
                    <div style={{ width: "5%" }} />
                    <div style={{ width: "60%", height: "100%", fontSize: "0.6vw", display: "flex", alignItems: "center" }}>
                        {t(`foods.Total`)}
                    </div>
                </div>
            )
        }
        return result
    }
    return (
        <CustomBlock
            width={"100%"}
            height={"100%"}>
            <div style={{ height: "12%", width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", paddingTop: "1%", paddingLeft: "2%" }}>
                {getDropdownGraphValue()}
            </div>
            <div style={{ width: "100%", height: "88%", display: "flex" }}>
                <div style={{ width: "85%", height: "100%" }}>
                    <BarChart id={"dayGraph"} data={getGraphDatas().result} keys={getGraphDatas().keys} top={0} left={50} right={40} bottom={70} legendRotation={-45} />
                </div>
                <div style={{ width: "15%", height: "85%", marginTop: "2%" }}>
                    <CustomScrollDiv update={Math.random()}>

                        {getLegends()}
                    </CustomScrollDiv>
                </div>

            </div>
        </CustomBlock>
    )
}

export default SearchGraph;