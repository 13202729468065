import React, { useState } from "react";
import CustomBlock from "../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { hasMultipleCams, hasMultipleHours } from "../../customFunctions/user.function";
import CustomDropdownCheckBoxes from "../../customComponents/CustomDropdownCheckBoxes";
import { setSelectedAnalysisDataType, setSelectedCams, setSelectedHours } from "../../../actions_reducers/actions/user.action";
import { getDatas } from "../../customFunctions/data.function";

const DataSelection = (props) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [dropdownCamsOpen, setDropdownCamsOpen] = useState(false);
    const [dropdownHoursOpen, setDropdownHoursOpen] = useState(false);
    const [dropdownKitchenOpen, setDropdownKitchenOpen] = useState(false);

    const changeHourOpen = () => setDropdownHoursOpen(!dropdownHoursOpen);
    const changeCamOpen = () => setDropdownCamsOpen(!dropdownCamsOpen);
    const changeKitchenOpen = () => setDropdownKitchenOpen(!dropdownKitchenOpen);

    const changeCam = (e) => {
        let cam = props.userState.cams.find(a => a.displayName === e.target.id);
        if (cam.camName === "total") {
            if (props.userState.selected_cams.find(a => a.camName === "total"))
                dispatch(setSelectedCams([]));
            else dispatch(setSelectedCams(props.userState.cams));
            getDatas(props.analyzesBaseDatas, props.userState.selected_hours, props.userState.cams, props.dashboardState, dispatch, props.userState.averageGuests);
        }
        else {
            let camsFinal = JSON.parse(JSON.stringify(props.userState.selected_cams));
            let camIndex = camsFinal.findIndex(a => a.displayName === cam.displayName);
            if (camIndex === -1) {
                camsFinal.push(cam);
                if (camsFinal.length === props.userState.cams.length - 1) camsFinal.push({ camName: "total", displayName: "Total" });
            }
            else {
                camsFinal.splice(camIndex, 1);
                let totIndex = camsFinal.findIndex(a => a.camName === 'total');
                camsFinal.splice(totIndex, 1);
            }
            dispatch(setSelectedCams(camsFinal));
            getDatas(props.analyzesBaseDatas, props.userState.selected_hours, camsFinal, props.dashboardState, dispatch, props.userState.averageGuests);
        }
    }
    const getCamsElement = () => {
        let values = props.userState.cams.map(item => item.displayName);
        let selectedCams = props.userState.selected_cams.map(item => item.displayName);
        if (hasMultipleCams(props.userState)) {
            return (
                <CustomDropdownCheckBoxes
                    zIndex={dropdownCamsOpen ? 0 : 2000}
                    width={'80%'}
                    height={'15%'}
                    backgroundColor={'#fff'}
                    color={"#666666"}
                    border={"1px solid #666666"}
                    arrowColor={"#D9D9D9"}
                    arrowWidth={"10%"}
                    displayedValue={t(`components.cams`)}
                    selectedValues={selectedCams}
                    values={values}
                    dropdownUpdate={22}
                    onChange={changeCam}
                    isOpen={dropdownCamsOpen}
                    onClick={changeCamOpen}
                />)
        }
        else {
            return (
                <CustomBlock
                    width={'80%'}
                    height={'15%'}
                    backgroundColor={'#fff'}
                    border={"1px solid #666666"}
                    boxShadow={"none"}
                >
                    <div className="data-selection-single-block" > {t('components.firstCam')}</div>
                </CustomBlock>
            )
        }
    }
    const getHourValue = (hour) => {
        if (hour === "midi") return "noon";
        else if (hour === "soir") return "evening";
        else return "total";
    }
    const changeHour = (e) => {
        let hour = getHourValue(e.target.id.toLowerCase());
        if (hour === "total") {
            if (props.userState.selected_hours.find(a => a === "total"))
                dispatch(setSelectedHours([]));
            else dispatch(setSelectedHours(props.userState.hours));
            getDatas(props.analyzesBaseDatas, props.userState.hours, props.userState.selected_cams, props.dashboardState, dispatch, props.userState.averageGuests);
        }
        else {
            let hoursFinal = JSON.parse(JSON.stringify(props.userState.selected_hours));
            let hourIndex = hoursFinal.findIndex(a => a === hour);
            if (hourIndex === -1) {
                hoursFinal.push(hour);
                if (hoursFinal.length === props.userState.hours.length - 1) hoursFinal.push("total");
            }
            else {
                hoursFinal.splice(hourIndex, 1);
                let totIndex = hoursFinal.findIndex(a => a === 'total');
                hoursFinal.splice(totIndex, 1);
            }
            dispatch(setSelectedHours(hoursFinal));
            getDatas(props.analyzesBaseDatas, hoursFinal, props.userState.selected_cams, props.dashboardState, dispatch, props.userState.averageGuests);
        }
    }
    const getHoursElement = () => {
        let values = props.userState.hours.map(item => t(`hours.${item}`));
        let selectedHours = props.userState.selected_hours.map(item => t(`hours.${item}`));
        if (hasMultipleHours(props.userState)) {
            return (
                <CustomDropdownCheckBoxes
                    zIndex={dropdownCamsOpen ? 0 : 1001}
                    width={'80%'}
                    height={'15%'}
                    backgroundColor={'#fff'}
                    color={"#666666"}
                    border={"1px solid #666666"}
                    arrowColor={"#D9D9D9"}
                    arrowWidth={"10%"}
                    displayedValue={t(`components.hours`)}
                    selectedValues={selectedHours}
                    values={values}
                    dropdownUpdate={22}
                    onChange={changeHour}
                    isOpen={dropdownHoursOpen}
                    onClick={changeHourOpen}
                />)
        }
        else {
            return (
                <CustomBlock
                    width={'80%'}
                    height={'15%'}
                    backgroundColor={'#fff'}
                    border={"1px solid #666666"}
                    boxShadow={"none"}
                >
                    <div className="data-selection-single-block" > {t('components.noonService')}</div>
                </CustomBlock>
            )
        }
    }
    const getDataTypeName = (value) => {
        if (value === t('hours.total')) return "total";
        else if (value === t('components.kitchen')) return "kitchen";
        else if (value === t('components.restaurant')) return "restaurant";
    }
    const changeDataType = (e) => {
        let dataTypes = props.userState.selectedAnalysisDataType;
        let value = getDataTypeName(e.target.id);
        if (value === "total") {
            if (dataTypes.find(a => a === "total"))
                dispatch(setSelectedAnalysisDataType([]));
            else
                dispatch(setSelectedAnalysisDataType(props.userState.analysisDataType));
        }
        else {
            let dataTypeFinal = JSON.parse(JSON.stringify(dataTypes));
            let dataTypeIndex = dataTypeFinal.findIndex(a => a === value);
            if (dataTypeIndex === -1) {
                dataTypeFinal.push(value);
                if (dataTypeFinal.length === props.userState.analysisDataType.length - 1) dataTypeFinal.push("total");
            }
            else {
                dataTypeFinal.splice(dataTypeIndex, 1);
                let totIndex = dataTypeFinal.findIndex(a => a === 'total');
                dataTypeFinal.splice(totIndex, 1);
            }
            dispatch(setSelectedAnalysisDataType(dataTypeFinal));

        }
    }
    const getTypesForAnalysis = () => {
        if (props.hasRestaurantAnalysis && props.hasKitchenAnalysis)
            return "all";
        else if (props.hasRestaurantAnalysis && !props.hasKitchenAnalysis)
            return "restaurant";
        else if (!props.hasRestaurantAnalysis && props.hasKitchenAnalysis)
            return "kitchen"
    }
    const getKitchenElement = () => {
        let values = props.userState.analysisDataType.map(item => t(`components.${item}`));
        let selectedAnalysisDataType = props.userState.selectedAnalysisDataType.map(item => t(`components.${item}`));
        if (props.userState.has_kitchen && getTypesForAnalysis() === "all") {
            return (
                <CustomDropdownCheckBoxes
                    zIndex={dropdownKitchenOpen ? 0 : 1000}
                    width={'80%'}
                    height={'15%'}
                    backgroundColor={'#fff'}
                    color={"#666666"}
                    border={"1px solid #666666"}
                    arrowColor={"#D9D9D9"}
                    arrowWidth={"10%"}
                    displayedValue={t(`components.origin`)}
                    selectedValues={selectedAnalysisDataType}
                    values={values}
                    dropdownUpdate={22}
                    onChange={changeDataType}
                    isOpen={dropdownKitchenOpen}
                    onClick={changeKitchenOpen}
                />)
        }
        else {
            return (
                <CustomBlock
                    width={'80%'}
                    height={'15%'}
                    backgroundColor={'#fff'}
                    border={"1px solid #666666"}
                    boxShadow={"none"}
                >
                    <div className="data-selection-single-block" >
                        {getTypesForAnalysis() === "restaurant" ?
                            t('dataTypes.restaurant')
                            : t('dataTypes.kitchen')}
                    </div>
                </CustomBlock>
            )
        }
    }


    return (
        <CustomBlock width={'100%'} height={'100%'}>
            <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                <div className="dashboard-block-title" style={{ paddingLeft: "6%", paddingTop: "3%", height: "20%" }}>
                    {t('components.data')}
                </div>
                <div className="dashboard-data-selection-body">
                    {getCamsElement()}
                    <div style={{ height: "20.5%" }} />
                    {getHoursElement()}
                    <div style={{ height: "20.5%" }} />
                    {getKitchenElement()}
                </div>
            </div>
        </CustomBlock>
    )
}
export default DataSelection;