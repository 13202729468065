import React, { useEffect } from "react";
import AnalyzesValues from "../../commonElements/AnalyzesValues";
import ExtrapolationButton from "../../commonElements/ExtrapolationButton";
import Analyzes from "./Analyzes";
import Graph from "./Graph";
import Informations from "./Informations";
import TopGaspi from "../../commonElements/TopGaspi";
import Objectives from "./Objectives";
import Evolution from "./Evolution";
import Repartition from "./Repartition";
import KitchenEvo from "./KitchenEvo";
import { useDispatch } from "react-redux";
import { setExtrapolationValue } from "../../../../actions_reducers/actions/dashboard.action";
import { setExtrapolatedAnalyzes, setKitchenExtrapolatedAnalyzes } from "../../../../actions_reducers/actions/datas.action";
import FirstAnalysisEvolution from "./FirstAnalysisEvolution";
import AnalyzesCutText from "./AnalyzesCutText";

const DashboardAllAnalyzes = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setExtrapolationValue("analysis"));
        dispatch(setKitchenExtrapolatedAnalyzes(props.kitchenAnalyzesDatas));
        dispatch(setExtrapolatedAnalyzes(props.analyzesDatas));
    }, [])
    const hasMultipleAnalyzes = () => {
        let analyzes = [];
        for (const analysis of props.analyzesDatas) {
            if (!analyzes.find(a => a === analysis.analysisNumber))
                analyzes.push(analysis.analysisNumber)
        }
        for (const analysis of props.kitchenAnalyzesDatas) {
            if (!analyzes.find(a => a === analysis.analysisNumber))
                analyzes.push(analysis.analysisNumber)
        }
        if (analyzes.length > 1) return true;
        else return false;
    }

    return (
        <div className="main-page-main-div">
            {props.isAnalysisCut && <AnalyzesCutText />}
            <div style={{ height: "3vh" }} />
            <div style={{ display: 'flex', flexDirection: 'row', width: "100%", height: "100%" }}>
                <div style={{
                    width: '55%'
                }}>
                    <ExtrapolationButton state={props.dashboardState} analyzesDatas={props.analyzesDatas} userState={props.userState} kitchenAnalyzesDatas={props.kitchenAnalyzesDatas} />
                    <AnalyzesValues state={props.dashboardState} has_kitchen={props.has_kitchen} kitchenDatas={props.kitchenExtrapolatedAnalyzes} analyzesDatas={props.extrapolatedAnalyzes} baseAnalysisKitch={props.kitchenAnalyzesDatas} baseAnalysis={props.analyzesDatas} height={"20vh"} />

                    <div style={{ height: "4vh" }} />
                    <Graph state={props.dashboardState} analyzesDatas={props.extrapolatedAnalyzes} arianne={props.arianne} kitchenDatas={props.kitchenExtrapolatedAnalyzes} baseAnalysisKitch={props.kitchenAnalyzesDatas} baseAnalysis={props.analyzesDatas}
                        userState={props.userState} />
                </div>
                <div style={{ height: "6vh" }} />
                <div style={{ width: '45%', marginLeft: '2.5%', height: "37vh" }}>
                    <Informations has_synthesis={props.has_synthesis} arianne={props.arianne} userState={props.userState} analyzesDatas={props.analyzesDatas} kitchenAnalyzesDatas={props.kitchenAnalyzesDatas} />
                    <TopGaspi state={props.dashboardState} userState={props.userState} has_kitchen={props.has_kitchen} kitchenAnalyzesDatas={props.kitchenExtrapolatedAnalyzes} analyzesDatas={props.extrapolatedAnalyzes} marginTop={"4vh"} />
                </div>
            </div>
            <div style={{ height: "6vh" }} />
            {props.has_kitchen && props.has_restaurant &&
                <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "45vh" }}>
                    <Repartition state={props.dashboardState} baseAnalysisKitch={props.kitchenAnalyzesDatas} baseAnalysis={props.analyzesDatas} kitchenDatas={props.kitchenExtrapolatedAnalyzes} analyzesDatas={props.extrapolatedAnalyzes} />
                    <KitchenEvo userState={props.userState} state={props.dashboardState} kitchenDatas={props.kitchenExtrapolatedAnalyzes} analyzesDatas={props.extrapolatedAnalyzes} baseAnalysisKitch={props.kitchenAnalyzesDatas} baseAnalysis={props.analyzesDatas} />
                </div>}
            {props.has_kitchen && props.has_restaurant &&
                <div style={{ height: "6vh" }} />}
            <div>
                <Objectives restaurant_name={props.userState.selected_restaurant} state={props.objectivesState} analyzesDatas={props.analyzesDatas} categories={props.categories} kitchenAnalyzesDatas={props.kitchenAnalyzesDatas} />
                {hasMultipleAnalyzes() &&
                    <>
                        <div style={{ height: "6vh" }} />
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "24%" }}>
                                <FirstAnalysisEvolution selectedAnalysis={props.dashboardState.selectedAnalysisNumber} analyzesDatas={props.analyzesDatas} kitchenAnalyzesDatas={props.kitchenAnalyzesDatas} />
                            </div>
                            <div style={{ width: "2.5%" }} />
                            <div style={{ width: "73.5%" }}>
                                <Evolution analyzesDatas={props.analyzesDatas} kitchenAnalyzesDatas={props.kitchenAnalyzesDatas} extrapolationValue={props.dashboardState.extrapolationValue} userState={props.userState} />
                            </div>
                        </div>
                    </>
                }
                <div style={{ height: "6vh" }} />
                <Analyzes analyzesDatas={props.analyzesDatas} kitchenAnalyzesDatas={props.kitchenAnalyzesDatas} arianne={props.arianne} />
            </div>

        </div >
    )
}

export default DashboardAllAnalyzes;
