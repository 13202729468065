import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import CustomButton from "../../../customComponents/CustomButton";
import CustomScrollDiv from "../../../customComponents/CustomScrollBar";
import { ReactComponent as Processing } from "../../../../assets/processing.svg";
import { ReactComponent as Check } from "../../../../assets/check.svg";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { setShowedAnalysis } from "../../../../actions_reducers/actions/dashboard.action";
import { updateArianneThread } from "../../../arianneThread/ArianneThread";

const Analyzes = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const getAnalyzesRows = () => {
        let result = [];
        let analyzes = JSON.parse(JSON.stringify(props.analyzesDatas)).sort((a, b) => b.analysisNumber - a.analysisNumber);
        let kitchenAnalyzes = JSON.parse(JSON.stringify(props.kitchenAnalyzesDatas)).sort((a, b) => b.analysisNumber - a.analysisNumber);
        for (const kitchenAnalysis of kitchenAnalyzes) {
            if (!analyzes.find(a => a.analysisNumber === kitchenAnalysis.analysisNumber))
                analyzes.push(kitchenAnalysis)
        }
        for (const analysisTmp of analyzes) {
            let analysisNumber = analysisTmp.analysisNumber;
            let dateStart = analysisTmp.dateStart;
            let dateEnd = analysisTmp.dateEnd;
            let kitchenAnalysis = props.analyzesDatas.find(a => a.analysisNumber === analysisTmp.analysisNumber)
            let analysis = props.kitchenAnalyzesDatas.find(a => a.analysisNumber === analysisTmp.analysisNumber);
            let massByConvKitchen = 0;
            let massByConvTrays = 0;
            if (kitchenAnalysis)
                massByConvKitchen = (parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass)) / parseInt(kitchenAnalysis.trays);
            if (analysis)
                massByConvTrays = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / parseInt(analysis.trays);
            let massByConv = massByConvKitchen + massByConvTrays;
            let isOver = analysisTmp.isOver;
            result.push(
                <div key={analysisTmp.analysisNumber} className="dashboard-block-analyzes-row">
                    <div className="dashboard-block-analyzes-button-col">
                        <CustomButton
                            id={analysisNumber}
                            width={"80%"}
                            height={"50%"}
                            backgroundColor={"#90c261"}
                            value={t('arianne.detailedAnalysis', { complementValue: analysisNumber })}
                            color={"#ffffff"}
                            fontWeight={550}
                            fontSize={'1.1vw'}
                            onClick={(e) => {
                                updateArianneThread('detailedAnalysis', props.arianne, dispatch, e.target.id);
                                dispatch(setShowedAnalysis(e.target.id))
                            }} />
                    </div>
                    <div className="dashboard-block-analyzes-text-col">
                        {t('components.from')}&nbsp;
                        {t('date', { "value": { "date": dateStart, "format": "DD MMMM YYYY" } })}&nbsp;
                        {t('components.to')}&nbsp;
                        {t('date', { "value": { "date": dateEnd, "format": "DD MMMM YYYY" } })}&nbsp;
                    </div>
                    <div className="dashboard-block-analyzes-value-col">
                        {t('mass', { "value": { "mass": massByConv, "unit": "g" } })}
                        {t('legends.massByConv')}
                    </div>
                    <div className="dashboard-block-analyzes-status-col">
                        {isOver ?
                            <Check className="analyzes-check-style" />
                            : <Processing className="analyzes-processing-style" />}
                    </div>
                </div>
            )
        }
        return result;
    }
    return (
        <CustomBlock width={'100%'} height={'65vh'} >
            <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                <div className="dashboard-block-title">
                    {t('components.analyzes')}
                </div>
                <div className="dashboard-block-analyzes">
                    <CustomScrollDiv update={Math.random()}>
                        {getAnalyzesRows()}
                    </CustomScrollDiv>
                </div>
            </div>
        </CustomBlock>
    )
}

export default Analyzes;