import React, { useState, useEffect } from "react";
import CustomBlock from "../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { getTraysWithSamplesForDate } from "../../../apiRoutes/trays.route";
import { ReactComponent as Arrow } from "../../../assets/arrow.svg";
import { setPicturesList } from "../../../actions_reducers/actions/datas.action";
import CustomScrollDiv from "../../customComponents/CustomScrollBar";
import CustomDropdown from "../../customComponents/CustomDropdown";

import 'moment/locale/fr';
import { getFoodName } from "../../customFunctions/food.functions";
import { getHour, hasMultipleCams, hasMultipleHours } from "../../customFunctions/user.function";
import { setDisplayedPictures, setSelectedPicture } from "../../../actions_reducers/actions/pictures.action";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);


const Pictures = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [numberPages, setNumberPages] = useState(1);
    const [selectedPage, setSelectedPage] = useState(1);
    const [update, setUpdate] = useState(0);
    const [selectedDate, setSelectedDate] = useState(props.selectedDate || "");
    const [allDates, setAllDates] = useState([]);
    const [selectedFood, setSelectedFood] = useState(t('foods.allFoods'));
    useEffect(() => {
        updateDisplayedPictures()
        if (!props.selectedDate) {
            let allDatesTmp = props.analysis.days.map(item => moment(item.date).format("YYYY-MM-DD"))
            allDatesTmp.sort((a, b) => a.localeCompare(b));
            setSelectedDate(allDatesTmp[0]);
            setAllDates(allDatesTmp)
        }
    }, [])
    useEffect(() => {
        updateDisplayedPictures()
    }, [selectedFood, props.pictures_list.length, selectedDate])
    const updateDisplayedPictures = (allDatesPictures) => {
        let picturesTmp = allDatesPictures ? allDatesPictures : props.pictures_list
        let allPictures = picturesTmp.find(a => a.date === selectedDate);
        if (!allPictures) allPictures = picturesTmp[0];
        if (allPictures) {
            let pictures = allPictures.pictures;
            let picturesFinal = [];
            for (const picture of pictures) {
                if (selectedFood === null || selectedFood === t('foods.allFoods') || picture.samples.find(a => getFoodName(props.food_list, a.aliment_id).includes(selectedFood)))
                    picturesFinal.push(picture)
            }
            picturesFinal.sort((a, b) => b.mass - a.mass)
            dispatch(setDisplayedPictures(picturesFinal))
        }
    }
    useEffect(() => {
        let picturesDay = props.pictures_list.find(a => a.date === selectedDate);
        if (!picturesDay)
            getPicturesForDate();
        else setNumberPages(picturesDay.numberPages);
    }, [selectedDate])
    const getPicturesForDate = async () => {
        let restaurant_name = props.userState.selected_restaurant;

        let pictures = await getTraysWithSamplesForDate(restaurant_name, selectedDate);
        let tmpNumberPages = Math.floor(pictures.length / 30) + (pictures.length % 30 !== 0 ? 1 : 0)
        let foodsOfDay = getAlimList(pictures);
        let datePictures = {
            date: selectedDate,
            pictures: pictures.sort((a, b) => b.volume - a.volume),
            numberPages: tmpNumberPages,
            foodsOfDay: foodsOfDay
        };
        let allDatesPictures = JSON.parse(JSON.stringify(props.pictures_list));
        allDatesPictures.push(datePictures);
        setUpdate(old => old + 1)
        setNumberPages(tmpNumberPages);
        dispatch(setPicturesList(allDatesPictures));
        updateDisplayedPictures(allDatesPictures)
    }
    const isPictureShowed = (hour, cam) => {
        let multipleHours = hasMultipleHours(props.userState);
        let multipleCams = hasMultipleCams(props.userState);
        let selectedCams = props.userState.selected_cams;
        let selectedHours = props.userState.selected_hours;
        if (multipleHours && multipleCams && cam && hour) {
            if (selectedCams.find(a => a.camName === cam.camName) && selectedHours.find(a => a === hour)) return true;
            else return false;
        }
        else if (!multipleHours && multipleCams && cam) {
            if (selectedCams.find(a => a.camName === cam.camName)) return true;
            else return false;
        }
        else if (multipleHours && !multipleCams && hour) {
            if (selectedHours.find(a => a === hour)) return true;
            else return false;
        }
        else
            return true;
    }
    const getShowedPictures = () => {
        let result = [];
        let tmpDiv = [];
        let picturesDay = props.pictures_list.find(a => a.date === selectedDate);
        if (selectedDate === "all") {

        }
        if (picturesDay && picturesDay.pictures) {
            let picturesFinal = picturesDay.pictures;
            if (selectedFood !== t('foods.allFoods'))
                picturesFinal = picturesFinal.filter(a => a.samples.find(b => t(`foods.${getFoodName(props.food_list, b.aliment_id)}`) === selectedFood))
            for (let i = 0; i < picturesFinal.length; i++) {
                let picture = picturesFinal[i];
                let hour = getHour(moment(picture.created_at).format("HH:mm:ss"));
                let cam = props.userState.selected_cams.find(a => picture.image_ref.includes(a.camName))
                if (isPictureShowed(hour, cam))
                    tmpDiv.push(
                        <div className="dashboard-picture" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img className="dashboard-picture" style={{ cursor: "pointer", width: "auto", maxWidth: "85%" }} src={picture.url} onClick={() => dispatch(setSelectedPicture(picture.url))
                            } />
                        </div>

                    )
            }
            let size = 3;
            for (let i = 0; i < tmpDiv.length; i += size) {
                if (i < 30 * parseInt(selectedPage) && i >= 30 * (parseInt(selectedPage) - 1)) {
                    result.push(<div key={i + selectedPage} className="dashboard-picture-div">{tmpDiv.slice(i, i + size)}</div>);
                }
            }
        }
        return { result: result, tmpDiv: tmpDiv };
    }

    useEffect(() => {
        let length = getShowedPictures().tmpDiv.length
        let tmpNumberPages = Math.floor(length / 30) + (length % 30 !== 0 ? 1 : 0);
        setNumberPages(tmpNumberPages);
    }, [props.userState.selected_cams.length, props.userState.selected_hours.length]);

    const updateSelectedPage = (id) => {
        if (id !== '...') {
            setSelectedPage(id);
            setUpdate(old => old + 1)
        }
    }
    const nextSelectedPage = () => {
        setUpdate(old => old + 1)
        if (parseInt(selectedPage) < parseInt(numberPages))
            setSelectedPage(old => parseInt(old) + 1);

    }
    const prevSelectedPage = () => {
        setUpdate(old => old + 1)
        if (selectedPage > 1)
            setSelectedPage(old => parseInt(old) - 1);

    }
    const getPageSelection = (numberPages) => {
        let items = [selectedPage];
        const tmpPage = parseInt(selectedPage);
        if (tmpPage === 1) {
            if (numberPages > 1)
                items.push(2);
            if (numberPages > 3)
                items.push(3);
            if (numberPages > 4)
                items.push('...');
        }
        else if (tmpPage === 2) {
            if (numberPages > 2)
                items.push(3)
            if (numberPages > 3)
                items.push('...')
            items.unshift(1)
        }
        else if (tmpPage === numberPages) {
            if (numberPages > tmpPage - 1)
                items.unshift(tmpPage - 1)
            if (numberPages > tmpPage - 2)
                items.unshift(tmpPage - 2)
            if (numberPages > tmpPage - 3)
                items.unshift('...')
        }
        else {
            if (numberPages > tmpPage)
                items.push(tmpPage + 1);
            if (numberPages > tmpPage + 1)
                items.push('...');
            items.unshift(tmpPage - 1);
            items.unshift('...');
        }
        let result = [<div className="dashboard-pictures-number-div" onClick={() => prevSelectedPage()}>{"<"}</div>];
        for (const item of items) {
            result.push(
                <div id={item} className="dashboard-pictures-number-div"
                    style={{
                        fontWeight: parseInt(item) === parseInt(selectedPage) ? 600 : 300,
                        textDecoration: parseInt(item) === parseInt(selectedPage) ? "underline" : "none"
                    }}
                    onClick={(e) => updateSelectedPage(e.target.id)}>{item}</div>
            )
        }
        result.push(<div className="dashboard-pictures-number-div" onClick={() => nextSelectedPage()}>{">"}</div>);
        return result;
    }
    const changeDate = (e) => {
        setSelectedDate(e.target.id);
        setSelectedFood(t('foods.allFoods'));
    }

    const changeFood = (e) => {
        setUpdate(old => old + 1);
        setSelectedFood(e.target.id);
        let pictures = JSON.parse(JSON.stringify(props.pictures_list)).find(a => a.date === selectedDate).pictures;
        if (e.target.id !== t('foods.allFoods')) {
            pictures = pictures.filter(a => a.samples.find(b => t(`foods.${getFoodName(props.food_list, b.aliment_id)}`) === e.target.id))
        }
        let tmpNumberPages = Math.floor(pictures.length / 30) + (pictures.length % 30 !== 0 ? 1 : 0);
        if (tmpNumberPages === 0) tmpNumberPages = 1;
        setNumberPages(parseInt(tmpNumberPages));
        setSelectedPage(1);
    }
    const getAlimentIdOfDay = (pictures) => {
        let result = [];
        for (const picture of pictures) {
            for (const sample of picture.samples) {
                if (!result.find(a => a === sample.aliment_id))
                    result.push(sample.aliment_id);
            }
        }
        return result;
    }
    const getAlimList = (pictures) => {
        let result = [];
        const alimentIds = getAlimentIdOfDay(pictures);
        for (const id of alimentIds) {
            let food = props.food_list.find(a => parseInt(a.idFood) === parseInt(id));
            if (food && food.categoryFood)
                result.push(food.nameFood);
        }
        result.sort((a, b) => a.localeCompare(b))
        result.unshift(t('foods.allFoods'));
        return result;

    }
    const getSelectedAlim = () => {
        const picturesDay = props.pictures_list.find(a => a.date === selectedDate);
        if (picturesDay && picturesDay.foodsOfDay) {
            let items = [];
            for (const alim of picturesDay.foodsOfDay) {
                if (alim !== t('foods.allFoods'))
                    items.push(t(`foods.${alim}`))
                else items.push(t('foods.allFoods'))
            }
            return <CustomDropdown
                width={"70%"}
                height={"70%"}
                backgroundColor={"#fff"}
                color={"#6D6C7A"}
                arrowColor={"#6D6C7A"}
                displayedValue={selectedFood}
                selectedValue={selectedFood}
                values={items}
                maxHeightDropdown={"35vh"}
                dropdownUpdate={update}
                onChange={changeFood}
                border={"1px solid #6D6C7A"}
            />
        }
    }

    const getDropdownDays = () => {
        return <CustomDropdown
            width={"70%"}
            height={"70%"}
            backgroundColor={"#72B9A2"}
            color={"#fff"}
            arrowColor={"#fff"}
            displayedValue={selectedDate}
            selectedValue={selectedDate}
            values={allDates}
            maxHeightDropdown={"35vh"}
            dropdownUpdate={update}
            onChange={changeDate}
        />
    }
    const checkKey = (e) => {
        if (props.selectedPicture) {
            if (e.keyCode === 39) {
                nextUrl()
            }
            else if (e.keyCode === 37) {
                previousUrl()
            }
        }
    }
    window.onkeydown = checkKey;
    const previousUrl = () => {
        let index = props.displayedPictures.findIndex(a => a.url === props.selectedPicture);
        if (index === 0)
            dispatch(setSelectedPicture(props.displayedPictures[props.displayedPictures.length - 1].url))
        else dispatch(setSelectedPicture(props.displayedPictures[index - 1].url))
    }
    const nextUrl = () => {
        let index = props.displayedPictures.findIndex(a => a.url === props.selectedPicture);
        if (index === props.displayedPictures.length - 1)
            dispatch(setSelectedPicture(props.displayedPictures[0].url))
        else dispatch(setSelectedPicture(props.displayedPictures[index + 1].url))
    }

    const imageModal = () => {
        let id = null;
        let pictures = null;
        if (props.selectedPicture) {
            id = props.selectedPicture;
            pictures = props.pictures_list.find(a => a.date === selectedDate);
            if (pictures) {
                return (
                    <CustomBlock id={id} width={"90%"} height={"100%"} right={"5%"} top={'0%'} display={props.selectedPicture == null ? "none" : "block"}
                        position={"absolute"} zIndex={20000000000}>
                        <div id={id} style={{ height: "90%", width: "100%", display: "flex", flexDirection: "row" }}>
                            <div id={id} style={{ width: "10%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                                onClick={() => previousUrl()}>
                                <Arrow id={id} className="arrow-left" />
                            </div>
                            <div id={id} style={{ width: "80%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img id={props.selectedPicture} className="img-pictures" style={{ width: "40%" }} src={props.selectedPicture} />
                            </div>
                            <div id={id} style={{ width: "10%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                                onClick={() => { nextUrl() }}>
                                <Arrow id={id} className="arrow-right" />
                            </div>
                        </div>
                        {props.userState.userType === "admin" &&
                            <div id={id} style={{ height: "10%", width: "100%", display: "flex", flexDirection: "row" }}>
                                <div id={id} style={{ width: "10%" }} />
                                <div id={id} style={{ width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {"image_ref : " + id}
                                </div>
                                <div id={id} style={{ width: "10%" }} />
                            </div>}
                    </CustomBlock>
                )
            }
        }
    }
    return (
        <CustomBlock width={'100%'} height={'100%'}>
            {imageModal()}
            <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "8%" }}>
                    <div className="dashboard-block-title" style={{ width: "30%", flexDirection: "column" }}>
                        {t('components.pictures')}
                        <div className="dasboard-picture-select-page-div">
                            {getPageSelection(numberPages)}
                        </div>
                    </div>
                    <div style={{ width: "40%", height: "100%", display: "flex", justifyContent: "center" }}>
                        {!props.selectedDate && getDropdownDays()}

                    </div>
                    <div style={{ width: "30%", height: "100%", display: "flex", justifyContent: "flex-end" }}>
                        {getSelectedAlim()}
                    </div>
                </div>
                <div className="dashboard-pictures-body">
                    <CustomScrollDiv update={Math.random()}>
                        {getShowedPictures().result}
                    </CustomScrollDiv>
                </div>
            </div>
        </CustomBlock>
    )
}
export default Pictures;