import React, { useEffect, useRef, useState } from "react";
import CustomBlock from "../../customComponents/CustomBlock";
import CustomScrollDiv from "../../customComponents/CustomScrollBar";
import { useTranslation } from "react-i18next";
import { ReactComponent as Arrow } from "../../../assets/arrow.svg";
import CustomButton from "../../customComponents/CustomButton";
import CustomTextField from "../../customComponents/CustomTextField";
import { useDispatch } from "react-redux";
import 'moment/locale/fr';
import { setAlimsToDelete, setNumberPagesPictures, setPictures, setPicturesToDelete, setSamplesModif, setSelectedFilterPictures, setSelectedPicture } from "../../../actions_reducers/actions/pictures.action";
import CustomAutocomplete from "../../customComponents/CustomAutocomplete";
import { deleteAlimForTray, updateAlimsForTray } from "../../../apiRoutes/trays.route";
import { setHasLoaded } from "../../../actions_reducers/actions/user.action";
import { getBaseUrls } from "../../customFunctions/user.function";
import CustomLoading from "../../customComponents/CustomLoading";
import { ReactComponent as ArrowDown } from "../../../assets/arrowdown2.svg";
import CustomCheckbox from "../../customComponents/CustomCheckbox";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const PicturesDetails = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dropdownRef = useRef(null);
    let baseUrl = getBaseUrls().url;
    const [showNtr, setShowNtr] = useState(props.restaurant_type === "hopital" || false);
    const [numberPages, setNumberPages] = useState(1);
    const [selectedPage, setSelectedPage] = useState(1);
    const [hoveredAlim, setHoveredAlim] = useState(null);
    const [hoveredImg, setHoveredImg] = useState(null);
    const [update, setUpdate] = useState(1);
    const [selectedAlim, setSelectedAlim] = useState(t('foods.allFoods'));
    const [dropdownIsOpen, setDropdownIsOpen] = useState(null);
    const [displayedPictures, setDisplayedPictures] = useState([]);
    const [pictureToModif, setPictureToModif] = useState(null);
    const [pictureToDelete, setPictureToDelete] = useState(null);
    const [dropdownModifAlimOpen, setDropdownModifAlimOpen] = useState(null);
    const [dropdownAlimsOpen, setDropdownAlimsOpen] = useState(false);
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && event.target.id === "") {
                setDropdownAlimsOpen(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const changeDropdownAlimsOpen = () => {
        setDropdownAlimsOpen(!dropdownAlimsOpen)
    };

    useEffect(() => {
        updateDisplayedPictures()
    }, [selectedAlim, props.pictures.length, props.selectedDate, props.selectedFilter])
    const getDateMajFormat = (date) => {
        return date.charAt(0).toUpperCase() + date.slice(1)
    }
    const updateDisplayedPictures = () => {
        let allPictures = { pictures: [] };
        if (props.selectedDate !== t('components.allMonth'))
            allPictures = props.pictures.find(a => a.date === props.selectedDate);
        else {
            let tmp = JSON.parse(JSON.stringify(props.pictures)).filter(a => getDateMajFormat(moment(a.date).format('MMMM YYYY')) === props.selectedMonth)
            if (tmp) {
                for (const picture of tmp) {
                    allPictures.pictures = allPictures.pictures.concat(picture.pictures)
                }
            }
        }
        if (allPictures) {
            let pictures = allPictures.pictures;
            let picturesFinal = [];
            for (const picture of pictures) {
                if (selectedAlim === null || selectedAlim === t('foods.allFoods') || picture.samples.find(a => getFoodName(a).includes(selectedAlim)))
                    picturesFinal.push(picture)
            }
            if (props.selectedFilter === "filterMass")
                picturesFinal.sort((a, b) => b.mass - a.mass)
            else {
                picturesFinal.sort((a, b) =>
                    moment(a.created_at).isSameOrBefore(moment(b.created_at)) ? -1 : 1
                )
            }
            setDisplayedPictures(picturesFinal)
            updateNbPages()
        }
    }
    const changeDropdownOpen = () => setDropdownIsOpen(!dropdownIsOpen)


    useEffect(() => {
        setUpdate(old => old + 1)
    }, [selectedAlim, displayedPictures.length])

    const cancelModif = () => {
        dispatch(setSamplesModif([]));
        setPictureToModif(null);
    }

    const confirmModif = async () => {
        let updatedSamples = [];
        let date = moment(pictureToModif.created_at).format("YYYY-MM-DD");
        let restaurant_name = props.restaurant_name;
        let allPictures = JSON.parse(JSON.stringify(props.pictures));
        let picturesDay = allPictures.find(a => a.date === date);
        let pictures = picturesDay.pictures;
        for (let sample of props.samplesModif) {
            let currentPicture = pictures.find(a => a.image_ref === sample.image_ref);
            let currentSample = currentPicture.samples.find(a => a.aliment_id === sample.oldId);
            let newSampleValues = {
                oldAlimentId: sample.oldId,
                image_ref: sample.image_ref,
            }
            let aliment_id = sample.oldId;
            if (sample.aliment_id) {
                aliment_id = sample.aliment_id;
                newSampleValues.newAlimentId = sample.aliment_id;
                currentSample.aliment_id = sample.aliment_id;
            }
            if (sample.newMass) {
                let food = props.food_list.find(a => parseInt(a.idFood) === parseInt(aliment_id));
                currentSample.mass = sample.newMass;
                if (aliment_id !== 244) {
                    let newVolume = sample.newMass / food.volumicMassFood * 1000;
                    newSampleValues.volume = newVolume;
                    currentSample.volume = newVolume;
                    if (sample.oldVolumeMoy) {
                        newSampleValues.volume_moy = newVolume;
                        currentSample.volume_moy = newVolume;
                    }
                }
                else {
                    let newSurface = sample.newMass / parseFloat(food.surfaceMassFood) * 100;
                    newSampleValues.surface = newSurface
                    currentSample.surface = newSurface;
                }
            }
            updatedSamples.push(newSampleValues);
        }
        await updateAlimsForTray(restaurant_name, date, updatedSamples)
        dispatch(setHasLoaded(false))
        dispatch(setSamplesModif([]));
        dispatch(setPictures(allPictures))
        setDisplayedPictures(pictures)
        setPictureToModif(null);
    }

    const checkKey = (e) => {
        if (props.selectedPicture) {
            if (e.keyCode === 39) {
                nextUrl()
            }
            else if (e.keyCode === 37) {
                previousUrl()
            }
        }
        else {
            if (e.keyCode === 39) {
                nextSelectedPage()
            }
            else if (e.keyCode === 37) {
                prevSelectedPage()
            }

        }
    }
    document.onkeydown = checkKey;
    const previousUrl = () => {
        let index = displayedPictures.findIndex(a => a.url === props.selectedPicture);
        if (index === 0)
            dispatch(setSelectedPicture(displayedPictures[displayedPictures.length - 1].url))
        else dispatch(setSelectedPicture(displayedPictures[index - 1].url))
    }
    const nextUrl = () => {
        let index = displayedPictures.findIndex(a => a.url === props.selectedPicture);
        if (index === displayedPictures.length - 1)
            dispatch(setSelectedPicture(displayedPictures[0].url))
        else dispatch(setSelectedPicture(displayedPictures[index + 1].url))
    }

    const getPadding = (length) => {
        if (length > 5)
            return "7%";
        else if (length > 4)
            return "8%";
        else if (length > 3)
            return "8%";
        else if (length > 2)
            return "7%";
        else if (length > 1)
            return "8%"
        else if (length === 1)
            return "7%"
    }

    const imageModal = () => {
        let id = null;
        let pictures = null;
        let picture = null;
        if (props.selectedPicture) {
            id = props.selectedPicture;
            let allPictures = { pictures: [] };
            if (props.selectedDate !== t('components.allMonth'))
                allPictures = props.pictures.find(a => a.date === props.selectedDate);
            else {
                let tmp = JSON.parse(JSON.stringify(props.pictures)).filter(a => getDateMajFormat(moment(a.date).format('MMMM YYYY')) === props.selectedMonth)
                if (tmp) {
                    for (const picture of tmp) {
                        allPictures.pictures = allPictures.pictures.concat(picture.pictures)
                    }
                }
            }
            picture = allPictures.pictures.find(a => a.url === props.selectedPicture);
            return (
                <CustomBlock id={id} width={"90%"} height={"100%"} right={"5%"} top={'0%'} display={props.selectedPicture == null ? "none" : "block"}
                    position={"absolute"} zIndex={20000000000}>
                    <div id={id} style={{ height: "90%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div id={id} style={{ width: "10%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                            onClick={() => previousUrl()}>
                            <Arrow id={id} className="arrow-left" />
                        </div>
                        <div id={id} style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img alt={props.selectedPicture} id={props.selectedPicture} className="img-pictures" style={{ width: "40%" }} src={props.selectedPicture} />
                            {
                                (hoveredAlim && hoveredImg === picture.image_ref)
                                &&
                                <img alt={baseUrl + hoveredAlim} id={baseUrl + hoveredAlim} className="img-masks" style={{ width: "40%", height: "auto" }} src={baseUrl + hoveredAlim} />
                            }
                        </div>
                        <div id={id} style={{ width: "36%", paddingLeft: "5%", paddingTop: getPadding(picture.samples.length) }}>
                            <CustomScrollDiv>
                                {getAlims(picture, "modal")}
                            </CustomScrollDiv>
                        </div>
                        <div id={id} style={{ width: "10%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                            onClick={() => { nextUrl() }}>
                            <Arrow id={id} className="arrow-right" />
                        </div>
                    </div>
                    <div id={id} style={{ height: "10%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div id={id} style={{ width: "10%" }} />
                        <div id={id} style={{ width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {"image_ref : " + id}
                        </div>
                        <div id={id} style={{ width: "10%" }} />
                    </div>
                </CustomBlock>
            )
        }
    }
    const modifModal = () => {

        let id = null;
        let picture = pictureToModif;
        if (pictureToModif) {
            let image_ref = baseUrl + picture.image_ref;
            return (
                <CustomBlock id={id} paddingBottom={"1%"} width={"90%"} height={"70%"} right={"5%"} top={'0%'} display={pictureToModif == null ? "none" : "block"}
                    position={"absolute"} zIndex={20000000000} color={"#666666"}>
                    <div style={{ height: "20%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "30%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {t('table.image')}
                        </div>
                        <div style={{ width: "35%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {t('table.currentValues')}
                        </div>
                        <div style={{ width: "35%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {t('table.correction')}
                        </div>
                    </div>
                    <div style={{ height: "70%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "30%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img alt={image_ref} id={image_ref} className="img-pictures" src={image_ref} style={{ width: "20%" }} />
                            {
                                (hoveredAlim && hoveredImg === picture.image_ref)
                                &&
                                <img alt={baseUrl + hoveredAlim} id={baseUrl + hoveredAlim} className="img-masks" style={{ width: "20%" }} src={baseUrl + hoveredAlim} />
                            }
                        </div>
                        {picture.samples.length > 2 ?
                            <div style={{ width: "70%", paddingTop: "2%", paddingBottom: "2%", paddingLeft: "3%", height: "auto" }}>
                                <CustomScrollDiv update={2}>
                                    {getAlimsModif(picture)}
                                </CustomScrollDiv>
                            </div>
                            :
                            <div style={{ width: "70%", paddingTop: "2%", paddingBottom: "2%", paddingLeft: "3%", height: "auto" }}>
                                {getAlimsModif(picture)}
                            </div>
                        }
                    </div>
                    <div style={{ height: "10%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomButton width={"50%"} height={"80%"} backgroundColor={"#90C261"} color={"#fff"} onClick={() => confirmModif()}>
                                {t('buttons.confirm')}
                            </CustomButton>
                        </div>
                        <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                            <CustomButton width={"50%"} height={"80%"} backgroundColor={"#949494"} color={"#fff"} onClick={() => cancelModif()}>
                                {t('buttons.cancel')}
                            </CustomButton>
                        </div>
                    </div>
                </CustomBlock>
            )
        }
    }

    const confirmDelete = async () => {
        let date = moment(pictureToDelete.created_at).format('YYYY-MM-DD');
        let restaurant_name = props.restaurant_name;
        let image_ref = pictureToDelete.image_ref;
        let ids = props.alimsToDelete;
        let allPictures = JSON.parse(JSON.stringify(props.pictures));
        let picturesDay = allPictures.find(a => a.date === date);
        let pictures = picturesDay.pictures;
        let picture = pictures.find(a => a.image_ref === image_ref);
        picture.samples = picture.samples.filter(a => !ids.find(b => b === a.aliment_id))
        await deleteAlimForTray(restaurant_name, date, image_ref, ids)
        dispatch(setHasLoaded(false))
        dispatch(setPictures(allPictures));
        setDisplayedPictures(pictures);
        dispatch(setAlimsToDelete([]));
        setPictureToDelete(null);
    }

    const cancelDelete = () => {
        dispatch(setAlimsToDelete([]));
        setPictureToDelete(null);
    }

    const deleteModal = () => {

        let id = null;
        let picture = pictureToDelete;
        if (picture) {
            let image_ref = baseUrl + picture.image_ref;
            return (
                <CustomBlock id={id} paddingBottom={"1%"} width={"90%"} height={"70%"} right={"5%"} top={'0%'} display={pictureToDelete == null ? "none" : "block"}
                    position={"absolute"} zIndex={20000000000} color={"#666666"}>
                    <div style={{ height: "20%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "30%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {t('table.image')}
                        </div>
                        <div style={{ width: "35%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {t('table.currentValues')}
                        </div>
                        <div style={{ width: "35%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {t('table.correction')}
                        </div>
                    </div>
                    <div style={{ height: "70%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "30%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img alt={image_ref} id={image_ref} className="img-pictures" src={image_ref} style={{ width: "20%" }} />
                            {
                                (hoveredAlim && hoveredImg === picture.image_ref)
                                &&
                                <img alt={baseUrl + hoveredAlim} id={baseUrl + hoveredAlim} className="img-masks" style={{ width: "20%" }} src={baseUrl + hoveredAlim} />
                            }
                        </div>
                        {picture.samples.length > 5 ?
                            <div style={{ width: "70%", paddingTop: "2%", paddingBottom: "2%", paddingLeft: "3%", height: "auto" }}>
                                <CustomScrollDiv update={2}>
                                    {getAlimsDelete(picture)}
                                </CustomScrollDiv>
                            </div>
                            :
                            <div style={{ width: "70%", paddingTop: "2%", paddingBottom: "2%", paddingLeft: "3%", height: "auto" }}>
                                {getAlimsDelete(picture)}
                            </div>
                        }
                    </div>
                    <div style={{ height: "10%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomButton width={"50%"} height={"80%"} backgroundColor={"#90C261"} color={"#fff"} onClick={() => confirmDelete()}>
                                {t('buttons.confirm')}
                            </CustomButton>
                        </div>
                        <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                            <CustomButton width={"50%"} height={"80%"} backgroundColor={"#949494"} color={"#fff"} onClick={() => cancelDelete()}>
                                {t('buttons.cancel')}
                            </CustomButton>
                        </div>
                    </div>
                </CustomBlock>
            )
        }
    }
    const getFoodName = (sample) => {
        let alim = props.food_list.find(a => parseInt(a.idFood) === parseInt(sample.aliment_id));
        if (alim)
            return t(`foods.${alim.nameFood}`)
        else if (sample.aliment_id === 231)
            return t('foods.Non organique')
    }
    const getAllFoods = () => {
        let result = [];
        for (const food of props.food_list) {
            result.push(food.nameFood)
        }
        result.push("Non organique")
        result.sort((a, b) => a.localeCompare(b))
        return result;
    }
    const updatePictureAlim = (e, sample) => {
        let food = props.food_list.find(a => a.nameFood === e.target.id);
        let idFood
        if (food && e.target.id !== t('foods.Non organique'))
            idFood = food.idFood;
        else idFood = 231
        let newModifSample = {
            image_ref: sample.image_ref,
            oldId: sample.aliment_id,
            aliment_id: idFood
        }
        let samplesModif = JSON.parse(JSON.stringify(props.samplesModif));
        let index = samplesModif.findIndex(a => a.image_ref === sample.image_ref && a.oldId === sample.aliment_id);
        if (index === -1) {
            samplesModif.push(newModifSample);
            dispatch(setSamplesModif(samplesModif))
        }
        else {
            samplesModif[index].aliment_id = idFood;
            dispatch(setSamplesModif(samplesModif))
        }
    }

    const updateMassAlim = (e, sample) => {
        let newModifSample = {
            image_ref: sample.image_ref,
            oldVolume: sample.volume,
            oldVolumeMoy: sample.volume_moy,
            oldSurface: sample.surface,
            oldId: sample.aliment_id,
            aliment_id: sample.aliment_id,
            newMass: parseInt(e.target.value)
        }

        let samplesModif = JSON.parse(JSON.stringify(props.samplesModif));
        let index = samplesModif.findIndex(a => a.image_ref === sample.image_ref && a.oldId === sample.aliment_id);
        if (index === -1) {
            samplesModif.push(newModifSample);
            dispatch(setSamplesModif(samplesModif))
        }
        else {
            samplesModif[index].oldVolume = sample.volume;
            samplesModif[index].oldVolumeMoy = sample.volume_moy;
            samplesModif[index].oldSurface = sample.surface;
            samplesModif[index].newMass = parseInt(e.target.value);
            dispatch(setSamplesModif(samplesModif))
        }
    }

    const getDisplayedValueModifAlim = (sample) => {
        let value = props.samplesModif.find(a => a.image_ref === sample.image_ref && a.oldId === sample.aliment_id);
        if (value && value.aliment_id) {
            return getFoodName(value)
        }
        else
            return t('components.selectAlim')
    }

    const getAlimsModif = (picture) => {
        let result = [];
        for (const sample of picture.samples) {
            result.push(
                <div style={{ width: "100%", height: "20%", display: "flex", flexDirection: "row" }}
                    id={sample.mask_ref}
                    value={sample.image_ref}
                    onMouseEnter={(e) => { setHoveredAlim(e.target.id); setHoveredImg(sample.image_ref) }}
                    onMouseOut={(e) => { setHoveredAlim(null); setHoveredImg(null) }}>
                    <div className="pictures-sample-div" id={sample.mask_ref} style={{ width: "40%", height: "100%", display: "flex", flexDirection: "row" }}>
                        <div id={sample.mask_ref} style={{ width: "75%", height: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", paddingLeft: "1%" }}>
                            {getFoodName(sample)}
                        </div>
                        <div id={sample.mask_ref} style={{ width: "25%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: "1%" }}>
                            {sample.mass.toFixed(1) + " g"}
                        </div>
                    </div>

                    <div style={{ width: "35%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: "1%" }}>
                        <CustomAutocomplete
                            width={"90%"}
                            height={"70%"}
                            color={"#666666"}
                            maxHeightDropdown={"40vh"}
                            displayedValue={getDisplayedValueModifAlim(sample)}
                            values={getAllFoods()}
                            zIndex={parseInt(sample.aliment_id) === parseInt(dropdownModifAlimOpen) ? 100000 : 1}
                            onClick={() => dropdownModifAlimOpen ? setDropdownModifAlimOpen(null) : setDropdownModifAlimOpen(sample.aliment_id)}
                            isOpen={sample.aliment_id === dropdownModifAlimOpen}
                            onChange={(e) => updatePictureAlim(e, sample)} />
                    </div>
                    <div id={sample.mask_ref}
                        value={sample.image_ref} style={{ width: "25%", height: "100%", flexDirection: "row", display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "1%" }}>
                        <CustomTextField
                            width={"50%"}
                            height={"65%"}
                            type={"number"}
                            onChange={(e) => updateMassAlim(e, sample)} />&nbsp; g
                    </div>
                </div>
            )
        }
        return result;
    }

    const updateAlimsToDelete = (e) => {
        let alims = JSON.parse(JSON.stringify(props.alimsToDelete));
        let index = alims.findIndex(a => parseInt(a) === parseInt(e.target.id))
        if (index === -1)
            alims.push(parseInt(e.target.id))
        else alims.splice(index, 1);
        dispatch(setAlimsToDelete(alims))
    }
    const getAlimsDelete = (picture) => {
        let result = [];
        for (const sample of picture.samples) {
            result.push(
                <div style={{ width: "100%", height: "20%", display: "flex", flexDirection: "row" }}
                    id={sample.mask_ref}
                    value={sample.image_ref}
                    onMouseEnter={(e) => { setHoveredAlim(e.target.id); setHoveredImg(sample.image_ref) }}
                    onMouseOut={(e) => { setHoveredAlim(null); setHoveredImg(null) }}>
                    <div className="pictures-sample-div" id={sample.mask_ref} style={{ width: "40%", height: "100%", display: "flex", flexDirection: "row" }}>
                        <div id={sample.mask_ref} style={{ width: "75%", height: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", paddingLeft: "1%" }}>
                            {getFoodName(sample)}
                        </div>
                        <div id={sample.mask_ref} style={{ width: "25%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: "1%" }}>
                            {t('mass', { value: { mass: sample.mass, unit: "g" } })}
                        </div>
                    </div>
                    <div style={{ width: "60%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "8%" }}>
                        <input type="checkbox" className='custom-checkbox-pictures'
                            style={{
                                fontSize: "1.6vw", width: "2vw", height: "2vw", backgroundColor:
                                    props.alimsToDelete.find(a => parseInt(a) === parseInt(sample.aliment_id)) != null ? "#ECAC68" : "#D9D9D9"
                            }}
                            id={sample.aliment_id} onChange={updateAlimsToDelete} checked={props.alimsToDelete.find(a => a === sample.aliment_id) != null} />

                    </div>
                </div>
            )
        }
        return result;
    }
    const getAlims = (picture, type) => {
        let result = [];
        if (type === "modal") {
            for (const sample of picture.samples) {
                result.push(
                    <div style={{ width: "100%", height: "20%" }}>
                        <div className="pictures-sample-div" style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row" }}
                            id={picture.url}
                            onMouseEnter={(e) => { setHoveredAlim(sample.mask_ref); setHoveredImg(sample.image_ref) }}
                            onMouseOut={(e) => { setHoveredAlim(null); setHoveredImg(null) }}>
                            <div id={picture.url} style={{ width: showNtr ? "40%" : "60%", height: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", paddingLeft: "1%" }}>
                                {getFoodName(sample)}
                            </div>
                            <div id={picture.url} style={{ width: showNtr ? "20%" : "40%", height: "100%", display: "flex", justifyContent: showNtr ? "center" : "flex-start", alignItems: "center", paddingRight: "1%" }}>
                                {t('mass', { value: { mass: sample.mass, unit: "g" } })}
                            </div>
                            {showNtr &&
                                <div id={picture.url} style={{ width: "20%", height: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", paddingRight: "1%" }}>
                                    {parseFloat(sample.cal).toFixed(1)}
                                </div>}
                            {showNtr &&
                                <div id={picture.url} style={{ width: "20%", height: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", paddingRight: "1%" }}>
                                    {parseFloat(sample.prot).toFixed(1)}
                                </div>}
                        </div>
                    </div >
                )
            }
        }
        else {
            for (const sample of picture.samples) {
                result.push(
                    <div className="pictures-sample-div" style={{ width: "100%", height: "8vh", display: "flex", flexDirection: "row" }}
                        id={sample.mask_ref}
                        value={sample.image_ref}
                        onMouseEnter={(e) => { setHoveredAlim(e.target.id); setHoveredImg(sample.image_ref) }}
                        onMouseOut={(e) => { setHoveredAlim(null); setHoveredImg(null) }}>
                        <div id={sample.mask_ref}
                            value={sample.image_ref} style={{ width: showNtr ? "40%" : "60%", height: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", paddingLeft: "1%" }}>
                            {getFoodName(sample)}
                        </div>
                        <div id={sample.mask_ref}
                            value={sample.image_ref} style={{ width: showNtr ? "20%" : "40%", height: "100%", display: "flex", justifyContent: showNtr ? "center" : "flex-start", alignItems: "center", paddingRight: "1%" }}>
                            {t('mass', { value: { mass: sample.mass, unit: "g" } })}
                        </div>
                        {showNtr &&
                            <div id={sample.mask_ref}
                                value={sample.image_ref} style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "1%" }}>
                                {parseFloat(sample.cal).toFixed(1)}
                            </div>}
                        {showNtr &&
                            <div id={sample.mask_ref}
                                value={sample.image_ref} style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "1%" }}>
                                {parseFloat(sample.prot).toFixed(1)}
                            </div>}
                    </div>
                )
            }

        }
        return result;
    }
    const updatePicturesToDelete = (e) => {
        let currentPictures = JSON.parse(JSON.stringify(props.picturesToDelete));
        let index = currentPictures.findIndex(a => a === e.target.id);
        if (index === -1)
            currentPictures.push(e.target.id)
        else currentPictures.splice(index, 1)
        dispatch(setPicturesToDelete(currentPictures))
    }
    const getRows = () => {
        let result = [];
        let length = 0;
        let allPictures = JSON.parse(JSON.stringify(props.pictures));
        let picturesDay = { pictures: [] }
        if (props.selectedDate !== t('components.allMonth'))
            picturesDay = props.pictures.find(a => a.date === props.selectedDate);
        else {
            let tmp = allPictures.filter(a => getDateMajFormat(moment(a.date).format('MMMM YYYY')) === props.selectedMonth)
            if (tmp) {
                for (const picture of tmp) {
                    picturesDay.pictures = picturesDay.pictures.concat(picture.pictures)
                }
            }
        }
        if (picturesDay && picturesDay.pictures) {
            let picturesFinal = JSON.parse(JSON.stringify(picturesDay.pictures));
            if (props.selectedFilter === "filterMass")
                picturesFinal.sort((a, b) => b.mass - a.mass)
            else {
                picturesFinal.sort((a, b) =>
                    moment(a.created_at).isSameOrBefore(moment(b.created_at)) ? -1 : 1
                )
            }
            if (selectedAlim && selectedAlim !== t('foods.allFoods')) {
                picturesFinal = picturesFinal.filter(a => a.samples.find(b => getFoodName(b) === selectedAlim) != null)
            }
            length = picturesFinal.length
            for (const [index, picture] of picturesFinal.entries()) {
                if (index < 30 * parseInt(selectedPage) && index >= 30 * (parseInt(selectedPage) - 1)) {
                    let image_ref = picture.url;
                    result.push(
                        <div id={image_ref} style={{ width: "100%", display: "flex", flexDirection: "row", backgroundColor: index % 2 === 0 && "#F0F0F0", color: "#666666", minHeight: "65%" }}>
                            <div id={image_ref} style={{ width: "25%", height: "auto", padding: "2%", cursor: "pointer", position: "relative", display: "flex", alignItems: "center" }} onClick={(e) => dispatch(setSelectedPicture(e.target.id))} >
                                <img alt={image_ref} id={image_ref} className="img-pictures" src={image_ref} />
                                {
                                    (hoveredAlim && hoveredImg === picture.image_ref)
                                    &&
                                    <img alt={baseUrl + hoveredAlim} id={baseUrl + hoveredAlim} className="img-masks" src={baseUrl + hoveredAlim} />
                                }
                            </div>
                            {picture.samples.length > 2 ?
                                <div style={{ width: (props.userState.login === "admin" || props.restaurant_name === "DESCOBERTAS") ? showNtr ? "58%" : "32%" : "75%", paddingTop: "1%", paddingLeft: "3%", height: "60vh" }}>
                                    <CustomScrollDiv update={2}>
                                        {getAlims(picture)}
                                    </CustomScrollDiv>
                                </div>
                                :
                                <div style={{ width: (props.userState.login === "admin" || props.restaurant_name === "DESCOBERTAS") ? showNtr ? "58%" : "32%" : "75%", paddingTop: "1%", paddingLeft: "3%", height: "auto" }}>
                                    {getAlims(picture)}
                                </div>}
                            {(props.userState.login === "admin" || props.restaurant_name === "DESCOBERTAS") &&
                                <div style={{ width: showNtr ? "8%" : "23%", paddingLeft: "0%", height: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <input type="checkbox" className='custom-checkbox-pictures'
                                        style={{
                                            fontSize: "2.5vw", width: "4vw", height: "4vw", backgroundColor:
                                                props.picturesToDelete.find(a => a === picture.image_ref) != null ? "#ECAC68" : "#D9D9D9"
                                        }}
                                        id={picture.image_ref} onChange={updatePicturesToDelete} checked={props.picturesToDelete.find(a => a === picture.image_ref) != null} />

                                </div>}
                            {(props.userState.login === "admin" || props.restaurant_name === "DESCOBERTAS") &&
                                <div style={{ width: showNtr ? "9%" : "20%", height: "auto", }}>
                                    <div style={{ height: "25%" }} />
                                    <div style={{ height: "20%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <CustomButton
                                            width={"90%"}
                                            height={"80%"}
                                            color={"#fff"}
                                            backgroundColor={"#90C261"}
                                            onClick={() => setPictureToModif(picture)}>
                                            {t('components.modifAlims')}
                                        </CustomButton>
                                    </div>
                                    <div style={{ height: "10%" }} />
                                    <div style={{ height: "20%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <CustomButton
                                            width={"90%"}
                                            height={"80%"}
                                            color={"#fff"}
                                            backgroundColor={"#ECAC68"}
                                            onClick={() => setPictureToDelete(picture)}>
                                            {t('components.deleteAlim')}

                                        </CustomButton>
                                    </div>
                                    <div style={{ height: "5%" }} />
                                </div>}
                        </div >
                    )
                }
            }
        }
        return { result: result, length: length };
    }

    const getFoods = () => {
        let result = [];
        let allPictures = props.pictures.find(a => a.date === props.selectedDate);
        if (allPictures) {
            for (const picture of allPictures.pictures) {
                for (const sample of picture.samples) {
                    if (!result.find(a => a === getFoodName(sample)))
                        result.push(getFoodName(sample))
                }
            }
        }
        result.sort((a, b) => a.localeCompare(b))
        result.unshift(t('foods.allFoods'))
        return result;
    }
    useEffect(() => {
        updateNbPages()
    }, [selectedAlim, props.selectedDate]);
    const updateNbPages = () => {

        let length = getRows().length;
        let tmpNumberPages = Math.floor(length / 30) + (length % 30 !== 0 ? 1 : 0);
        setNumberPages(tmpNumberPages);
    }

    const updateSelectedPage = (id) => {
        if (id !== '...') {
            setSelectedPage(id);
            setUpdate(old => old + 1)
        }
    }
    const nextSelectedPage = () => {
        setUpdate(old => old + 1)
        if (parseInt(selectedPage) < parseInt(numberPages))
            setSelectedPage(old => parseInt(old) + 1);

    }
    const prevSelectedPage = () => {
        setUpdate(old => old + 1)
        if (selectedPage > 1)
            setSelectedPage(old => parseInt(old) - 1);

    }
    const getPageSelection = (numberPages) => {
        let items = [selectedPage];
        const tmpPage = parseInt(selectedPage);
        if (tmpPage === 1) {
            if (numberPages > 1)
                items.push(2);
            if (numberPages > 3)
                items.push(3);
            if (numberPages > 4)
                items.push('...');
        }
        else if (tmpPage === 2) {
            if (numberPages > 2)
                items.push(3)
            if (numberPages > 3)
                items.push('...')
            items.unshift(1)
        }
        else if (tmpPage === numberPages) {
            if (numberPages > tmpPage - 1)
                items.unshift(tmpPage - 1)
            if (numberPages > tmpPage - 2)
                items.unshift(tmpPage - 2)
            if (numberPages > tmpPage - 3)
                items.unshift('...')
        }
        else {
            if (numberPages > tmpPage)
                items.push(tmpPage + 1);
            if (numberPages > tmpPage + 1)
                items.push('...');
            items.unshift(tmpPage - 1);
            items.unshift('...');
        }
        let result = [<div className="dashboard-pictures-number-div" onClick={() => prevSelectedPage()}>{"<"}</div>];
        for (const item of items) {
            result.push(
                <div id={item} className="dashboard-pictures-number-div"
                    style={{
                        fontWeight: parseInt(item) === parseInt(selectedPage) ? 600 : 300,
                        textDecoration: parseInt(item) === parseInt(selectedPage) ? "underline" : "none"
                    }}
                    onClick={(e) => updateSelectedPage(e.target.id)}>{item}</div>
            )
        }
        result.push(<div className="dashboard-pictures-number-div" onClick={() => nextSelectedPage()}>{">"}</div>);
        return result;
    }
    const getDropdownFilter = () => {
        let values = ["filterMass", "filterHour"]
        return (
            <div
                ref={dropdownRef}
                className='custom-dropdown'
                id={'general-dropdown-food'}
                style={{
                    height: "60%",
                    width: "18%",
                    color: "#666666",
                    backgroundColor: "#fff",
                    zIndex: dropdownAlimsOpen ? 100000 : 1,
                    border: "1px solid #666666",
                    boxShadow: "none"
                }}
                onClick={(e) => { changeDropdownAlimsOpen() }}>
                {t('components.filterBy')}
                <ArrowDown
                    id={'general-dropdown-food'} className='arrow-down-img' fill={"#666666"} />

                < div className="custom-dropdown-choices"
                    style={{
                        width: "100%",
                        display: dropdownAlimsOpen ? 'block' : 'none',
                        backgroundColor: '#fff',
                        borderRight: "1px solid #666666",
                        borderBottom: "1px solid #666666",
                        borderLeft: "1px solid #666666",
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px"
                    }}>
                    {values.map((value) => {
                        return (
                            <div key={'dropdown-food-' + value} id={value} className="custom-dropdown-choice-div"
                                style={{
                                    display: "flex", flexDirection: "row", height: "4.5vh", backgroundColor: "#fff", color: "#666666",
                                    borderBottomLeftRadius: "10px",
                                    borderBottomRightRadius: "10px"
                                }}
                            >
                                <div id={value} style={{ width: "10%", height: "100%" }} onClick={(e) => ""}>
                                    <CustomCheckbox width={"2vh"} height={"2vh"} checked={value === props.selectedFilter} id={value} onChange={(e) => dispatch(setSelectedFilterPictures(e.target.id))} />
                                </div>
                                <div id={value} style={{ width: "80%", height: "100%" }} >
                                    {t(`components.${value}`)}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

        )
    }
    if (!props.loading)
        return (
            <CustomBlock width={"100%"} height={"100%"}>
                {imageModal()}
                {modifModal()}
                {deleteModal()}
                <div style={{ height: "10%", width: "100%", display: 'flex', flexDirection: "row" }}>
                    <div className="dashboard-block-title" style={{ width: "30%", paddingTop: ".5%", paddingLeft: "2%", height: "100%", flexDirection: "column", zIndex: 1 }}>
                        {t('components.pictures')}
                        <div className="dasboard-picture-select-page-div">
                            {getPageSelection(numberPages)}
                        </div>
                    </div>
                    <div style={{ position: "absolute", zIndex: 0, width: "100%", height: "10.1%", left: "0%", top: "-0.1%", paddingTop: "0%", paddingLeft: "1%", display: "flex", alignItems: "center", justifyContent: "center" }}>

                    </div>
                    <div style={{ position: "absolute", width: "100%", height: "10.1%", right: "0%", top: "-0.1%", display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: "1%" }}>
                        <CustomAutocomplete
                            autocomplete={true}
                            width={"18%"} height={"60%"} backgroundColor={"#fff"} arrowColor={"#666666"} color={"#666666"}
                            displayedValue={selectedAlim || t('foods.allFoods')}
                            values={getFoods()}
                            onChange={e => { setSelectedPage(1); setSelectedAlim(e.target.id) }}
                            isOpen={dropdownIsOpen}
                            maxHeightDropdown={"30vh"}
                            fontSize={"1vw"}
                            zIndex={1000000}
                            onClick={changeDropdownOpen}
                            border={"1px solid #666666"}
                            boxShadow={"none"}
                        />
                        <div style={{ width: "2%" }} />
                        {getDropdownFilter()}
                    </div>
                </div>
                <div style={{ height: "8%", width: "100%", display: "flex", flexDirection: "row", color: "#666666", fontWeight: 500, fontSize: "1.2vw" }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "25%", paddingRight: "1%" }}>
                        {t('table.picture')}
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: (props.userState.login === "admin" || props.restaurant_name === "DESCOBERTAS") ? showNtr ? "58%" : "32%" : "75%", paddingLeft: "3%" }}>
                        {showNtr ?
                            <div style={{ display: "flex", width: "100%" }}>
                                <div style={{ width: "40%", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                    {t('table.alim')}
                                </div>
                                <div style={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {t('table.weight')}
                                </div>
                                <div style={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {t('table.calories')}
                                </div>
                                <div style={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {t('table.proteins')}
                                </div>
                            </div>
                            :
                            t('table.associatedValues')
                        }
                    </div>
                    {props.userState.login === "admin" && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: showNtr ? "8%" : "23%" }}>
                        {t('table.selection')}
                    </div>}
                    {props.userState.login === "admin" && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: showNtr ? "9%" : "20%" }}>
                        {t('table.actions')}
                    </div>}
                </div>
                <div style={{ height: "80%", width: "100%" }}>
                    <CustomScrollDiv update={update}>
                        {getRows().result}
                    </CustomScrollDiv>

                </div>
            </CustomBlock >
        )
    else
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <CustomLoading />
            </div>
        )
}

export default PicturesDetails;