import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import HorizontalBarChart from "../../../charts/HorizontalBarChart";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);


const AverageWaste = (props) => {
    const { t } = useTranslation();


    let firstAnalysis = JSON.parse(JSON.stringify(props.analyzesDatas)).filter(a => parseInt(a.analysisNumber) === 1);
    console.log(firstAnalysis)
    let currentAnalysis = props.analysis;
    const getDatesAnalysis = () => {

        let dateFirst = '';
        let dateCurrent = '';
        if (firstAnalysis[0])
            dateFirst = getDateMajFormat(t('date', { value: { date: moment(firstAnalysis[0].dateEnd).subtract(7, "days"), format: "MM/YY" } }))
        if (currentAnalysis)
            dateCurrent = getDateMajFormat(t('date', { value: { date: moment(currentAnalysis.dateEnd).subtract(7, "days"), format: "MM/YY" } }))
        if (props.has_kitchen) {
            let firstKitchenAnalysis = JSON.parse(JSON.stringify(props.kitchenAnalyzesDatas)).filter(a => a && parseInt(a.analysisNumber) === 1)
            if (firstKitchenAnalysis[0])
                dateFirst = getDateMajFormat(t('date', { value: { date: moment(firstKitchenAnalysis[0].dateEnd).subtract(7, "days"), format: "MM/YY" } }))
        }
        if (props.kitchenAnalysis) {
            dateCurrent = getDateMajFormat(t('date', { value: { date: moment(props.kitchenAnalysis.dateEnd).subtract(7, "days"), format: "MM/YY" } }))
        }
        return {
            dateFirst: dateFirst,
            dateCurrent: dateCurrent
        }
    }
    const getDateMajFormat = (date) => {
        return date.charAt(0).toUpperCase() + date.slice(1)
    }

    const getData = () => {
        let result = [
            {
                name: "0",
                y: 0,
                x: 0,
                color: "transparent"
            },
            {
                name: t('components.firstAnalysis', { date: getDatesAnalysis().dateFirst }),
                y: 1,
                x: 80,
                color: "#F8E187"
            },
            {
                name: "1",
                y: 2,
                x: 0,
                color: "transparent"
            },
            {
                name: t('components.currentAnalysis', { date: getDatesAnalysis().dateCurrent }),
                y: 3,
                x: 75,
                color: "#ACDCAA"
            },
            {
                name: "2",
                y: 4,
                x: 0,
                color: "transparent"
            },
        ];
        let firstMass = 0;
        let firstTrays = 0;
        for (const analysis of firstAnalysis) {
            firstMass = parseFloat(firstMass) + parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass);
            firstTrays = parseInt(firstTrays) + parseInt(analysis.trays);
        }
        let firstMassConv = 0;
        let firstMassConvKitchen = 0;
        let currentMassConv = 0;
        let currentMassConvKitchen = 0;
        if (currentAnalysis) {

            firstMassConv = firstTrays > 0 && firstMass / firstTrays
            currentMassConv = currentAnalysis.trays > 0 && (currentAnalysis.mass - currentAnalysis.inevitableMass) / currentAnalysis.trays
        }

        if (props.has_kitchen) {
            let firstKitchenAnalysis = JSON.parse(JSON.stringify(props.kitchenAnalyzesDatas)).filter(a => a && parseInt(a.analysisNumber) === 1)
            let kitchenFirstMass = 0;
            let kitchenFirstTrays = 0;
            for (const analysis of firstKitchenAnalysis) {
                kitchenFirstMass = parseFloat(kitchenFirstMass) + parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass);
                kitchenFirstTrays = parseInt(kitchenFirstTrays) + parseInt(analysis.trays);
            }
            firstMassConvKitchen = kitchenFirstTrays > 0 && parseFloat(kitchenFirstMass) / parseInt(kitchenFirstTrays)
            if (props.kitchenAnalysis) {
                currentMassConvKitchen = props.kitchenAnalysis.trays > 0 && (parseFloat(props.kitchenAnalysis.mass) - parseFloat(props.kitchenAnalysis.inevitableMass)) / (parseInt(props.kitchenAnalysis.trays))
            }
        }
        if (getTypesForAnalysis() === "all") {
            result[1].mass = (firstMassConv + firstMassConvKitchen).toFixed(1);
            result[3].mass = (currentMassConv + currentMassConvKitchen).toFixed(1);
            result[1].x = (firstMassConv + firstMassConvKitchen) > 250 ? 250 : (firstMassConv + firstMassConvKitchen).toFixed(1);
            result[3].x = (currentMassConv + currentMassConvKitchen) > 250 ? 250 : (currentMassConv + currentMassConvKitchen).toFixed(1);

        }
        else if (getTypesForAnalysis() === "restaurant") {
            result[1].mass = (firstMassConv).toFixed(1);
            result[3].mass = (currentMassConv).toFixed(1);
            result[1].x = firstMassConv > 160 ? 160 : (firstMassConv).toFixed(1);
            result[3].x = firstMassConv > 160 ? 160 : (currentMassConv).toFixed(1);
        }
        else {
            result[1].mass = (firstMassConvKitchen).toFixed(1);
            result[3].mass = (currentMassConvKitchen).toFixed(1);
            result[1].x = firstMassConvKitchen > 80 ? 80 : (firstMassConvKitchen).toFixed(1);
            result[3].x = currentMassConvKitchen > 80 ? 80 : (currentMassConvKitchen).toFixed(1);
        }

        return result;
    }
    const getTypesForAnalysis = () => {
        if (props.analysis && props.kitchenAnalysis)
            return "all";
        else if (props.analysis && !props.kitchenAnalysis)
            return "restaurant";
        else if (!props.analysis && props.kitchenAnalysis)
            return "kitchen"
    }
    const getTitleText = () => {
        if (getTypesForAnalysis() === "kitchen")
            return t('components.averageWasteTitleKitchen');
        else if (getTypesForAnalysis() === "restaurant")
            return t('components.averageWasteTitleRestaurant');
        else return t('components.averageWasteTitleAll');
    }
    return (
        <CustomBlock width={'100%'} height={'100%'}>
            <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                <div className="dashboard-block-title" style={{ paddingLeft: "1.25%" }}>
                    {getTitleText()}
                </div>
                <div className="dashboard-graph-body">
                    <HorizontalBarChart data={getData()} type={getTypesForAnalysis()} bottom={40} top={25} right={20} left={20} />
                </div>
            </div>
        </CustomBlock>
    )
}
export default AverageWaste;