const CustomButton = (props) => {
    return (
        <button className='custom-button'
            id={props.id}
            style={{
                height: props.height,
                width: props.width,
                marginLeft: props.marginLeft || 0,
                marginRight: props.marginRight || 0,
                marginTop: props.marginTop || 0,
                marginBottom: props.marginBottom || 0,
                backgroundColor: props.backgroundColor,
                color: props.color || "#666666",
                fontSize: props.fontSize || '1.1vw',
                fontWeight: props.fontWeight || 400,
                paddingTop: props.paddingTop || 0,
                paddingBottom: props.paddingBottom || 0,
                paddingRight: props.paddingRight || 0,
                paddingLeft: props.paddingLeft || 0,
                position: props.position || "relative",
                top: props.top,
                bottom: props.bottom,
                right: props.right,
                left: props.left,
                selected: props.selected || false,
                filter:props.boxShadow,
                lineHeight:props.lineHeight,
            }}
            disabled={props.disabled}
            onClick={props.onClick}>
            {props.children}
            {props.value}
        </button>
    )
}

export default CustomButton;