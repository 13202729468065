import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import { useTranslation } from "react-i18next";
import CustomBlock from "../../../customComponents/CustomBlock";
import CustomTextField from "../../../customComponents/CustomTextField";
import CustomCheckbox from "../../../customComponents/CustomCheckbox";
import CustomDropdown from "../../../customComponents/CustomDropdown";
import CustomButton from "../../../customComponents/CustomButton";
import { updateArianneThread } from "../../../arianneThread/ArianneThread";
import { setCurrentPage } from "../../../../actions_reducers/actions/navigation.action";
import { setAllInformations, setSelectedInformations } from "../../../../actions_reducers/actions/admin.action";
import CustomScrollDiv from "../../../customComponents/CustomScrollBar";
import { updateInformations } from "../../../../apiRoutes/informations.routes";
import { ReactComponent as Cross } from "../../../../assets/cross.svg";
import { deleteFileForRestaurant, downloadFileRestaurant, getFilesOfRestaurantAndAnalysis, uploadFilesForRestaurant } from "../../../../apiRoutes/files.routes";

const ClientListSettings = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [showMails, setShowMails] = useState(false);
    const [file, setFile] = useState('');
    useEffect(() => {
        getClientSignature()
    }, []);
    const getClientSignature = async () => {
        let url = props.selectedClient + "/";
        let filesList = await getFilesOfRestaurantAndAnalysis(url, "signature");
        if (filesList && filesList[0]) {
            setFile(filesList[0].split(url)[1]);
        }
    }
    const backToClientList = () => {
        updateArianneThread("clientList", props.arianne, dispatch, "");
        dispatch(setCurrentPage("clientList"));
        dispatch(setSelectedInformations(null));
    }
    const confirmChanges = async () => {
        await updateInformations(props.selectedInformations);
        let informations = JSON.parse(JSON.stringify(props.informations));
        let index = informations.findIndex(a => a.restaurantName === props.selectedInformations.restaurantName);
        informations[index] = props.selectedInformations;
        dispatch(setAllInformations(informations));
        window.alert('Les informations du restaurant ont bien été mises à jour.');
    }
    const getValues = () => {
        return {
            restaurantName: props.selectedInformations.restaurantName,
            clientName: props.selectedInformations.clientName,
            displayName: props.selectedInformations.displayName,
            morning: props.selectedInformations.morning,
            evening: props.selectedInformations.evening,
            noon: props.selectedInformations.noon,
            saturday: props.selectedInformations.saturday,
            sunday: props.selectedInformations.sunday,
            multipleCams: props.selectedInformations.multipleCams,
            camsNames: props.selectedInformations.camsNames,
            hasTheoricNumber: props.selectedInformations.hasTheoricNumber,
            massUnit: props.selectedInformations.massUnit,
            costUnit: props.selectedInformations.costUnit,
            averageTrays: props.selectedInformations.averageTrays,
            hasSynthesis: props.selectedInformations.hasSynthesis,
            hasRestaurant: props.selectedInformations.hasRestaurant,
            hasKitchen: props.selectedInformations.hasKitchen,
            mainReceiversMails: props.selectedInformations.mainReceiversMails,
            copyReceiversMails: props.selectedInformations.copyReceiversMails,
            synthesisName: props.selectedInformations.synthesisName
        }
    }
    const updateValue = (field, newValue) => {
        let informations = JSON.parse(JSON.stringify(props.selectedInformations));
        informations[field] = newValue;
        dispatch(setSelectedInformations(informations));
    }
    const changeCameras = () => {
        let informations = JSON.parse(JSON.stringify(props.selectedInformations));
        if (informations.camsNames.length === 0) {
            informations.camsNames = [
                {
                    numberCam: 1,
                    nameCam: ""
                },
                {
                    numberCam: 2,
                    nameCam: ""
                }
            ]
            informations.multipleCams = true
            dispatch(setSelectedInformations(informations))
        }
        else {
            informations.multipleCams = false
            informations.camsNames = [];
            dispatch(setSelectedInformations(informations))
        }
    }
    const updateNameCam = (index, value) => {
        let informations = JSON.parse(JSON.stringify(props.selectedInformations))
        informations.camsNames[index].nameCam = value;
        dispatch(setSelectedInformations(informations));
    }
    const getSettingsDisplay = () => {
        return (
            <CustomBlock
                width={"100%"}
                height={"90%"}
                paddingLeft={"2%"}
                paddingRight={"2%"}
                paddingBottom={"1%"}
                paddingTop={"1%"}>
                <div style={{ height: "10%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ position: "absolute", left: "2%", width: "100%", height: "100%", justifyContent: "flex-start", alignItems: "center", display: "flex", height: "100%" }}>
                        {clientSignature()}
                    </div>
                    {getValues().restaurantName}
                </div>
                <div style={{ height: "10%", width: "100%", display: "flex" }}>
                    <div style={{ width: "50%", height: "100%" }}>
                        <div style={{ width: "100%", height: "50%", display: "flex" }}>
                            <div style={{ width: "42%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                Display name :
                            </div>
                            <div style={{ width: "1%" }} />
                            <div style={{ width: "59%", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <CustomTextField filter={"none"}
                                    width={"55%"}
                                    height={"80%"}
                                    backgroundColor={"#D9D9D9"}
                                    borderRadius={"10px"}
                                    value={getValues().displayName}
                                    onChange={(e) => updateValue('displayName', e.target.value)} />
                            </div>

                        </div>
                        <div style={{ width: "100%", height: "50%", display: "flex" }}>
                            <div style={{ width: "42%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                Nom sur la synthèse :
                            </div>
                            <div style={{ width: "1%" }} />
                            <div style={{ width: "59%", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <CustomTextField filter={"none"}
                                    width={"55%"}
                                    height={"80%"}
                                    backgroundColor={"#D9D9D9"}
                                    borderRadius={"10px"}
                                    value={getValues().synthesisName}
                                    onChange={(e) => updateValue('synthesisName', e.target.value)} />
                            </div>

                        </div>

                    </div>
                    <div style={{ width: "50%", height: "100%", display: "flex" }}>
                        <div style={{ width: "49%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            Client :
                        </div>
                        <div style={{ width: "1%" }} />
                        <div style={{ width: "49%", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            {getValues().clientName}
                        </div>
                    </div>
                </div>
                <div style={{ height: "65%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ height: "100%", width: "20%" }}>
                        <div style={{ height: "60%" }}>
                            <div style={{ height: "19%", display: "flex", justifyContent: "flex-start", alignItems: "flex-end" }}>
                                Services analysés
                            </div>
                            <div style={{ height: "27%", display: "flex" }}>
                                <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <CustomCheckbox checked={getValues().morning}
                                        onChange={() => updateValue('morning', !getValues().morning)} />
                                </div>
                                <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                    Matin
                                </div>
                            </div>
                            <div style={{ height: "27%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <CustomCheckbox checked={getValues().noon}
                                        onChange={() => updateValue('noon', !getValues().noon)} />
                                </div>
                                <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                    Midi
                                </div>
                            </div>
                            <div style={{ height: "27%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <CustomCheckbox checked={getValues().evening}
                                        onChange={() => updateValue('evening', !getValues().evening)} />
                                </div>
                                <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                    Soir
                                </div>
                            </div>
                        </div>
                        <div style={{ height: "40%" }}>
                            <div style={{ height: "50%", display: "flex", justifyContent: "flex-start", alignItems: "flex-end" }}>
                                Unité massique
                            </div>
                            <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomDropdown
                                    height={"50%"}
                                    width={"60%"}
                                    color={"#666666"}
                                    backgroundColor={"#D9D9D9"}
                                    values={["g", "lbs"]}
                                    displayedValue={getValues().massUnit}
                                    onChange={(e) => updateValue('massUnit', e.target.id)}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ height: "100%", width: "20%" }}>
                        <div style={{ height: "60%" }}>
                            <div style={{ height: "19%", display: "flex", justifyContent: "flex-start", alignItems: "flex-end" }}>
                                Jours de fonctionnement
                            </div>
                            {/*<div style={{ height: "27%", display: "flex" }}>
                                <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <CustomCheckbox checked={props.newRestaurantSaturday} onChange={() => ""} />
                                </div>
                                <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                    Du lundi au vendredi
                                </div>
                            </div>*/}
                            <div style={{ height: "27%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <CustomCheckbox checked={getValues().saturday}
                                        onChange={() => updateValue('saturday', !getValues().saturday)} />
                                </div>
                                <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                    Samedi
                                </div>
                            </div>
                            <div style={{ height: "27%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <CustomCheckbox checked={getValues().sunday}
                                        onChange={() => updateValue('sunday', !getValues().sunday)} />
                                </div>
                                <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                    Dimanche
                                </div>
                            </div>
                        </div>
                        <div style={{ height: "40%" }}>
                            <div style={{ height: "50%", display: "flex", justifyContent: "flex-start", alignItems: "flex-end" }}>
                                Unité monétaire
                            </div>
                            <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomDropdown
                                    height={"50%"}
                                    width={"60%"}
                                    color={"#666666"}
                                    backgroundColor={"#D9D9D9"}
                                    values={["€", "$"]}
                                    displayedValue={getValues().costUnit}
                                    onChange={(e) => updateValue('costUnit', e.target.id)}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ height: "100%", width: "20%", position: "relative" }}>
                        <div style={{ height: "60%" }}>
                            <div style={{ height: "19%", display: "flex", justifyContent: "flex-start", alignItems: "flex-end" }}>
                                Plusieurs caméras
                            </div>
                            <div style={{ height: "27%", display: "flex" }}>
                                <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <CustomCheckbox checked={getValues().multipleCams === true}
                                        onChange={() => changeCameras()} />
                                </div>
                                <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                    Oui
                                </div>
                            </div>
                            <div style={{ height: "27%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <CustomCheckbox checked={getValues().multipleCams === false}
                                        onChange={() => changeCameras()} />
                                </div>
                                <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                    Non
                                </div>
                            </div>

                        </div>

                        {getValues().multipleCams &&
                            <div style={{ position: "absolute", height: "80%", bottom: "-30%", width: "80%", left: "25%" }}>
                                <div style={{ height: "50%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <div style={{ height: "30%", width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                        Camera n°1
                                    </div>
                                    <div style={{ height: "70%", width: "100%" }}>
                                        <CustomTextField width={"70%"} height={"40%"}
                                            filter={"none"}
                                            backgroundColor={"#D9D9D9"}
                                            borderRadius={"10px"}
                                            type={"text"}
                                            value={getValues().camsNames[0].nameCam}
                                            onChange={(e) => updateNameCam(0, e.target.value)} />
                                    </div>
                                </div>
                                <div style={{ height: "50%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <div style={{ height: "30%", width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                        Camera n°2
                                    </div>
                                    <div style={{ height: "70%", width: "100%" }}>
                                        <CustomTextField width={"70%"} height={"40%"}
                                            filter={"none"}
                                            backgroundColor={"#D9D9D9"}
                                            borderRadius={"10px"}
                                            type={"text"}
                                            value={getValues().camsNames[1].nameCam}
                                            onChange={(e) => updateNameCam(1, e.target.value)} />
                                    </div>
                                </div>
                            </div>}
                    </div>
                    <div style={{ height: "100%", width: "20%" }}>
                        <div style={{ height: "60%" }}>
                            <div style={{ height: "19%", display: "flex", justifyContent: "flex-start", alignItems: "flex-end" }}>
                                Plateaux théoriques
                            </div>
                            <div style={{ height: "27%", display: "flex" }}>
                                <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <CustomCheckbox checked={getValues().hasTheoricNumber === true}
                                        onChange={() => updateValue('hasTheoricNumber', true)} />
                                </div>
                                <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                    Oui
                                </div>
                            </div>
                            <div style={{ height: "27%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <CustomCheckbox checked={getValues().hasTheoricNumber === false}
                                        onChange={() => updateValue('hasTheoricNumber', false)} />
                                </div>
                                <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                    Non
                                </div>
                            </div>
                        </div>
                        <div style={{ height: "40%" }}>
                            <div style={{ height: "50%", display: "flex", justifyContent: "flex-start", alignItems: "flex-end" }}>
                                Nombre de plateaux moyens
                            </div>
                            <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomTextField width={"60%"} height={"50%"}
                                    filter={"none"}
                                    type={"number"}
                                    backgroundColor={"#D9D9D9"}
                                    borderRadius={"10px"}
                                    value={getValues().averageTrays}
                                    onChange={(e) => updateValue('averageTrays', e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div style={{ height: "100%", width: "20%" }}>
                        <div style={{ height: "34%" }}>
                            <div style={{ height: "34.5%", display: "flex", justifyContent: "flex-start", alignItems: "flex-end" }}>
                                Synthèses rédigées
                            </div>
                            <div style={{ height: "37.75%", display: "flex" }}>
                                <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <CustomCheckbox checked={getValues().hasSynthesis === true}
                                        onChange={() => updateValue('hasSynthesis', true)} />
                                </div>
                                <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                    Oui
                                </div>
                            </div>
                            <div style={{ height: "37.75%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <CustomCheckbox checked={getValues().hasSynthesis === false}
                                        onChange={() => updateValue('hasSynthesis', false)} />
                                </div>
                                <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                    Non
                                </div>
                            </div>
                        </div>
                        <div style={{ height: "33%" }}>
                            <div style={{ height: "34.5%", display: "flex", justifyContent: "flex-start", alignItems: "flex-end" }}>
                                Restaurant
                            </div>
                            <div style={{ height: "37.75%", display: "flex" }}>
                                <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <CustomCheckbox checked={getValues().hasRestaurant === true}
                                        onChange={() => updateValue('hasRestaurant', true)} />
                                </div>
                                <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                    Oui
                                </div>
                            </div>
                            <div style={{ height: "37.75%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <CustomCheckbox checked={getValues().hasRestaurant === false}
                                        onChange={() => updateValue('hasRestaurant', false)} />
                                </div>
                                <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                    Non
                                </div>
                            </div>
                        </div>
                        <div style={{ height: "33%" }}>
                            <div style={{ height: "34.5%", display: "flex", justifyContent: "flex-start", alignItems: "flex-end" }}>
                                Cuisine
                            </div>
                            <div style={{ height: "37.75%", display: "flex" }}>
                                <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <CustomCheckbox checked={getValues().hasKitchen === true}
                                        onChange={() => updateValue('hasKitchen', true)} />
                                </div>
                                <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                    Oui
                                </div>
                            </div>
                            <div style={{ height: "37.75%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <CustomCheckbox checked={getValues().hasKitchen === false}
                                        onChange={() => updateValue('hasKitchen', false)} />
                                </div>
                                <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                    Non
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ height: "15%", position: "relative" }}>
                    <CustomButton
                        width={"10%"}
                        height={"50%"}
                        position={"absolute"} color={"#fff"} backgroundColor={"#90c261"} right={0} bottom={0} value={t('buttons.confirm')} onClick={() => confirmChanges()} />
                </div>
            </CustomBlock>)
    }
    const updateMail = (type, index, value) => {
        let informations = JSON.parse(JSON.stringify(props.selectedInformations))
        let typeName = 'mainReceiversMails';
        if (type === "copy") typeName = 'copyReceiversMails';
        if (informations[typeName]) {
            informations[typeName][index] = value;
        }
        else {
            informations[typeName] = [value];
        }
        dispatch(setSelectedInformations(informations));
    }
    const getMailsRow = (type) => {
        let list = getValues().mainReceiversMails;
        if (type === "copy") list = getValues().copyReceiversMails;
        let result = [];
        if (list) {
            for (const [index, mail] of list.entries()) {
                result.push(
                    <div style={{ width: "100%", height: "12%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomTextField filter={"none"}
                            width={"80%"}
                            height={"80%"}
                            backgroundColor={"#D9D9D9"}
                            borderRadius={"10px"}
                            value={mail}
                            onChange={(e) => updateMail(type, index, e.target.value)} />
                    </div>)
            }
            result.push(<div style={{ height: "5%" }} />)
            result.push(
                <div style={{ width: "100%", height: "12%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CustomTextField
                        width={"80%"}
                        height={"80%"}
                        filter={"none"}
                        backgroundColor={"#D9D9D9"}
                        borderRadius={"10px"}
                        onChange={(e) => updateMail(type, list.length, e.target.value)} />
                </div>)
        }
        else {
            result.push(
                <div style={{ width: "100%", height: "12%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CustomTextField filter={"none"}
                        width={"80%"}
                        height={"80%"}
                        backgroundColor={"#D9D9D9"}
                        borderRadius={"10px"}
                        onChange={(e) => updateMail(type, 0, e.target.value)} />
                </div>)
            result.push(<div style={{ height: "5%" }} />)
        }
        return result;
    }
    const getMailsDisplay = () => {
        return (
            <CustomBlock
                width={"100%"}
                height={"90%"}
                paddingLeft={"2%"}
                paddingRight={"2%"}
                paddingBottom={"1%"}
                paddingTop={"1%"}>
                <div style={{ height: "10%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {getValues().restaurantName}
                </div>
                <div style={{ height: "75%", display: "flex" }}>
                    <div style={{ width: "50%", height: "100%" }}>
                        <div style={{ height: "10%", justifyContent: "center", alignItems: "center", display: "flex" }}>Destinataires principaux</div>
                        <div style={{ height: "90%" }}>
                            <CustomScrollDiv update={Math.random()}>
                                {getMailsRow("main")}
                            </CustomScrollDiv>
                        </div>
                    </div>
                    <div style={{ width: "50%", height: "100%" }}>
                        <div style={{ height: "10%", justifyContent: "center", alignItems: "center", display: "flex" }}>Destinataires en copie</div>
                        <div style={{ height: "90%" }}>
                            <CustomScrollDiv update={Math.random()}>
                                {getMailsRow("copy")}
                            </CustomScrollDiv>
                        </div>
                    </div>
                </div>
                <div style={{ height: "15%", position: "relative" }}>
                    <CustomButton
                        width={"10%"}
                        height={"50%"}
                        position={"absolute"} color={"#fff"} backgroundColor={"#90c261"} right={0} bottom={0} value={t('buttons.confirm')} onClick={() => confirmChanges()} />
                </div>
            </CustomBlock>)
    }
    const handleChange = async (e) => {
        e.preventDefault();
        let url = props.selectedClient + "/" + e.target.files[0].name.replace(/ /g, "_");
        await uploadFilesForRestaurant(url, e.target.files[0], null, null, null, "signature");
        setFile(e.target.files[0].name.replace(/ /g, "_"))
    }
    const clickInputUpload = () => {
        document.getElementById("file").click();
    }
    const downloadFile = async (e) => {
        let fileName = file;
        let url = props.selectedClient + "/"
        let path = url + fileName;
        await downloadFileRestaurant(path, fileName, "signature");
    }
    const deleteFile = async () => {
        let url = props.selectedClient + "/"
        await deleteFileForRestaurant(url + file, "signature")
        setFile(null)
    }
    const clientSignature = () => {
        if (!file) {
            return (
                <div style={{ width: "50%", height: "100%", display: "flex", alignItems: "center" }}>
                    <div style={{ width: "28%", height: "100%", display: "flex", alignItems: "center" }}>
                        Signature restaurant :
                    </div>
                    <CustomButton width={"30%"} height={"5%"}
                        color="#fff"
                        onClick={() => clickInputUpload()}>
                        Cliquez pour ajouter
                        <input name="file" id="file" type="file" className="files-drop-input" multiple={false} onChange={handleChange} />
                    </CustomButton>
                </div>
            )
        }
        else return (
            <div style={{ width: "50%", height: "100%", display: "flex", alignItems: "center" }}>
                <div style={{ width: "28%", height: "100%", display: "flex", alignItems: "center" }}>
                    Signature restaurant :
                </div>
                <div style={{ width: "auto", height: "25%", display: "flex", alignItems: "center", color: "#007bff", textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => downloadFile()}>
                    {file}
                </div>
                <div style={{ width: "23%", height: "100%", display: "flex", alignItems: "center", paddingTop: "1%" }}>
                    <Cross style={{ width: "25%", height: "25%", cursor: "pointer" }} onClick={() => deleteFile()} />
                </div>
            </div>
        )
    }
    return (
        <div className="main-page-main-div" style={{ height: "85.8vh", display: "flex", justifyContent: "center", color: "#666666", flexDirection: "column" }}>
            <div style={{ height: "10%", position: "relative" }}>
                <CustomButton height={"70%"} width={"10%"} position={"absolute"} color={"#fff"} backgroundColor={"#666666"} left={0} value={t('buttons.back')} onClick={() => backToClientList()} />
                <CustomButton height={"70%"} width={"17%"} position={"absolute"} color={"#fff"} backgroundColor={"#89CD85B3"} right={0} value={showMails ? t('buttons.seeSettings') : t('buttons.seeMails')} onClick={() => setShowMails(!showMails)} />
            </div>
            {
                showMails ? getMailsDisplay() : getSettingsDisplay()
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        selectedInformations: state.adminReducer.selectedInformations,
        arianne: state.navigationReducer.arianne,
        informations: state.adminReducer.allInformations,
    };
}

export default connect(mapStateToProps)(ClientListSettings);
