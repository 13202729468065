import React from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import { useTranslation } from "react-i18next";
import CustomButton from "../../customComponents/CustomButton";
import { ReactComponent as Ppt } from "../../../assets/ppt.svg";

import pptxgen from "pptxgenjs";

import { getTeenSlide } from "./Sensi_AllPages/TeenPage";
import { getChildSlide } from "./Sensi_AllPages/ChildPage";
import { getAdultSlide } from "./Sensi_AllPages/AdultPage";
import { getEvolutionSlide } from "./Sensi_AllPages/EvolutionPage";
// import { getSecondSlide_Sensi } from "./Sensi_AllPages/FirstPage";
// import { getThirdSlide_Sensi } from "./Sensi_AllPages/FirstPage";

import { getFirstSlide } from "./Synthesis_AllPages/FirstPage";
import { getSecondSlide } from "./Synthesis_AllPages/SecondPage";
import { getThirdSlide } from "./Synthesis_AllPages/ThirdPage";
import { getFourthSlide } from "./Synthesis_AllPages/FourthPage";
import { getFifthSlide } from "./Synthesis_AllPages/FifthPage";
import { getSixthSlide } from "./Synthesis_AllPages/SixthPage";
import { getSeventhSlide } from "./Synthesis_AllPages/SeventhPage";
import { getEightSlide } from "./Synthesis_AllPages/EighthPage";
import { getNinethSlide } from "./Synthesis_AllPages/NinethPage";
import { getTenthSlide } from "./Synthesis_AllPages/TenthPage";
import { getRepartitionSlide } from "./Synthesis_AllPages/RepartitionSlide";
import { getTraysValues } from "../../../apiRoutes/trays.route";
import GaugeChart from "react-gauge-chart";
import CustomBlock from "../../customComponents/CustomBlock";
import CustomCheckbox from "../../customComponents/CustomCheckbox";
import { setDownloadModalOpen, setHasAssessment, setHasKitchenValues, setHasRestaurantValues, setSelectedDocuments } from "../../../actions_reducers/actions/files.action";
import { getNewAdultSlide } from "./Sensi_AllPages/AdultsPageRestaurant";
import SensiLineChart from "./Sensi_AllPages/SensiLineChart";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const Synthesis = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const updateSelectedDocuments = (e) => {
        let tmp = JSON.parse(JSON.stringify(props.selectedDocuments));
        let index = tmp.findIndex(a => a === e.target.id);
        if (index === -1)
            tmp.push(e.target.id);
        else
            tmp.splice(index, 1)
        dispatch(setSelectedDocuments(tmp))
    }

    const downloadModal = () => {
        let typesLeft = ["synthesis", "synthesisCommodity", "sensiAdult"];
        let typesRight = ["sensiTeen", "sensiChild", "sensiAdultCommodity"];
        if (props.downloadModalOpen === props.id) {
            return (

                <CustomBlock
                    width={"55vw"}
                    height={"45vh"}
                    top={"1%"}
                    left={"1%"}
                    paddingLeft={"1%"}
                    paddingRight={"1%"}
                    paddingTop={"1%"}
                    paddingBottom={"1%"}
                    backgroundColor={"#fff"}
                    position={"absolute"}
                >

                    <div style={{ height: "15%" }}>
                        {t('synthesis.title', { value: props.id })}
                    </div>
                    <div style={{ height: "35%", display: "flex" }}>
                        <div style={{ width: "50%" }}>
                            {typesLeft.map((item) => {
                                return (
                                    <div style={{ height: "33.33%", display: "flex", alignItems: "center" }}>
                                        <div style={{ width: "30%" }}>
                                            <CustomCheckbox id={item} checked={props.selectedDocuments.find(a => a === item)} onChange={updateSelectedDocuments} />
                                        </div>
                                        <div style={{ width: "70%", display: "flex", justifyContent: "flex-start" }}>
                                            {t(`synthesis.${item}`)}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{ width: "50%" }}>
                            {typesRight.map((item) => {
                                return (
                                    <div style={{ height: "33.33%", display: "flex", alignItems: "center" }}>
                                        <div style={{ width: "30%" }}>
                                            <CustomCheckbox id={item} checked={props.selectedDocuments.find(a => a === item)} onChange={updateSelectedDocuments} />
                                        </div>
                                        <div style={{ width: "70%", display: "flex", justifyContent: "flex-start" }}>
                                            {t(`synthesis.${item}`)}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "1%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ backgroundColor: "#666666", height: ".5px", width: "90%" }} />
                    </div>
                    <div style={{ height: "20%", display: "flex", alignItems: "center" }}>
                        <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
                            <div style={{ width: "30%" }}>
                                <CustomCheckbox checked={props.hasRestaurantValues} onChange={() => dispatch(setHasRestaurantValues(!props.hasRestaurantValues))} />
                            </div>
                            <div style={{ width: "70%", display: "flex", justifyContent: "flex-start" }}>
                                {t(`synthesis.hasRestaurantValues`)}
                            </div>
                        </div>
                        <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
                            <div style={{ width: "30%" }}>
                                <CustomCheckbox checked={props.hasKitchenValues} onChange={() => dispatch(setHasKitchenValues(!props.hasKitchenValues))} />
                            </div>
                            <div style={{ width: "70%", display: "flex", justifyContent: "flex-start" }}>
                                {t(`synthesis.hasKitchenValues`)}
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "1%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ backgroundColor: "#666666", height: ".5px", width: "90%" }} />
                    </div>
                    <div style={{ height: "18%", display: "flex", alignItems: "center" }}>
                        <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
                            <div style={{ width: "30%" }}>
                                <CustomCheckbox checked={props.hasAssessment === true} onChange={() => dispatch(setHasAssessment(true))} />
                            </div>
                            <div style={{ width: "70%", display: "flex", justifyContent: "flex-start" }}>
                                {t(`synthesis.withAssessment`)}
                            </div>
                        </div>
                        <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
                            <div style={{ width: "30%" }}>
                                <CustomCheckbox checked={props.hasAssessment === false} onChange={() => dispatch(setHasAssessment(false))} />
                            </div>
                            <div style={{ width: "70%", display: "flex", justifyContent: "flex-start" }}>
                                {t(`synthesis.withoutAssessment`)}
                            </div>
                        </div>
                    </div>
                    <div style={{ height: "10%", display: "flex" }}>
                        <div style={{ width: "50%" }}>
                            <CustomButton color={"#fff"} backgroundColor={"#666666"} id={"cancel"} width={"60%"} height={"100%"} value={t('buttons.cancel')} onClick={() => dispatch(setDownloadModalOpen(null))} />
                        </div>
                        <div style={{ width: "50%" }}>
                            <CustomButton color={"#fff"} backgroundColor={"#90c261"} id={"download"} width={"60%"} height={"100%"} value={t('buttons.download')} onClick={() => generateSynthesises()} />
                        </div>
                    </div>
                </CustomBlock>
            )
        }
    }
    const getValues = (analysis, kitchenAnalysis) => {
        let massRestaurant = 0;
        let massKitchen = 0;
        let traysRestaurant = 0;
        let traysKitchen = 0;
        if (analysis) {
            massRestaurant = parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass);
            traysRestaurant = analysis.trays;
        }
        if (kitchenAnalysis) {
            massKitchen = parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass);
            traysKitchen = kitchenAnalysis.trays;
        }
        let massConv = traysRestaurant > 0 ? massRestaurant / traysRestaurant : 0;
        let massConvKitchen = traysKitchen > 0 ? massKitchen / traysKitchen : 0;
        let massConvTot = parseFloat(massConv) + parseFloat(massConvKitchen)
        return {
            massConvTot: massConvTot,
        }
    }

    const getAnalysisNumber = () => {
        return props.id.split('analyse_')[1]
    }
    const getAnalyzes = () => {
        return props.analyzesDatas.find(a => parseInt(a.analysisNumber) === parseInt(getAnalysisNumber()))
    }
    const getKitchenAnalyzes = () => {
        return props.kitchenDatas.find(a => parseInt(a.analysisNumber) === parseInt(getAnalysisNumber()))
    }

    const getPreviousAnalysis = () => {
        return props.analyzesDatas.find(a => parseInt(a.analysisNumber) === parseInt(getAnalysisNumber()) - 1)
    }

    const getPreviousKitchenAnalysis = () => {
        return props.kitchenDatas.find(a => parseInt(a.analysisNumber) === parseInt(getAnalysisNumber()) - 1)
    }

    const getNeedleRotation = () => {
        let percent = 0;

        let analysis = getAnalyzes();
        let kitchenAnalysis = getKitchenAnalyzes();
        let massConv = getValues(analysis, kitchenAnalysis).massConvTot
        if (massConv > 150)
            massConv = 150;
        percent = massConv / 150
        return percent;
    }

    const generateSensi_adult = async () => {
        let pres = new pptxgen();
        pres.defineLayout({ name: "A4", width: 8.27272727, height: 11.6957041 });
        pres.layout = "A4";
        pres.defineSlideMaster({
            title: 'sensi',
            background: { color: "#ffffff" },
            objects: [],
        });


        let analysis = props.hasRestaurantValues ? getAnalyzes() : null;
        let kitchenAnalysis = props.hasKitchenValues ? getKitchenAnalyzes() : null;
        let previousAnalysis = props.hasRestaurantValues ? getPreviousAnalysis() : null;
        let previousKitchenAnalysis = props.hasKitchenValues ? getPreviousKitchenAnalysis() : null;
        //pres = getNewAdultSlide(pres, t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, 'day', 35, props.restaurant_name, props.food_list, props.food_list_kitchen, "", props.userState.averageGuests, props.displayName)
        pres = getAdultSlide(pres, t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, 'day', 35, props.restaurant_name, props.food_list, props.food_list_kitchen, "", props.userState.averageGuests, props.displayName);
        pres = getAdultSlide(pres, t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, 'week', 35, props.restaurant_name, props.food_list, props.food_list_kitchen, "", props.userState.averageGuests, props.displayName);
        pres = getAdultSlide(pres, t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, 'year', 28, props.restaurant_name, props.food_list, props.food_list_kitchen, "", props.userState.averageGuests, props.displayName);
        pres = getEvolutionSlide(pres, t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, 28, props.restaurant_name, props.food_list, props.food_list_kitchen, "", props.userState.averageGuests, props.displayName);

        pres.writeFile({ fileName: props.restaurant_name + "_sensibilisation_adultes_" + getAnalysisNumber() + ".pptx" });
    }
    const generateSensiAdultCommodityCost = async () => {
        let pres = new pptxgen();
        pres.defineLayout({ name: "A4", width: 11.6957041, height: 8.27272727 });
        pres.layout = "A4";
        pres.defineSlideMaster({
            title: 'sensi',
            background: { color: "F8F8F3" },
            objects: [],
        });


        let analysis = props.hasRestaurantValues ? getAnalyzes() : null;
        let kitchenAnalysis = props.hasKitchenValues ? getKitchenAnalyzes() : null;
        let previousAnalysis = props.hasRestaurantValues ? getPreviousAnalysis() : null;
        let previousKitchenAnalysis = props.hasKitchenValues ? getPreviousKitchenAnalysis() : null;

        pres = getAdultSlide(pres, t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, 'day', 33, props.restaurant_name, props.food_list, props.food_list_kitchen, "commodity_cost", props.userState.averageGuests, props.displayName);
        pres = getAdultSlide(pres, t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, 'week', 33, props.restaurant_name, props.food_list, props.food_list_kitchen, "commodity_cost", props.userState.averageGuests, props.displayName);
        pres = getAdultSlide(pres, t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, 'year', 28, props.restaurant_name, props.food_list, props.food_list_kitchen, "commodity_cost", props.userState.averageGuests, props.displayName);
        pres = getEvolutionSlide(pres, t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, 28, props.restaurant_name, props.food_list, props.food_list_kitchen, "commodity_cost", props.userState.averageGuests, props.displayName);

        pres.writeFile({ fileName: props.restaurant_name + "_sensibilisation_adultes_commodity_cost" + getAnalysisNumber() + ".pptx" });
    }

    const generateSensi_child = async () => {
        let pres = new pptxgen();
        pres.defineLayout({ name: "A4", width: 8.27272727, height: 11.6957041 });
        pres.layout = "A4";
        pres.defineSlideMaster({
            title: 'sensi',
            background: { color: "F8F8F3" },
            objects: [],
        });


        let analysis = props.hasRestaurantValues ? getAnalyzes() : null;
        let kitchenAnalysis = props.hasKitchenValues ? getKitchenAnalyzes() : null;
        let previousAnalysis = props.hasRestaurantValues ? getPreviousAnalysis() : null;
        let previousKitchenAnalysis = props.hasKitchenValues ? getPreviousKitchenAnalysis() : null;

        pres = getChildSlide(pres, t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, "day", 25, 30, props.restaurant_name, props.userState.averageGuests, props.displayName);
        pres = getChildSlide(pres, t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, "week", 25, 30, props.restaurant_name, props.userState.averageGuests, props.displayName);
        pres = getChildSlide(pres, t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, "year", 18, 25, props.restaurant_name, props.userState.averageGuests, props.displayName);


        pres.writeFile({ fileName: props.restaurant_name + "_sensibilisation_enfants_" + getAnalysisNumber() + ".pptx" });
    }

    const generateSensi_teens = async () => {
        let pres = new pptxgen();
        pres.defineLayout({ name: "A4", width: 8.27272727, height: 11.6957041 });
        pres.layout = "A4";
        pres.defineSlideMaster({
            title: 'sensi',
            background: { color: "F8F8F3" },
            objects: [],
        });


        let analysis = props.hasRestaurantValues ? getAnalyzes() : null;
        let kitchenAnalysis = props.hasKitchenValues ? getKitchenAnalyzes() : null;
        let previousAnalysis = props.hasRestaurantValues ? getPreviousAnalysis() : null;
        let previousKitchenAnalysis = props.hasKitchenValues ? getPreviousKitchenAnalysis() : null;


        pres = getTeenSlide(pres, t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, props.restaurant_name);

        pres.writeFile({ fileName: props.restaurant_name + "_sensibilisation_ados_" + getAnalysisNumber() + ".pptx" });
    }


    const generateSynthesis = async () => {
        let x = 0;
        let pres = new pptxgen();
        pres.defineLayout({ name: "Standard", width: 33.87 / 2.54, height: 19.09 / 2.54 })
        pres.layout = "Standard"
        pres.defineSlideMaster({
            title: 'TITLE_SLIDE',
            background: { color: "F8F8F3" },
            objects: [
            ],
        });

        let name = props.displayName ? props.displayName : props.restaurant_name;
        let analysis = props.hasRestaurantValues ? getAnalyzes() : null;
        let kitchenAnalysis = props.hasKitchenValues ? getKitchenAnalyzes() : null;
        let previousAnalysis = props.hasRestaurantValues ? getPreviousAnalysis() : null;
        let previousKitchenAnalysis = props.hasKitchenValues ? getPreviousKitchenAnalysis() : null;
        let dateStart;
        let dateEnd;
        if (analysis) {
            dateStart = analysis.dateStart;
            dateEnd = analysis.dateEnd;
        }
        else {
            dateStart = kitchenAnalysis.dateStart;
            dateEnd = kitchenAnalysis.dateEnd;
        }
        let trays = await getTraysValues(props.restaurant_name, dateStart, moment(dateEnd).add(1, 'day'));

        pres = getFirstSlide(pres, t, getAnalysisNumber(), dateStart, name);
        x += 1;
        if (getAnalysisNumber() > 1) {
            pres = getSecondSlide(pres, t, getAnalysisNumber(), analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, x, props.objectives);
            x += 1;
        }
        pres = getThirdSlide(pres, t, getAnalysisNumber(), analysis, kitchenAnalysis, x, props.food_list, props.food_list_kitchen, "", props.synthesisName);
        x += 1;
        if (analysis && kitchenAnalysis) {
            pres = getRepartitionSlide(pres, t, getAnalysisNumber(), analysis, kitchenAnalysis, trays, x);
            x += 1;
        }
        pres = getFourthSlide(pres, t, analysis, kitchenAnalysis, x, props.userState.averageGuests);
        x++;
        pres = getFifthSlide(pres, t, analysis, kitchenAnalysis, x, props.food_list, props.food_list_kitchen, "", props.userState.averageGuests);
        x++;
        pres = getSixthSlide(pres, t, analysis, kitchenAnalysis, x, props.userState.averageGuests);
        x++;
        pres = getSeventhSlide(pres, t, analysis, kitchenAnalysis, x, props.userState.averageGuests);
        x++;
        if (props.hasAssessment) {
            pres = getEightSlide(pres, t, analysis, kitchenAnalysis, x);
            x++;
            pres = getNinethSlide(pres, t, analysis, kitchenAnalysis, x);
            x++;
        }
        pres = getTenthSlide(pres, t, analysis, kitchenAnalysis, x, props.objectives);
        x++;
        pres.writeFile({ fileName: props.restaurant_name + "_synthèse_" + getAnalysisNumber() + ".pptx" });
    }
    const generateSynthesisCommodityCost = async () => {
        let x = 0;
        let pres = new pptxgen();
        pres.defineLayout({ name: "Standard", width: 33.87 / 2.54, height: 19.09 / 2.54 })
        pres.layout = "Standard"
        pres.defineSlideMaster({
            title: 'TITLE_SLIDE',
            background: { color: "F8F8F3" },
            objects: [
            ],
        });

        let analysis = props.hasRestaurantValues ? getAnalyzes() : null;
        let kitchenAnalysis = props.hasKitchenValues ? getKitchenAnalyzes() : null;
        let previousAnalysis = props.hasRestaurantValues ? getPreviousAnalysis() : null;
        let previousKitchenAnalysis = props.hasKitchenValues ? getPreviousKitchenAnalysis() : null;

        let trays = await getTraysValues(props.restaurant_name, analysis.dateStart, moment(analysis.dateEnd).add(1, 'day'));

        pres = getFirstSlide(pres, t, getAnalysisNumber(), analysis.dateStart);
        x += 1;
        if (getAnalysisNumber() > 1) {
            pres = getSecondSlide(pres, t, getAnalysisNumber(), analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, x, props.objectives);
            x += 1;
        }
        pres = getThirdSlide(pres, t, getAnalysisNumber(), analysis, kitchenAnalysis, x, props.food_list, props.food_list_kitchen, "commodity_cost", props.synthesisName);
        x += 1;
        if (analysis && kitchenAnalysis) {
            pres = getRepartitionSlide(pres, t, getAnalysisNumber(), analysis, kitchenAnalysis, trays, x);
            x += 1;
        }

        pres = getFourthSlide(pres, t, analysis, kitchenAnalysis, x, props.userState.averageGuests);
        x++;
        pres = getFifthSlide(pres, t, analysis, kitchenAnalysis, x, props.food_list, props.food_list_kitchen, "commodity_cost", props.userState.averageGuests);
        x++;
        pres = getSixthSlide(pres, t, analysis, kitchenAnalysis, x, props.userState.averageGuests);
        x++;
        pres = getSeventhSlide(pres, t, analysis, kitchenAnalysis, x, props.userState.averageGuests);
        x++;
        if (props.hasAssessment) {
            pres = getEightSlide(pres, t, analysis, kitchenAnalysis, x);
            x++;
            pres = getNinethSlide(pres, t, analysis, kitchenAnalysis, x);
            x++;
        }
        pres = getTenthSlide(pres, t, analysis, kitchenAnalysis, x, props.objectives);
        x++;
        pres.writeFile({ fileName: props.restaurant_name + "_synthèse_" + getAnalysisNumber() + "_commodity_cost.pptx" });
    }
    const generateSynthesises = () => {
        props.selectedDocuments.find(a => a === "synthesis") && generateSynthesis();
        props.selectedDocuments.find(a => a === "sensiAdult") && generateSensi_adult()
        props.selectedDocuments.find(a => a === "sensiTeen") && generateSensi_teens()
        props.selectedDocuments.find(a => a === "sensiChild") && generateSensi_child()
        props.selectedDocuments.find(a => a === "sensiAdultCommodity") && generateSensiAdultCommodityCost()
        props.selectedDocuments.find(a => a === "synthesisCommodity") && generateSynthesisCommodityCost()
        dispatch(setSelectedDocuments(["synthesis", "sensiAdult"]))
        dispatch(setDownloadModalOpen(null))
        dispatch(setHasKitchenValues(true));
        dispatch(setHasRestaurantValues(true))
    }
    let result = [
        {
            id: "sensi_line_chart",
            color: "#90C261",
            data: [
                {
                    name: "03/24",
                    x: 0,
                    y: 21,
                    color: 'transparent'
                },
                {
                    name: "04/24",
                    x: 1,
                    y: 23,
                    color: 'transparent'
                },
                {
                    name: "04/24",
                    x: 2,
                    y: 15,
                    color: 'transparent'
                },
                {
                    name: "04/24",
                    x: 3,
                    y: 15,
                    color: 'transparent'
                },
                {
                    name: "04/24",
                    x: 4,
                    y: 15,
                    color: 'transparent'
                },
                {
                    name: "04/24",
                    x: 5,
                    y: 15,
                    color: 'transparent'
                },
                {
                    name: "04/24",
                    x: 6,
                    y: 15,
                    color: 'transparent'
                },
                {
                    name: "04/24",
                    x: 7,
                    y: 15,
                    color: 'transparent'
                },
                {
                    name: "04/24",
                    x: 8,
                    y: 15,
                    color: 'transparent'
                },
                {
                    name: "04/24",
                    x: 9,
                    y: 15,
                    color: 'transparent'
                }
            ]
        }
    ];
    return (

        <div style={{ width: "12%" }}
            onClick={(e) => { if (e.target.id !== "cancel" && e.target.id !== "download") dispatch(setDownloadModalOpen(props.id)) }}>
            {downloadModal()}
            <Ppt style={{ width: "35%", height: "50%", fill: "#666666" }} />
            <div id={"speedometerToImpres" + getAnalysisNumber()} style={{ display: "block", width: 250, height: 250, position: "absolute", right: "200%" }}>
                <GaugeChart className="speedometer"
                    nrOfLevels={6}
                    arcWidth={0.35}
                    cornerRadius={1.1}
                    arcPadding={0.02}
                    animate={false}
                    percent={getNeedleRotation()}
                    hideText={true}
                    colors={["#B3D693", "#C2FF74", "#FFE074", "#FFB774", "#FF9574", "#FF7474"]} />
            </div>

            <div id={"chartToImpress" + getAnalysisNumber()} style={{ display: "block", width: 500, height: 200, position: "absolute", right: "200%" }}>

                <SensiLineChart className={"sensi_line_chart"} id={"sensi_line_chart"} data={result} color={"#B3D693"} markerValue={21} />
            </div>
        </div>
    )
}
function mapStateToProps(state) {
    return {
        restaurant_name: state.userReducer.selected_restaurant,
        files_list: state.filesReducer.files_list,
        analyzesDatas: state.datasReducer.analyzesDatas,
        kitchenDatas: state.datasReducer.kitchenAnalyzesDatas,
        selected_analysis: state.filesReducer.selected_analysis,
        userState: state.userReducer,
        trays_by_day: state.datasReducer.trays_by_day,
        selected_cam: state.filesReducer.selected_cam,
        showOnBoarding: state.filesReducer.show_on_boarding,
        current_page_on_boarding: state.filesReducer.current_page_on_boarding,
        cams: state.userReducer.cams,
        onBoardingState: state.onBoardingReducer,
        on_boarding_done: state.filesReducer.on_boarding_done,
        user_type: state.userReducer.user_type,
        food_list: state.datasReducer.food_list,
        food_list_kitchen: state.datasReducer.food_list_kitchen,
        selectedDocuments: state.filesReducer.selectedDocuments,
        downloadModalOpen: state.filesReducer.downloadModalOpen,
        hasRestaurantValues: state.filesReducer.hasRestaurantValues,
        hasKitchenValues: state.filesReducer.hasKitchenValues,
        displayName: state.userReducer.displayName,
        synthesisName: state.userReducer.synthesisName,
        objectives: state.objectivesReducer.objectives,
        hasAssessment: state.filesReducer.hasAssessment
    };
}

export default connect(mapStateToProps)(Synthesis);