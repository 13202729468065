import React from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import './Bars.css'
import { useTranslation } from "react-i18next";
import { ReactComponent as List } from "../../assets/list.svg";
import { ReactComponent as Agenda } from "../../assets/agenda.svg";
import { ReactComponent as Operation } from "../../assets/operation.svg";
import { ReactComponent as Statistic } from "../../assets/statistic.svg";
import { ReactComponent as Dashboard } from "../../assets/dashboard.svg";
import { ReactComponent as File } from "../../assets/file.svg";
import { ReactComponent as Ressources } from "../../assets/ressources.svg";
import { ReactComponent as Picture } from "../../assets/Picture.svg";
import { ReactComponent as Restaurant } from "../../assets/restaurant.svg";
import { ReactComponent as Samplebins } from "../../assets/samplebins.svg";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { ReactComponent as Qrcode } from "../../assets/qrcode.svg";
import { ReactComponent as Search } from "../../assets/search.svg";
import { setCurrentPage } from "../../actions_reducers/actions/navigation.action";
import { updateArianneThread } from "../arianneThread/ArianneThread";
import { softResetUser } from "../../actions_reducers/actions/user.action";
import { hardResetDashboard } from "../../actions_reducers/actions/dashboard.action";

const SideBar = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isSelected = (page) => {
        if (page === props.current_page) return true;
        else return false;
    }
    const getColors = (page, type) => {
        if (type !== 'admin') {
            let backgroundColor = "transparent";
            let color = "#fff";
            let boxShadow = "0px 0px 0px rgba(0, 0, 0, 0)"
            if (isSelected(page)) {
                backgroundColor = "#fff";
                color = "#89A290";
                boxShadow = "0px 4px 4px rgba(0, 0, 0, 0.25)";
            }
            return {
                backgroundColor: backgroundColor,
                color: color,
                boxShadow: boxShadow
            }
        }
        else {
            let backgroundColor = "#567A60";
            let color = "#fff";
            let boxShadow = "0px 0px 0px rgba(0, 0, 0, 0)"
            if (isSelected(page)) {
                backgroundColor = "#fff";
                color = "#89A290";
                boxShadow = "0px 4px 4px rgba(0, 0, 0, 0.25)";
            }
            return {
                backgroundColor: backgroundColor,
                color: color,
                boxShadow: boxShadow
            }
        }
    }
    const changeCurrentPage = (title) => {
        updateArianneThread(title, props.arianne, dispatch, "");
        dispatch(setCurrentPage(title));
        dispatch(softResetUser())
        dispatch(hardResetDashboard())
    }
    const showClientBar = () => {
        if (props.userState.user_type === 'super-client' || props.userState.user_type === 'client' || props.clientState.clientName)
            return true;
        else return false;
    }
    const showRestaurantsBar = () => {
        if (props.userState.selected_restaurant !== null)
            return true;
        else return false;
    }

    return (
        <div className="side-bar-main-div">
            {
                props.userState.user_type === 'admin' &&
                <>
                    <div className="side-bar-title"
                        style={{
                            backgroundColor: getColors("clientList", "admin").backgroundColor,
                            color: getColors("clientList", "admin").color,
                            boxShadow: getColors("clientList", "admin").boxShadow
                        }}
                        onClick={() => changeCurrentPage("clientList")}>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <List className="side-bar-icon"
                                style={{ fill: getColors("clientList", "admin").color, width: "55%", height: "55%", position: "absolute", top: "25%" }} />
                        </div>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "76%" }}>
                            {t('navigation.clientList')}
                        </div>
                    </div>
                    <div className="side-bar-title"
                        style={{
                            backgroundColor: getColors("planification", "admin").backgroundColor,
                            color: getColors("planification", "admin").color,
                            boxShadow: getColors("planification", "admin").boxShadow
                        }}
                        onClick={() => changeCurrentPage("planification")}>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Agenda className="side-bar-icon"
                                style={{ fill: getColors("planification", "admin").color, height: "80%", width: "80%", position: "absolute", top: "15%" }} />
                        </div>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "76%" }}>
                            {t('navigation.planification')}
                        </div>
                    </div>
                    <div className="side-bar-title"
                        style={{
                            backgroundColor: getColors("operations", "admin").backgroundColor,
                            color: getColors("operations", "admin").color,
                            boxShadow: getColors("operations", "admin").boxShadow
                        }}
                        onClick={() => changeCurrentPage("operations")}>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Operation className="side-bar-icon"
                                style={{ fill: getColors("operations", "admin").color, height: "75%", width: "75%", position: "absolute", top: "17%" }} />
                        </div>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "76%" }}>
                            {t('navigation.operations')}
                        </div>
                    </div>
                    <div className="side-bar-title"
                        style={{
                            backgroundColor: getColors("stats", "admin").backgroundColor,
                            color: getColors("stats", "admin").color,
                            boxShadow: getColors("stats", "admin").boxShadow
                        }}
                        onClick={() => changeCurrentPage("stats")}>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Statistic className="side-bar-icon"
                                style={{ fill: getColors("stats", "admin").color, width: "42%", height: "42%", position: "absolute", top: "30%" }} />
                        </div>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "76%" }}>
                            {t('navigation.stats')}
                        </div>
                    </div>
                    <div className="side-bar-title"
                        style={{
                            backgroundColor: getColors("mails", "admin").backgroundColor,
                            color: getColors("mails", "admin").color,
                            boxShadow: getColors("mails", "admin").boxShadow
                        }}
                        onClick={() => changeCurrentPage("mails")}>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Statistic className="side-bar-icon"
                                style={{ fill: getColors("mails", "admin").color, width: "42%", height: "42%", position: "absolute", top: "30%" }} />
                        </div>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "76%" }}>
                            {t('navigation.mails')}
                        </div>
                    </div>
                    <div style={{ height: "0.5%", backgroundColor: "#fff", width: "100%", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }} />
                </>
            }
            {showClientBar() &&
                <div className="side-bar-title"
                    style={{
                        backgroundColor: getColors("clientDashboard").backgroundColor,
                        color: getColors("clientDashboard").color,
                        boxShadow: getColors("clientDashboard").boxShadow
                    }}
                    onClick={() => changeCurrentPage("clientDashboard")}>
                    <div style={{ width: "2%" }} />
                    <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Dashboard className="side-bar-icon"
                            style={{ fill: getColors("clientDashboard").color, width: "37%", height: "37%", position: "absolute", top: "30%" }} />
                    </div>
                    <div style={{ width: "2%" }} />
                    <div style={{ width: "76%" }}>
                        {t('navigation.restaurants')}
                    </div>
                </div>}


            {(showClientBar() && props.userState.user_type !== 'admin') &&
                <div className="side-bar-title"
                    style={{
                        backgroundColor: getColors("planification").backgroundColor,
                        color: getColors("planification").color,
                        boxShadow: getColors("planification").boxShadow
                    }}
                    onClick={() => changeCurrentPage("planification")}>
                    <div style={{ width: "2%" }} />
                    <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Agenda className="side-bar-icon"
                            style={{ fill: getColors("planification", "admin").color, height: "80%", width: "80%", position: "absolute", top: "15%" }} />
                    </div>
                    <div style={{ width: "2%" }} />
                    <div style={{ width: "76%" }}>
                        {t('navigation.planification')}
                    </div>
                </div>
            }
            {showRestaurantsBar() &&
                <>
                    <div className="side-bar-title"
                        style={{
                            backgroundColor: getColors("dashboard").backgroundColor,
                            color: getColors("dashboard").color,
                            boxShadow: getColors("dashboard").boxShadow
                        }}
                        onClick={() => changeCurrentPage("dashboard")}>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Dashboard className="side-bar-icon"
                                style={{ fill: getColors("dashboard").color, width: "37%", height: "37%", position: "absolute", top: "30%" }} />
                        </div>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "76%" }}>
                            {t('navigation.dashboard')}
                        </div>
                    </div>
                    {props.userState.has_survey &&
                        <div className="side-bar-title"
                            style={{
                                backgroundColor: getColors("answers").backgroundColor,
                                color: getColors("answers").color,
                                boxShadow: getColors("answers").boxShadow
                            }}
                            onClick={() => changeCurrentPage("answers")}>
                            <div style={{ width: "2%" }} />
                            <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Qrcode className="side-bar-icon"
                                    style={{ fill: getColors("answers").color, width: "55%", height: "55%", position: "absolute", top: "25%" }} />
                            </div>
                            <div style={{ width: "2%" }} />
                            <div style={{ width: "76%" }}>
                                {t('navigation.answers')}
                            </div>
                        </div>}
                    {/*<div className="side-bar-title"
                        style={{
                            backgroundColor: getColors("planning").backgroundColor,
                            color: getColors("planning").color,
                            boxShadow: getColors("planning").boxShadow
                        }}
                        onClick={() => changeCurrentPage("planning")}>
                        <Planning className="side-bar-icon"
                            style={{ fill: getColors("planning").color, marginTop: "2%" }} />
                        {t('navigation.planning')}
                    </div>*/}
                    <div className="side-bar-title"
                        style={{
                            backgroundColor: getColors("files").backgroundColor,
                            color: getColors("files").color,
                            boxShadow: getColors("files").boxShadow
                        }}
                        onClick={() => changeCurrentPage("files")}>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <File className="side-bar-icon"
                                style={{ fill: getColors("files").color, width: "48%", height: "48%", position: "absolute", top: "28%" }} />
                        </div>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "76%" }}>
                            {t('navigation.files')}
                        </div>
                    </div>
                    <div className="side-bar-title"
                        style={{
                            backgroundColor: getColors("ressources").backgroundColor,
                            color: getColors("ressources").color,
                            boxShadow: getColors("ressources").boxShadow
                        }}
                        onClick={() => changeCurrentPage("ressources")}>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Ressources className="side-bar-icon"
                                style={{ fill: getColors("ressources").color, width: "40%", height: "40%", position: "absolute", top: "31%" }} />
                        </div>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "76%" }}>
                            {t('navigation.ressources')}
                        </div>
                    </div>
                    {
                        props.userState.user_type === 'admin' &&
                        <div className="side-bar-title"
                            style={{
                                backgroundColor: getColors("datasRestaurant").backgroundColor,
                                color: getColors("datasRestaurant").color,
                                boxShadow: getColors("datasRestaurant").boxShadow
                            }}
                            onClick={() => changeCurrentPage("datasRestaurant")}>
                            <div style={{ width: "2%" }} />
                            <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Restaurant className="side-bar-icon"
                                    style={{ fill: getColors("datasRestaurant").color, width: "45%", height: "45%", position: "absolute", top: "27%" }} />
                            </div>
                            <div style={{ width: "2%" }} />
                            <div style={{ width: "76%" }}>
                                {t('navigation.datasRestaurant')}
                            </div>
                        </div>
                    }
                    <div className="side-bar-title"
                        style={{
                            backgroundColor: getColors("search").backgroundColor,
                            color: getColors("search").color,
                            boxShadow: getColors("search").boxShadow
                        }}
                        onClick={() => changeCurrentPage("search")}>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Search className="side-bar-icon"
                                style={{ fill: getColors("search").color, width: "40%", height: "40%", position: "absolute", top: "28%" }} />
                        </div>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "76%" }}>
                            {t('navigation.search')}
                        </div>
                    </div>
                    <div className="side-bar-title"
                        style={{
                            backgroundColor: getColors("pictures").backgroundColor,
                            color: getColors("pictures").color,
                            boxShadow: getColors("pictures").boxShadow
                        }}
                        onClick={() => changeCurrentPage("pictures")}>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Picture className="side-bar-icon"
                                style={{ fill: getColors("pictures").color, width: "38%", height: "38%", position: "absolute", top: "28%" }} />
                        </div>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "76%" }}>
                            {t('navigation.pictures')}
                        </div>
                    </div>
                    <div className="side-bar-title"
                        style={{
                            backgroundColor: getColors("samplesBinGestion").backgroundColor,
                            color: getColors("samplesBinGestion").color,
                            boxShadow: getColors("samplesBinGestion").boxShadow
                        }}
                        onClick={() => changeCurrentPage("samplesBinGestion")}>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Samplebins className="side-bar-icon"
                                style={{ fill: getColors("samplesBinGestion").color, width: "40%", height: "40%", position: "absolute", top: "29%" }} />

                        </div>
                        <div style={{ width: "2%" }} />
                        <div style={{ width: "76%" }}>
                            {t('navigation.samplesBinGestion')}

                        </div>
                    </div>

                    {
                        props.userState.user_type === 'chef' &&
                        <div className="side-bar-title"
                            style={{
                                backgroundColor: getColors("planification").backgroundColor,
                                color: getColors("planification").color,
                                boxShadow: getColors("planification").boxShadow
                            }}
                            onClick={() => changeCurrentPage("planification")}>
                            <div style={{ width: "2%" }} />
                            <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Agenda className="side-bar-icon"
                                    style={{ fill: getColors("planification", "admin").color, height: "80%", width: "80%", position: "absolute", top: "15%" }} />
                            </div>
                            <div style={{ width: "2%" }} />
                            <div style={{ width: "76%" }}>
                                {t('navigation.planification')}
                            </div>
                        </div>
                    }
                    {
                        props.userState.user_type === 'admin' &&
                        <div className="side-bar-title"
                            style={{
                                backgroundColor: getColors("alerts").backgroundColor,
                                color: getColors("alerts").color,
                                boxShadow: getColors("alerts").boxShadow
                            }}
                            onClick={() => changeCurrentPage("alerts")}>
                            <div style={{ width: "2%" }} />
                            <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Warning className="side-bar-icon"
                                    style={{ fill: getColors("alerts").color, width: "48%", height: "48%", position: "absolute", top: "29%" }} />
                            </div>
                            <div style={{ width: "2%" }} />
                            <div style={{ width: "76%" }}>
                                {t('navigation.alerts')}
                            </div>
                        </div>
                    }

                </>
            }
        </div >
    )
}

function mapStateToProps(state) {
    return {
        userState: state.userReducer,
        clientState: state.clientReducer
    };
}

export default connect(mapStateToProps)(SideBar);
