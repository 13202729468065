import React from "react";
import { useTranslation } from 'react-i18next';
import CustomDropdown from "../../../customComponents/CustomDropdown";
import { useDispatch } from "react-redux";
import { setExtrapolationValue } from "../../../../actions_reducers/actions/dashboard.action";
import { extrapolateAnalyzesValues } from "../../../customFunctions/extrapolation.function";
import { setExtrapolatedAnalyzes, setKitchenExtrapolatedAnalyzes } from "../../../../actions_reducers/actions/datas.action";

const ExtrapolationButtonAnalysis = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const getDisplayedExtrapValue = () => {
        switch (t(`extrapButton.${props.state.extrapolationValue}`)) {
            case (t('extrapButton.day')):
                return t('extrapButton.dayExtrapSmall');
            case (t('extrapButton.week')):
                return t('extrapButton.weekExtrapSmall');
            case (t('extrapButton.month')):
                return t('extrapButton.monthExtrapSmall');
            case (t('extrapButton.year')):
                return t('extrapButton.yearExtrapSmall');
            case (t('extrapButton.analysis')):
                return t('extrapButton.analysisExtrapSmall');
            default: return t('extrapButton.analysisExtrapSmall')
        }
    }
    const getExtrapValue = (value) => {
        switch (value) {
            case (t('extrapButton.day')):
                return 'day';
            case (t('extrapButton.week')):
                return 'week';
            case (t('extrapButton.month')):
                return 'month';
            case (t('extrapButton.year')):
                return 'year';
            case t('extrapButton.analysis'):
                return 'analysis'
            default: return 'analysis';
        }
    }
    const values = [t('extrapButton.analysis'), t('extrapButton.day'), t('extrapButton.week'), t('extrapButton.month'), t('extrapButton.year')];
    const updateValue = (e) => {
        let analyzes = JSON.parse(JSON.stringify(props.analyzesDatas))
        analyzes = extrapolateAnalyzesValues(analyzes, getExtrapValue(e.target.id),'restaurant', props.userState.averageGuests);
        if (props.userState.has_kitchen) {
            let kitchenAnalyzes = JSON.parse(JSON.stringify(props.kitchenAnalyzesDatas))
            kitchenAnalyzes = extrapolateAnalyzesValues(kitchenAnalyzes, getExtrapValue(e.target.id),'restaurant', props.userState.averageGuests);
            dispatch(setKitchenExtrapolatedAnalyzes(kitchenAnalyzes));
        }
        dispatch(setExtrapolatedAnalyzes(analyzes));
        dispatch(setExtrapolationValue(getExtrapValue(e.target.id)))
    }
    return (
        <CustomDropdown
            width={'100%'}
            height={'100%'}
            backgroundColor={'#949494'}
            displayedValue={getDisplayedExtrapValue()}
            selectedValue={t(`extrapButton.${props.state.extrapolationValue}ExtrapSmall`)}
            values={values}
            onChange={updateValue}
            arrowColor={"#D9D9D9"}
            fontSize={"0.9vw"}
        />
    )
}

export default ExtrapolationButtonAnalysis;