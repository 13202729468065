import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';

const AnalysisGap = (props) => {
    const { t } = useTranslation();

    const getValues = (analyzes, valuesTmp, dataType, type) => {
        let values = JSON.parse(JSON.stringify(valuesTmp))
        if (analyzes.length > 0) {
            for (const analysis of analyzes) {
                values[dataType + "Cost"] = parseFloat(values[dataType + "Cost"]) + parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost);
                values[dataType + "Co2"] = parseFloat(values[dataType + "Co2"]) + parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2);
                values[dataType + "MassTot"] = parseFloat(values[dataType + "MassTot"]) + parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass);

                if (type === "restaurant") {
                    values[dataType + "Mass"] = parseFloat(values[dataType + "Mass"]) + parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass);
                    values[dataType + "Trays"] = parseFloat(values[dataType + "Trays"]) + parseFloat(analysis.trays);
                }
                else {
                    values[dataType + "KitchenMass"] = parseFloat(values[dataType + "KitchenMass"]) + parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass);
                    values[dataType + "KitchenTrays"] = parseFloat(values[dataType + "KitchenTrays"]) + parseFloat(analysis.trays);

                }
                values[dataType + "MassConv"] = parseFloat(values[dataType + "MassConv"]) + (((parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / parseInt(analysis.trays)))

            }
        }
        return values;
    }
    const getSignEvo = (value) => {
        if (value > 0) return '+'
        else if (value < 0) return '-'
        else return ''
    }
    const getEvo = (values) => {
        let value = 0;
        switch (props.state.selectedAnalysisType) {
            case 'mass':
                value = (((values.currentMassTot - values.previousMassTot) / values.previousMassTot) * 100);
                break;
            case 'cost':
                value = (((values.currentCost - values.previousCost) / values.previousCost) * 100);
                break;
            case 'co2':
                value = (((values.currentCo2 - values.previousCo2) / values.previousCo2) * 100);
                break;
            case 'massConv':
                value = (((values.currentMassConv - values.previousMassConv) / values.previousMassConv) * 100);
                break;
            default:
                value = (((values.currentMassConv - values.previousMassConv) / values.previousMassConv) * 100);
                break;

        }
        value = getSignEvo(value) + Math.abs(value).toFixed(0) + " %";
        return value
    }
    const getValue = () => {
        let evo = "0 %";
        let values = {
            currentMassTot: 0,
            previousMassTot: 0,
            currentMass: 0,
            currentCost: 0,
            currentCo2: 0,
            currentMassConv: 0,
            previousMass: 0,
            previousCost: 0,
            previousCo2: 0,
            previousMassConv: 0,
            previousKitchenMass: 0,
            previousKitchenTrays: 0,
            previousTrays: 0,
            currentTrays: 0,
            currentKitchenMass: 0,
            currentKitchenTrays: 0

        }
        let analysisNumber = props.state.selectedAnalysisNumber;
        if (parseInt(analysisNumber) !== 1) {
            let currentKitchenAnalysis = JSON.parse(JSON.stringify(props.kitchenAnalyzesDatas)).filter(a => a && a.analysisNumber === parseInt(analysisNumber));
            let currentAnalysis = JSON.parse(JSON.stringify(props.analyzesDatas)).filter(a => a.analysisNumber === parseInt(analysisNumber));
            let previousKitchenAnalysis = JSON.parse(JSON.stringify(props.kitchenAnalyzesDatas)).filter(a =>a&& a.analysisNumber === parseInt(analysisNumber) - 1);
            let previousAnalysis = JSON.parse(JSON.stringify(props.analyzesDatas)).filter(a => a.analysisNumber === parseInt(analysisNumber) - 1);

            if (currentAnalysis)
                values = getValues(currentAnalysis, values, "current", "restaurant");
            if (currentKitchenAnalysis)
                values = getValues(currentKitchenAnalysis, values, "current", "kitchen");
            if (previousAnalysis)
                values = getValues(previousAnalysis, values, "previous", "restaurant");
            if (previousKitchenAnalysis)
                values = getValues(previousKitchenAnalysis, values, "previous", "kitchen");
            let currentKitchenMass = values.currentKitchenTrays > 0 ? values.currentKitchenMass / values.currentKitchenTrays : 0;
            let currentTraysMass = values.currentTrays > 0 ? values.currentMass / values.currentTrays : 0;
            let previousKitchenMass = values.previousKitchenTrays > 0 ? values.previousKitchenMass / values.previousKitchenTrays : 0;
            let previousTraysMass = values.previousTrays > 0 ? values.previousMass / values.previousTrays : 0;

            values.currentMassConv = currentTraysMass + currentKitchenMass;
            values.previousMassConv = previousTraysMass + previousKitchenMass;
            evo = getEvo(values);
        }
        return evo;
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: "100%" }}>
            <CustomBlock width={"100%"} height={"100%"} hasHover={true} backgroundColor={'#fff'} selected={true}>
                <div style={{ cursor: "default", textAlign: "center", color: "#666666", height: "40%", paddingTop: "6%", lineHeight: "125%" }}>
                    {t('components.evoSincePrevious')}
                </div>
                <div style={{ height: "60%", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bolder", color: "#666666", fontSize: "1.3vw" }}>
                    {getValue()}
                </div>
            </CustomBlock>

        </div>
    )
}

export default AnalysisGap