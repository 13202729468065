import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import { getTraysValueForCamAndHour } from "../../../customFunctions/trays.function";
import { useState } from "react";
import { ReactComponent as Information } from "../../../../assets/informationButton.svg";

const DayTrays = (props) => {
    const { t } = useTranslation();
    const [showInfo, setShowInfo] = useState(false)

    const getInfo = (type) => {
        return (
            <Information fill={"#FFFFFF"} style={{ width: "2vw", height: "3vh", top: "4%", right: "1%", position: "absolute" }}
                onMouseLeave={() => setShowInfo(false)}
                onMouseEnter={() => setShowInfo(true)} />)
    }

    const getAverageTrays = () => {
        let trays = 0;
        if (props.day)
            trays = props.day.trays;
        else if (props.kitchenDay)
            trays = props.kitchenDay.trays
        return trays;
    }
    const getEmptyTrays = () => {
        let emptyTrays = 0;
        if (props.dayTrays && props.day) {
            let empties = getTraysValueForCamAndHour(props.dayTrays.empty, props.userState, "empty");
            emptyTrays = ((empties / (props.day.trays ? props.day.trays : 1)) * 100).toFixed(0) + '%';
        }
        return emptyTrays;
    }
    const getSortedTrays = () => {
        let sortedTrays = 0;
        if (props.dayTrays != null && props.day) {
            let nonOrgas = getTraysValueForCamAndHour(props.dayTrays.nonOrga, props.userState, "nonOrga");
            sortedTrays = (((1 - nonOrgas / (props.day.trays ? props.day.trays : 1))) * 100).toFixed(0) + '%';
        }
        return sortedTrays;
    }
    return (
        <div style={{ width: '100%', height: '100%', minHeight: "100%", display: "flex", flexDirection: "row" }}>
            <div style={{ width: '26%', height: '100%' }}>
                <CustomBlock width={'100%'} height={'100%'} backgroundColor={"#CACACA"} color={"#fff"} paddingLeft={"5%"} paddingBottom={"3%"}>
                    <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                        <div className="dashboard-block-tray-value">
                            {getAverageTrays()}
                        </div>
                        <div className="dashboard-graph-tray-text">
                            {t('components.numberTrays')}
                        </div>
                    </div>
                </CustomBlock>
            </div>
            <div style={{ width: "11.5%" }} />
            <div style={{ width: '26%', height: '100%' }}>
                <CustomBlock width={'100%'} height={'100%'} backgroundColor={"#CACACA"} color={"#fff"} paddingLeft={"5%"} paddingBottom={"3%"}>
                    <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                        <div className="dashboard-block-tray-value">
                            {getEmptyTrays()}
                        </div>
                        <div className="dashboard-graph-tray-text">
                            {t('components.emptyTrays')}
                        </div>
                    </div>
                </CustomBlock>
            </div>
            <div style={{ width: "11.5%" }} />
            <div style={{ width: '26%', height: '100%' }}>
                <CustomBlock width={'100%'} height={'100%'} backgroundColor={"#CACACA"} color={"#fff"} paddingLeft={"5%"} paddingBottom={"3%"}>
                    {getInfo()}
                    {showInfo && (
                        <div className="window-info" style={{ width: "20vw", position: "absolute", bottom: "100%", right: "-19vw", color: "#666666", zIndex: "20031997", backgroundColor: "#FFFFFF", padding: "10px", borderRadius: "10px", fontSize: "2vh", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>{t("components.sortedTraysInfo")}</div>
                    )}
                    <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                        <div className="dashboard-block-tray-value">
                            {getSortedTrays()}
                        </div>
                        <div className="dashboard-graph-tray-text">
                            {t('components.sortedTrays')}
                        </div>
                    </div>
                </CustomBlock>
            </div>
        </div>
    )
}
export default DayTrays;
