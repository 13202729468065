import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import BarChart from "../../../charts/BarChart";
import 'moment/locale/fr';
import { setSelectedAnalysisType, setSelectedDay } from "../../../../actions_reducers/actions/dashboard.action";
import { updateArianneThread } from "../../../arianneThread/ArianneThread";
import CustomButton from "../../../customComponents/CustomButton";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const AnalysisGraph = (props) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const convertMass = (mass) => {
        if (props.userState.massUnit === "lbs") {
            if (Math.abs(mass) / 453.6 > 10000)
                return parseFloat(mass / 6350.29);
            else
                return parseFloat(mass / 453.6);
        }
        else return mass / 1000;
    }
    const getValueData = (singleData, day, date) => {
        if (day) {
            switch (props.state.selectedAnalysisType) {
                case 'massByConv':
                    var massByConv = (parseFloat(day.mass) - parseFloat(day.inevitableMass)) / (parseFloat(day.trays) ? parseFloat(day.trays) : 1);
                    singleData.y = parseFloat(t('mass', { "value": { "mass": massByConv, "unit": "g" } }));
                    singleData.tooltip = t('dateGraph', { "value": date }) + " : " + t('mass', { "value": { "mass": massByConv, "unit": "g" } });
                    break;
                case 'mass':
                    var mass = (parseFloat(day.mass) - parseFloat(day.inevitableMass))
                    singleData.y = convertMass(mass);
                    singleData.tooltip = t('dateGraph', { "value": date }) + " : " + t('mass', { "value": { "mass": mass, "unit": "kg" } });
                    break;
                case 'cost':
                    var cost = parseFloat(day.cost) - parseFloat(day.inevitableCost)
                    singleData.y = cost;
                    singleData.tooltip = t('dateGraph', { "value": date }) + " : " + t('currency', { "value": { cost: cost } });
                    break;
                case 'co2':
                    var co2 = (parseFloat(day.co2) - parseFloat(day.inevitableCo2))
                    singleData.y = convertMass(co2);
                    singleData.tooltip = t('dateGraph', { "value": date }) + " : " + t('mass', { "value": { "mass": co2, "unit": "kg" } });
                    break
                case 'trays':
                    var trays = parseInt(day.trays)
                    singleData.y = parseInt(day.trays);
                    singleData.tooltip = t('dateGraph', { "value": date }) + " : " + trays + " " + t('components.trays');
                    break;
                default:
                    var defaultValue = (parseFloat(day.mass) - parseFloat(day.inevitableMass)) / parseFloat(day.trays);
                    singleData.y = parseFloat(t('mass', { "value": { "mass": defaultValue, "unit": "g" } }));
                    singleData.tooltip = t('dateGraph', { "value": date }) + " : " + t('mass', { "value": { "mass": defaultValue, "unit": "g" } });
                    break;
            }
        }
        return singleData;
    }
    const getData = () => {
        let result = [];
        let keys = ["restaurant", "kitchen"];
        let analysis = props.analysis;
        let kitchenAnalysis = props.kitchenAnalysis;
        if (props.state.selectedAnalysisType === 'massByConv') {
            analysis = props.baseAnalysis;
            kitchenAnalysis = props.baseAnalysisKitch
        }
        if (analysis) {
            for (const day of analysis.days) {
                let name = moment(day.date).format('DD/MM');
                let date = moment(day.date).format('YYYY-MM-DD');
                let singleData = {
                    name: name,
                    x: date,
                    date: date,
                    y: '',
                    tooltip: ''
                };
                if (props.state.selectedPieCategory == null) {
                    singleData = getValueData(singleData, day, date)
                }
                else {
                    let catValues = day.categories.find(a => a.category === props.state.selectedPieCategory);
                    singleData = getValueData(singleData, catValues, date)
                }
                singleData.restaurant = singleData.y
                singleData.restaurantColor = "#90c261"
                singleData.tooltipRestaurant = singleData.tooltip;
                result.push(singleData);
            }
        }
        if (kitchenAnalysis) {
            for (const day of kitchenAnalysis.days) {
                let name = moment(day.date).format('DD/MM');
                let date = moment(day.date).format('YYYY-MM-DD');
                let index = result.findIndex(a => a.name === name);
                let singleData = {
                    name: name,
                    x: date,
                    date: date,
                    y: '',
                    tooltip: ''
                };
                if (props.state.selectedPieCategory == null) {
                    singleData = getValueData(singleData, day, date)
                }
                else {
                    let catValues = day.categories.find(a => a.category === props.state.selectedPieCategory);
                    if (catValues)
                        singleData = getValueData(singleData, catValues, date)
                }
                if (index === -1) {
                    singleData.kitchen = singleData.y
                    singleData.kitchenColor = "#54b0e3"
                    singleData.tooltipKitchen = singleData.tooltip;
                    result.push(singleData);
                }
                else {
                    result[index].kitchenColor = "#54b0e3"
                    result[index].tooltipKitchen = singleData.tooltip;
                    result[index].kitchen = singleData.y
                }
            }
        }
        result.sort((a, b) => a.date.localeCompare(b.date));
        return { result: result, keys: keys };
    }
    const getKeys = () => {
        if (props.state.selectedAnalysisType === "trays") return ["y"]
        else if (props.analysis && props.kitchenAnalysis && props.state.selectedAnalysisType !== "trays") return ["restaurant", "kitchen"];
        else if (props.analysis && !props.kitchenAnalysis) return ["restaurant"];
        else if (!props.analysis && props.kitchenAnalysis) return ["kitchen"];
        else return [];
    }

    const onClickDay = (e) => {
        let day = e.data.date;
        dispatch(setSelectedDay(day));
        dispatch(setSelectedAnalysisType("mass"))
        updateArianneThread('day', props.arianne, dispatch, t('date', { value: { "date": e.data.date, "format": "DD/MM/YYYY" } }));
    }

    const changeSelectedType = () => {
        const newType = props.state.selectedAnalysisType === "trays" ? "massByConv" : "trays";
        dispatch(setSelectedAnalysisType(newType));
    };
    const getTraysTotal = () => {
        let total = 0
        let days = [];
        let analysis = props.analysis;
        let kitchenAnalysis = props.kitchenAnalysis;
        if (analysis) {
            for (const day of analysis.days) {
                if (props.state.selectedPieCategory == null) {
                    total = parseInt(total) + parseInt(day.trays)
                    days.push(moment(day.date).format("YYYY-MM-DD"));
                }
                else {
                    let catValues = day.categories.find(a => a.category === props.state.selectedPieCategory);
                    total = parseInt(total) + parseInt(catValues.trays)
                    days.push(moment(day.date).format("YYYY-MM-DD"));
                }
            }
        }
        if (kitchenAnalysis) {
            for (const day of kitchenAnalysis.days) {
                if (props.state.selectedPieCategory == null) {
                    if (!days.find(a => a === moment(day.date).format("YYYY-MM-DD"))) {
                        total = parseInt(total) + parseInt(day.trays)
                        days.push(moment(day.date).format("YYYY-MM-DD"));
                    }
                }
                else {
                    let catValues = day.categories.find(a => a.category === props.state.selectedPieCategory);
                    if (catValues && !days.find(a => a === day.date)) {
                        total = parseInt(total) + parseInt(catValues.trays)
                        days.push(moment(day.date).format("YYYY-MM-DD"));
                    }
                }
            }
        }
        return total;
    }
    const getTitle = () => {
        if (props.state.selectedPieCategory == null) {
            if (props.state.selectedAnalysisType === "trays")
                return t(`components.graph${props.state.selectedAnalysisType}`) + t('components.totalTrays', { value: getTraysTotal() })
            else
                return t(`components.graph${props.state.selectedAnalysisType}`)
        }
        else {

            if (props.state.selectedAnalysisType === "trays")
                return t(`components.graph${props.state.selectedAnalysisType}`) + t(`topGaspiTitles.${props.state.selectedPieCategory}`) + t('components.totalTrays', { value: getTraysTotal() })
            else
                return t(`components.graph${props.state.selectedAnalysisType}`) + t(`topGaspiTitles.${props.state.selectedPieCategory}`)
        }
    }
    const getLegend = () => {
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <div style={{ height: "50%", width: "100%", display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "80%", height: "100%", display: 'flex', justifyContent: "flex-end", alignItems: "center", fontSize: "0.75vw" }}>
                        {t('components.restaurantSide')}
                    </div>
                    <div style={{ width: "20%", height: "100%", display: 'flex', justifyContent: "flex-end", alignItems: "center" }}>
                        <div style={{ backgroundColor: "#90c261", width: "70%", height: "50%", borderRadius: 10 }} />
                    </div>
                </div>
                <div style={{ height: "50%", width: "100%", display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "80%", height: "100%", display: 'flex', justifyContent: "flex-end", alignItems: "center", fontSize: "0.75vw" }}>
                        {t('components.kitchenSide')}
                    </div>
                    <div style={{ width: "20%", height: "100%", display: 'flex', justifyContent: "flex-end", alignItems: "center" }}>
                        <div style={{ backgroundColor: "#54b0e3", width: "70%", height: "50%", borderRadius: 10 }} />
                    </div>
                </div>
            </div>
        )
    }
    const getMassSelectedAnalysis = () => {
        let mass = 0;
        let co2 = 0;
        if (props.analysis) {
            mass += parseFloat(props.analysis.mass) - parseFloat(props.analysis.inevitableMass)
            co2 += parseFloat(props.analysis.co2) - parseFloat(props.analysis.inevitableCo2)
        }
        if (props.kitchenAnalysis) {
            mass += parseFloat(props.kitchenAnalysis.mass) - parseFloat(props.kitchenAnalysis.inevitableMass)
            co2 += parseFloat(props.kitchenAnalysis.co2) - parseFloat(props.kitchenAnalysis.inevitableCo2)

        }
        return { mass: mass, co2: co2 };
    }
    const getGraphLegend = () => {
        let mass = getMassSelectedAnalysis().mass;
        let co2 = getMassSelectedAnalysis().co2;
        if (props.state.selectedAnalysisType === "cost") return t('graph.cost', { unit: props.userState.costUnit });
        if (props.userState.massUnit === "lbs") {
            if (props.state.selectedAnalysisType === "massByConv") return t('graph.massByConv', { unit: "oz" });
            else if (props.state.selectedAnalysisType === "mass") return t('graph.mass', { unit: mass / 453.6 > 10000 ? "st" : "lbs" });
            else if (props.state.selectedAnalysisType === "co2") return t('graph.co2', { unit: co2 / 453.6 > 10000 ? "st" : "lbs" });
        }
        else {
            if (props.state.selectedAnalysisType === "massByConv") return t('graph.massByConv', { unit: "g" });
            else if (props.state.selectedAnalysisType === "mass") return t('graph.mass', { unit: mass > 10000000 ? "T" : "kg" });
            else if (props.state.selectedAnalysisType === "co2") return t('graph.co2', { unit: co2 > 10000000 ? "T" : "kg" });
        }
    }
    return (
        <CustomBlock width={'100%'} height={'100%'}>
            <div style={{ width: '100%', height: '100%', padding: '2%' }}>

                {(props.state.selectedAnalysisType !== "trays" && props.analysis && props.kitchenAnalysis) &&
                    <div style={{ width: "24%", height: "8%", position: "absolute", right: "2.5%", top: "14%" }}>
                        {getLegend()}
                    </div>}
                <div className="dashboard-block-title" style={{ height: "6%", width: "80%", flexDirection: "row" }}>
                    <div style={{ width: "100%" }}>
                        {getTitle()}
                    </div>
                </div>
                <div style={{ position: "absolute", right: "2%", top: "2%", width: "18%", height: "13%", display: "flex", alignItems: "center" }}>
                    <CustomButton
                        width={"100%"}
                        height={"60%"}
                        boxShadow={"none"}
                        backgroundColor={props.state.selectedAnalysisType === "trays" ? "#90c261" : "#949494"}
                        color={"#FFFFFF"}
                        fontSize={"0.7vw"}
                        onClick={() => changeSelectedType()}>
                        {props.state.selectedAnalysisType === "trays" ? t('buttons.showValues') : t('buttons.showGuests')}
                    </CustomButton>
                </div>
                <div style={{ fontSize: ".8vw", color: "#666666" }}>
                    {getGraphLegend()}
                </div>
                <div className="dashboard-graph-body" style={{ height: "89%" }}>
                    <BarChart id={"analysisGraph"} legendRotation={-35} data={getData().result} keys={getKeys()} top={20} left={50} right={40} bottom={30} onClick={onClickDay} />

                </div>
            </div>
        </CustomBlock>
    );
};

export default AnalysisGraph;