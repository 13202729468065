const CustomTextField = (props) => {
    return (
        <input
            type={props.type}
            className='custom-text-field'
            step={props.step}
            id={props.id}
            value={props.value}
            disabled={props.disabled || false}
            onChange={props.onChange}
            placeholder={props.placeholder || ""}
            max={(props.type === "number" && props.max) && 99}
            style={{
                height: props.height,
                width: props.width,
                marginLeft: props.marginLeft || 0,
                marginRight: props.marginRight || 0,
                marginTop: props.marginTop || 0,
                marginBottom: props.marginBottom || 0,
                backgroundColor: props.backgroundColor,
                color: props.color || "#666666",
                fontSize: props.fontSize || '1.1vw',
                fontWeight: props.fontWeight || 400,
                paddingTop: props.paddingTop || 0,
                paddingBottom: props.paddingBottom || 0,
                paddingRight: props.paddingRight || 0,
                paddingLeft: props.paddingLeft || 0,
                position: props.position || "relative",
                top: props.top,
                bottom: props.bottom,
                right: props.right,
                left: props.left,
                textAlign: props.textAlign,
                border: props.border || "none",
                borderRadius: props.borderRadius || "0px",
                filter: props.filter
            }} />
    )
}

export default CustomTextField;