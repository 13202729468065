import React, { useEffect, useState } from "react";
import CustomBlock from "../../customComponents/CustomBlock";
import CustomScrollDiv from "../../customComponents/CustomScrollBar";
import { useTranslation } from "react-i18next";
import CustomAutocomplete from "../../customComponents/CustomAutocomplete";
import { ReactComponent as Check } from "../../../assets/check.svg";
import { ReactComponent as Warning } from "../../../assets/warning.svg";
import { ReactComponent as Arrow } from "../../../assets/arrow.svg";
import CustomButton from "../../customComponents/CustomButton";
import { useDispatch } from "react-redux";
import { setAnalyzesBaseDatas, setInitialAnalyzes } from "../../../actions_reducers/actions/datas.action";
import 'moment/locale/fr';
import { setAlerts, setSelectedAlert } from "../../../actions_reducers/actions/alerts.action";
import { updateDataForTrays } from "../../customFunctions/newTrays.function";
import { getDatas } from "../../customFunctions/data.function";
import { updateAlert } from "../../../apiRoutes/alerts.routes";
import { updateDailyFoodWaste } from "../../../apiRoutes/dailyFoodWaste.routes";
import { getBaseUrls } from "../../customFunctions/user.function";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const AlertsDetails = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [dropdownIsOpen, setDropdownIsOpen] = useState(null);
    const [hoveredImg, setHoveredImg] = useState(null);
    const [hoveredId, setHoveredId] = useState(null);
    const [currentSamples, setCurrentSamples] = useState([]);
    const [currentAlert, setCurrentAlert] = useState(null);
    const [indexImageRef, setIndexImageRef] = useState(null);
    const [update, setUpdate] = useState(0);
    const [modified, setModified] = useState(null);
    useEffect(() => {
        setUpdate(old => old + 1)
    }, [props.selectedDate])

    useEffect(() => {
        if (props.selectedAlert != null) {
            let index = currentSamples.findIndex(a => a.aliment_id === props.selectedAlert);
            setCurrentAlert(currentSamples[index])
            setIndexImageRef(0);
        }
    }, [props.selectedAlert])

    useEffect(() => {
        let samples = [];
        for (const alert of props.alerts) {
            let dateSample = moment(alert.date).format('YYYY-MM-DD');
            if (dateSample === props.selectedDate) {
                samples = alert.alerts
            }
        }
        setCurrentSamples(samples)
    }, [props.selectedDate, props.alerts.length]);

    const changeDropdownOpen = (e) => {
        if (dropdownIsOpen === null || parseInt(e.target.id) !== parseInt(dropdownIsOpen))
            setDropdownIsOpen(e.target.id);
        else
            setDropdownIsOpen(null);
    };
    const changeSelectedAlim = (alertTmp, e) => {
        let alert = JSON.parse(JSON.stringify(alertTmp));
        let modified = JSON.parse(JSON.stringify(alertTmp))
        setModified(modified);
        if (e.target.id !== t('foods.Non organique')) {
            let food = props.food_list.find(a => t(`foods.${a.nameFood}`) === e.target.id)
            if (food) {
                alert.newAlimentId = food.idFood;
            }
        }
        else {
            alert.newAlimentId = 231;
        }
        let allAlerts = JSON.parse(JSON.stringify(props.alerts));
        let alertsDay = allAlerts.find(a => a.date === alert.date);
        let selectedAlert = alertsDay.alerts.findIndex(a => a.aliment_id === alert.aliment_id);
        alertsDay.alerts[selectedAlert] = alert;
        dispatch(setAlerts(allAlerts));
    }
    const getAlimList = () => {
        let result = [];
        for (const alim of props.food_list) {
            result.push(t(`foods.${alim.nameFood}`))
        }
        result.push(t('foods.Non organique'));
        result.sort((a, b) => a.localeCompare(b))
        return result;
    }
    const getFoodName = (alert) => {
        let alim = props.food_list.find(a => parseInt(a.idFood) === parseInt(alert.newAlimentId));
        if (alim)
            return t(`foods.${alim.nameFood}`)
    }
    const getDisplayedValue = (alert, type) => {
        if (type === "new" && alert.newAlimentId != null) {
            if (alert.newAlimentId !== null && alert.newAlimentId !== 231 && alert.newAlimentId > 200) {
                return t(`foods.${props.food_list.find(a => parseInt(a.idFood) === parseInt(alert.newAlimentId)).nameFood}`);
            }
            else if (alert.newAlimentId === 231) return t('foods.Non organique');
            else return t('table.alim');
        }
        else {
            if (alert.aliment_id !== null && alert.aliment_id !== 231 && alert.aliment_id > 200) {
                return t(`foods.${props.food_list.find(a => parseInt(a.idFood) === parseInt(alert.aliment_id)).nameFood}`);
            }
            else if (alert.aliment_id === 231) return t('foods.Non organique');
            else return t('table.alim');
        }
    }
    const checkKey = (e) => {
        if (props.selectedAlert) {
            if (e.keyCode === 39) {
                nextUrl()
            }
            else if (e.keyCode === 37) {
                previousUrl()
            }
        }
    }
    document.onkeydown = checkKey;
    const previousUrl = () => {
        let newImageRef = null;
        let index = indexImageRef;
        if (index === 0) {
            newImageRef = currentAlert.image_ref.length - 1;
        }
        else {
            newImageRef = indexImageRef - 1;
        }
        setIndexImageRef(newImageRef)
    }
    const nextUrl = () => {
        let newImageRef = null;
        let index = indexImageRef
        if (index === currentAlert.image_ref.length - 1) {
            newImageRef = 0;
        }
        else {
            newImageRef = indexImageRef + 1;
        }
        setIndexImageRef(newImageRef)
    }
    const imageModal = () => {
        let id = null;
        let mask_ref = "";
        if (currentAlert != null && indexImageRef != null) {
            id = currentAlert.image_ref[indexImageRef]
            mask_ref = getMaskOfImage(id, currentAlert.masks_ref)
            return (
                <CustomBlock id={id} width={"90%"} height={"110%"} right={"5%"} top={'-13%'} display={props.selectedAlert == null ? "none" : "block"}
                    position={"absolute"} zIndex={20000000000}>
                    <div id={id} style={{ height: "90%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div id={id} style={{ width: "10%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                            onClick={() => previousUrl()}>
                            <Arrow id={id} className="arrow-left" />
                        </div>
                        <div id={id} style={{ width: "80%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img onMouseEnter={(e) => { setHoveredImg(e.target.id); setHoveredId(currentAlert.aliment_id) }}
                                onMouseOut={() => { setHoveredImg(null); setHoveredId(null) }} id={id} src={id} style={{ width: "60%", height: "auto" }} />
                            {
                                (hoveredImg === id && hoveredId === currentAlert.aliment_id)
                                &&
                                <img onMouseEnter={(e) => { setHoveredImg(e.target.id); setHoveredId(currentAlert.aliment_id) }}
                                    onMouseOut={() => { setHoveredImg(null); setHoveredId(null) }} id={id} className="img-masks" style={{ width: "48%", height: "auto" }} src={mask_ref} />
                            }
                        </div>
                        <div id={id} style={{ width: "10%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                            onClick={() => nextUrl()}>
                            <Arrow id={id} className="arrow-right" />
                        </div>
                    </div>
                    <div id={id} style={{ height: "10%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div id={id} style={{ width: "10%" }} />
                        <div id={id} style={{ width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {"image_ref : " + id}
                        </div>
                        <div id={id} style={{ width: "10%" }} />
                    </div>
                </CustomBlock>
            )
        }
    }
    const getMaskOfImage = (image_ref, masks_ref) => {
        let mask_ref = "";
        const hour = image_ref.split(getBaseUrls().url)[1].split('/')[1].split('_')[4].split('.')[0];
        for (const mask of masks_ref) {
            if (mask && typeof mask === 'string' && mask.includes(hour)) {
                mask_ref = mask;
                break;
            }
        }
        return mask_ref;
    }
    const getValues = (values, food) => {
        values.mass = food.mass;
        values.cost = food.cost;
        values.co2 = food.co2;
        values.inevitableMass = food.inevitableMass;
        values.inevitableCost = food.inevitableCost;
        values.inevitableCo2 = food.inevitableCo2;
        values.trays = food.trays;
        return values;
    }
    const removeValues = (data, values, hasTrays) => {
        data.mass = data.mass - values.mass;
        data.cost = data.cost - values.cost;
        data.co2 = data.co2 - values.co2;
        data.inevitableMass = data.inevitableMass - values.inevitableMass;
        data.inevitableCost = data.inevitableCost - values.inevitableCost;
        data.inevitableCo2 = data.inevitableCo2 - values.inevitableCo2;
        if (hasTrays)
            data.trays = data.trays - values.trays;
        return data;
    }

    const getWasteManagementCost = (wasteManagementMethod) => {
        let wasteManagementCost;
        switch (wasteManagementMethod) {
            case "compostage":
                wasteManagementCost = 140;
                break;
            case "incinération":
                wasteManagementCost = 95;
                break;
            case "méthanisation":
                wasteManagementCost = 80;
                break;
            case "enfouissement":
                wasteManagementCost = 60;
                break;
            case "alimentation animale":
                wasteManagementCost = 140;
                break;
            default:
                wasteManagementCost = 140;
        }
        return wasteManagementCost;
    }

    const getFoodValues = (food, volume, surface, trays) => {
        let wasteManagementCost = getWasteManagementCost(props.wasteManagementMethod)
        let mass;
        if (food) {
            if (food.nameFood === "Salade")
                mass = (parseFloat(surface) / 100) * parseFloat(food.surfaceMassFood);
            else mass = (Math.abs(parseFloat(volume)) / 1000) * parseFloat(food.volumicMassFood);

            let co2 = parseFloat(mass) * (parseFloat(food.co2Food) + 204.97 / 1000);
            let cost
            if (food.categoryFood === "Déchets inévitables") cost = (parseFloat(mass) / 1000000) * parseFloat(wasteManagementCost);
            else
                cost = parseFloat(mass) * (parseFloat(food.costfood) / 1000) + parseFloat(mass) * 0.00354 + (parseFloat(mass) / 1000000) * parseFloat(wasteManagementCost)


            var sampleValues = {
                nameFood: food.nameFood,
                category: food.categoryFood,
                mass: mass,
                co2: co2,
                cost: cost,
                inevitableMass: food.categoryFood === "Déchets inévitables" ? mass : 0,
                inevitableCost: food.categoryFood === "Déchets inévitables" ? cost : 0,
                inevitableCo2: food.categoryFood === "Déchets inévitables" ? co2 : 0,
                trays: trays
            };
            return sampleValues;
        }
        else return null;
    }
    const addNewValues = (data, values, hasTrays) => {
        data.mass = data.mass + values.mass;
        data.cost = data.cost + values.cost;
        data.co2 = data.co2 + values.co2;
        data.inevitableMass = data.inevitableMass + values.inevitableMass;
        data.inevitableCost = data.inevitableCost + values.inevitableCost;
        data.inevitableCo2 = data.inevitableCo2 + values.inevitableCo2;
        if (hasTrays)
            data.trays = data.trays + values.trays;
        return data;

    }
    const updateDatasForAlert = (alertTmp) => {
        let alert = JSON.parse(JSON.stringify(alertTmp));
        if (alert.newAlimentId != null) {
            let currentFoodAlert;
            if (modified && modified.newAlimentId)
                currentFoodAlert = props.food_list.find(a => a.idFood === modified.newAlimentId);
            else currentFoodAlert = props.food_list.find(a => a.idFood === alert.aliment_id);

            let newFoodAlert = props.food_list.find(a => a.idFood === alert.newAlimentId);
            let currentValues = {};
            let newValues = {};
            let analyzes = JSON.parse(JSON.stringify(props.analyzes))
            if (newFoodAlert) {
                for (let analysis of analyzes) {
                    for (let day of analysis.days) {
                        if (moment(day.date).format('YYYY-MM-DD') === moment(alert.date).format('YYYY-MM-DD')) {
                            let currentCategoryIndex = day.categories.findIndex(a => a.category === currentFoodAlert.categoryFood);
                            let currentCategory = day.categories[currentCategoryIndex];
                            let currentFoodIndex = currentCategory.foods.findIndex(a => a.aliment_id === currentFoodAlert.idFood);

                            let currentFood = currentCategory.foods[currentFoodIndex];
                            let currentTrays = currentFood.trays;
                            currentValues = getValues(currentValues, currentFood);
                            currentCategory.foods.splice(currentFoodIndex, 1);
                            currentCategory = removeValues(currentCategory, currentValues, true);
                            newValues = getFoodValues(newFoodAlert, alert.volumeTot, alert.surfaceTot, currentTrays);
                            let newDailyFood = {
                                currentAlimentId: currentFoodAlert.idFood,
                                newAlimentId: newFoodAlert.idFood,
                                co2: newValues.co2,
                                cost: newValues.cost,
                                inevitableCo2: newValues.inevitableCo2,
                                inevitableCost: newValues.inevitableCost,
                                inevitableMass: newValues.inevitableMass,
                                mass: newValues.mass,
                                nameFood: newFoodAlert.nameFood,
                                trays: newValues.trays,
                                category: newValues.category,
                                camName: analysis.camName,
                                restaurant_name: props.restaurant_name,
                                camValue: analysis.camValue,
                                hour: analysis.hour,
                                date: props.selectedDate
                            }
                            updateDailyFoodWaste(newDailyFood);
                            if (currentCategory.mass === 0) day.categories.splice(currentCategoryIndex, 1);

                            let newCategory = day.categories.find(a => a.category === newFoodAlert.categoryFood);
                            if (newCategory) {
                                let newFood = newCategory.foods.find(a => a.aliment_id === currentFoodAlert.idFood);
                                if (newFood) {
                                    newFood = addNewValues(newFood, newValues, true);
                                }
                                else {
                                    newCategory.foods.push({
                                        aliment_id: newFoodAlert.idFood,
                                        co2: newValues.co2,
                                        cost: newValues.cost,
                                        inevitableCo2: newValues.inevitableCo2,
                                        inevitableCost: newValues.inevitableCost,
                                        inevitableMass: newValues.inevitableMass,
                                        mass: newValues.mass,
                                        nameFood: newFoodAlert.nameFood,
                                        trays: newValues.trays,
                                    })
                                }
                                newCategory = addNewValues(newCategory, newValues, true);
                            }
                            else {
                                day.categories.push({
                                    category: newFoodAlert.categoryFood,
                                    co2: newValues.co2,
                                    cost: newValues.cost,
                                    inevitableCo2: newValues.inevitableCo2,
                                    inevitableCost: newValues.inevitableCost,
                                    inevitableMass: newValues.inevitableMass,
                                    mass: newValues.mass,
                                    trays: newValues.trays,
                                    foods: [{
                                        aliment_id: newFoodAlert.idFood,
                                        co2: newValues.co2,
                                        cost: newValues.cost,
                                        inevitableCo2: newValues.inevitableCo2,
                                        inevitableCost: newValues.inevitableCost,
                                        inevitableMass: newValues.inevitableMass,
                                        mass: newValues.mass,
                                        nameFood: newFoodAlert.nameFood,
                                        trays: newValues.trays,
                                    }]
                                })
                            }


                            day = removeValues(day, currentValues);
                            day = addNewValues(day, newValues);
                        }
                    }
                    analysis = removeValues(analysis, currentValues);
                    analysis = addNewValues(analysis, newValues);
                }
                let analyzesTmp = JSON.parse(JSON.stringify(props.allAnalyzes));
                for (const analysis of analyzes) {
                    let index = analyzesTmp.findIndex(a => a != null && a.analysisNumber === analysis.analysisNumber);
                    analyzesTmp[index] = analysis;
                }
                dispatch(setInitialAnalyzes(analyzesTmp));
                let newBaseAnalyzes = updateDataForTrays(analyzesTmp, props.newTrays, props.multipleCams, props.camsNames, props.restaurant_name);
                dispatch(setAnalyzesBaseDatas(newBaseAnalyzes));
                getDatas(newBaseAnalyzes, props.userState.selected_hours, props.userState.selected_cams, props.dashboardState, dispatch, props.userState.averageGuests);

            }
        }
        alert.checked = true;
        let allAlerts = JSON.parse(JSON.stringify(props.alerts));
        let alertsDay = allAlerts.find(a => a.date === alert.date);
        let selectedAlert = alertsDay.alerts.findIndex(a => a.aliment_id === alert.aliment_id);
        alertsDay.alerts[selectedAlert] = alert;
        dispatch(setAlerts(allAlerts));
        let newAlert = {
            restaurant_name: alert.restaurant_name,
            firstAlimentId: modified ? modified.aliment_id : alert.aliment_id,
            aliment_id: modified ? (modified.newAlimentId ? modified.newAlimentId : modified.aliment_id) : alert.aliment_id,
            newAlimentId: alert.newAlimentId,
            date: alert.date,
            checked: alert.checked

        }
        updateAlert(newAlert)
        setModified(null);
    }
    const getRows = () => {
        let result = [];
        let alertsDay = props.alerts.find(a => moment(a.date).format("YYYY-MM-DD") === props.selectedDate);
        if (alertsDay) {
            for (const [index, alert] of alertsDay.alerts.entries()) {

                let mask_ref = getMaskOfImage(alert.image_ref[0], alert.masks_ref);
                let id = alert.aliment_id;
                let image_ref = alert.aliment_id;
                let proportion = alert.numberAlimentId + "/" + alert.totalSamplesNumberDay;
                result.push(
                    <div id={image_ref} style={{ width: "100%", display: "flex", flexDirection: "row", backgroundColor: index % 2 === 0 && "#F0F0F0", color: "#666666", minHeight: "50%" }}
                    >
                        <div id={image_ref} style={{ width: "25%", padding: "2%", cursor: "pointer", position: "relative", display: "flex", alignItems: "center" }} onClick={() => { dispatch(setSelectedAlert(alert.aliment_id)) }} >
                            <img onMouseEnter={(e) => { setHoveredImg(e.target.id); setHoveredId(alert.aliment_id) }}
                                onMouseOut={() => { setHoveredImg(null); setHoveredId(null) }} id={alert.image_ref[0]} className="img-pictures" src={alert.image_ref[0]} />
                            {
                                (hoveredImg === alert.image_ref[0] && hoveredId === id)
                                &&
                                <img onMouseEnter={(e) => { setHoveredImg(e.target.id); setHoveredId(alert.aliment_id) }}
                                    onMouseOut={() => { setHoveredImg(null); setHoveredId(null) }} id={alert.image_ref[0]} className="img-masks" src={mask_ref} />
                            }
                        </div>
                        <div onMouseEnter={(e) => { setHoveredImg(e.target.id); setHoveredId(alert.aliment_id) }}
                            onMouseOut={() => { setHoveredImg(null); setHoveredId(null) }} id={image_ref} style={{ width: "17%", alignItems: "center", height: "auto", justifyContent: "center", display: "flex" }}>
                            {getDisplayedValue(alert)}
                        </div>

                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "auto", width: "10%" }}>
                            {proportion}
                        </div>
                        <div style={{ width: "23%", alignItems: "center", height: "auto", justifyContent: "center", display: "flex" }}>
                            <CustomAutocomplete
                                id={id}
                                autocomplete={true}
                                width={"70%"} height={"15%"} backgroundColor={"#90C261"} arrowColor={"#fff"} color={"#fff"}
                                displayedValue={getDisplayedValue(alert, "new")}
                                selectedValue={getDisplayedValue(alert, "new")}
                                values={getAlimList()}
                                onChange={e => changeSelectedAlim(alert, e)}
                                isOpen={parseInt(id) === parseInt(dropdownIsOpen)}
                                maxHeightDropdown={"30vh"}
                                dropdownUpdate={Math.random()}
                                fontSize={"1vw"}
                                onClick={e => changeDropdownOpen(e)}
                                border={"1px solid #90C261"}
                            />
                        </div>
                        <div style={{ width: "10%", alignItems: "center", height: "auto", justifyContent: "center", display: "flex" }}>
                            {alert.checked === false ? t('components.unverified') : t('components.verified')}
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "10%" }}>
                            <CustomButton width={"90%"} height={"15%"} backgroundColor={"#89CD85"} color={"#fff"} onClick={() => updateDatasForAlert(alert)} value={t('buttons.confirm')} />
                        </div>
                        <div style={{ width: "5%", alignItems: "center", height: "auto", justifyContent: "center", display: "flex" }}>
                            {alert.checked ?
                                <Check style={{ width: "80%", height: "30%" }} fill={"#72B9A2"} /> :
                                <Warning style={{ width: "80%", height: "30%" }} fill={"#EA881F"} />
                            }
                        </div>
                    </div >
                )
            }
        }
        return result;
    }
    return (
        <CustomBlock width={"100%"} height={"100%"}>
            {imageModal()}
            <div style={{ height: "10%", width: "100%", display: "flex", flexDirection: "row", color: "#666666", fontWeight: 500, fontSize: "1.2vw" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "25%" }}>
                    {t('table.picture')}
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "17%" }}>
                    {t('table.recognizeFood')}
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "10%" }}>
                    {t('table.proportion')}
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "23%" }}>
                    {t('table.trueAlim')}
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "10%" }}>
                    {t('table.state')}
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "10%" }}>
                    {t('table.actions')}
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "5%" }}>
                </div>
            </div>
            <div style={{ height: "90%", width: "100%" }}>
                <CustomScrollDiv update={update}>
                    {getRows()}
                </CustomScrollDiv>

            </div>
        </CustomBlock >
    )
}

export default AlertsDetails;