import { useTranslation } from "react-i18next";
import CustomBlock from "../../customComponents/CustomBlock";
import { useDispatch } from "react-redux";
import CustomCheckbox from "../../customComponents/CustomCheckbox";
import CustomButton from "../../customComponents/CustomButton";
import { setAnalyzesDatas, setKitchenAnalyzesDatas } from "../../../actions_reducers/actions/datas.action";
import { updateAnalysisValues } from "../../../apiRoutes/analyzes.routes";


export const FilesConfirm = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const getSelectedAnalysisValues = () => {
        let hasMenus = false;
        let hasEffectives = false;
        let isOver = false;
        let analysisNumber = 0;
        let analysis = props.analyzesDatas.find(a => t('arianne.detailedAnalysis', { complementValue: a.analysisNumber }) === props.selected_analysis)
        if (!analysis) analysis = props.kitchenDatas.find(a => t('arianne.detailedAnalysis', { complementValue: a.analysisNumber }) === props.selected_analysis)
        if (analysis) {
            hasMenus = analysis.hasMenus;
            hasEffectives = analysis.hasEffectives;
            isOver = analysis.isOver;
            analysisNumber = analysis.analysisNumber;
        }
        return {
            hasMenus: hasMenus,
            hasEffectives: hasEffectives,
            isOver: isOver,
            analysisNumber: analysisNumber,
        }
    }
    const updateValue = (field, newValue) => {
        let analyzes = JSON.parse(JSON.stringify(props.analyzesDatas));
        let kitchenAnalyzes = JSON.parse(JSON.stringify(props.kitchenDatas));
        let analysisIndex = analyzes.findIndex(a => t('arianne.detailedAnalysis', { complementValue: a.analysisNumber }) === props.selected_analysis)
        let kitchenAnalysisIndex = kitchenAnalyzes.findIndex(a => t('arianne.detailedAnalysis', { complementValue: a.analysisNumber }) === props.selected_analysis)
        if (analysisIndex !== -1) {
            analyzes[analysisIndex][field] = newValue;
            dispatch(setAnalyzesDatas(analyzes));
        }
        if (kitchenAnalysisIndex !== -1) {
            kitchenAnalyzes[kitchenAnalysisIndex][field] = newValue;
            dispatch(setKitchenAnalyzesDatas(kitchenAnalyzes))
        }
    }
    const confirmChanges = async () => {
        let params = {
            hasEffectives: getSelectedAnalysisValues().hasEffectives,
            hasMenus: getSelectedAnalysisValues().hasMenus,
            isOver: getSelectedAnalysisValues().isOver
        }
        await updateAnalysisValues(props.restaurant_name, getSelectedAnalysisValues().analysisNumber, params)
    }
    return (
        <CustomBlock
            width={"100%"}
            height={"100%"}
            paddingLeft={"1%"}
            paddingRight={"1%"}
            paddingTop={"1%"}
            paddingBottom={"1%"}
            backgroundColor={"#fff"}
        >
            <div style={{ height: "25%", fontWeight: "bold" }}>
                {t('components.filesAnalysisTitle', { value: props.selected_analysis })}
            </div>
            <div style={{ height: "75%", display: "flex" }}>
                <div style={{ width: "25%" }}>
                    <div style={{ height: "10%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        Validations des effectifs
                    </div>
                    <div style={{ height: "90%" }}>
                        <div style={{ height: "50%", display: "flex" }}>
                            <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <CustomCheckbox checked={getSelectedAnalysisValues().hasEffectives === true}
                                    onChange={() => updateValue('hasEffectives', true)} />
                            </div>
                            <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                Oui
                            </div>
                        </div>
                        <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <CustomCheckbox checked={getSelectedAnalysisValues().hasEffectives === false}
                                    onChange={() => updateValue('hasEffectives', false)} />
                            </div>
                            <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                Non
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ width: "2%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ borderRight: "1px solid #666666", width: "2", height: "80%" }} />

                </div>
                <div style={{ width: "25%" }}>
                    <div style={{ height: "10%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        Validations des menus
                    </div>
                    <div style={{ height: "90%" }}>
                        <div style={{ height: "50%", display: "flex" }}>
                            <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <CustomCheckbox checked={getSelectedAnalysisValues().hasMenus === true}
                                    onChange={() => updateValue('hasMenus', true)} />
                            </div>
                            <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                Oui
                            </div>
                        </div>
                        <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <CustomCheckbox checked={getSelectedAnalysisValues().hasMenus === false}
                                    onChange={() => updateValue('hasMenus', false)} />
                            </div>
                            <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                Non
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "2%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ borderRight: "1px solid #666666", width: "2", height: "80%" }} />

                </div>
                <div style={{ width: "25%" }}>
                    <div style={{ height: "10%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        Validations de la synthèse
                    </div>
                    <div style={{ height: "90%" }}>
                        <div style={{ height: "50%", display: "flex" }}>
                            <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <CustomCheckbox checked={getSelectedAnalysisValues().isOver === true}
                                    onChange={() => updateValue('isOver', true)} />
                            </div>
                            <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                Oui
                            </div>
                        </div>
                        <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <CustomCheckbox checked={getSelectedAnalysisValues().isOver === false}
                                    onChange={() => updateValue('isOver', false)} />
                            </div>
                            <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                Non
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "21%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CustomButton height={"30%"} width={"80%"} color={"#fff"} backgroundColor={"#90c261"} value={t('buttons.checkFiles')} onClick={() => confirmChanges()} />
                </div>
            </div>
        </CustomBlock>
    )
}