
import { useEffect, useState, useRef } from "react";
import { ReactComponent as ArrowDown } from "../../assets/arrowdown2.svg";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import CustomScrollDiv from "./CustomScrollBar";
const CustomDropdown = (props) => {
    const [search, setSearch] = useState("");
    const [scrollToPos, setScrollToPos] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const changeDropdownOpen = () => setDropdownOpen(!dropdownOpen)
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);
    useEffect(() => {
        setSearch("");
    }, [props.isOpen])

    const checkKey = (e) => {
        let tmp = search;
        if (e.key !== "Backspace") {
            tmp = tmp + e.key;
            setSearch(old => old + e.key)
            let pos = props.values.findIndex(a => a.toLowerCase().includes(tmp));
            if (pos !== -1)
                setScrollToPos((pos) / (props.values.length));
            else
                setScrollToPos(0)
        }
        else {
            tmp.slice(0, -1);
            setSearch(old => old.slice(0, -1))
            let pos = props.values.findIndex(a => a.toLowerCase().includes(tmp));
            if (pos !== -1)
                setScrollToPos((pos) / (props.values.length));
            else
                setScrollToPos(0)
        }

    }
    const getValue = (value) => {
        if (props.alert) {
            return (
                <div key={props.id + '-' + value.name} id={value.name} style={{ position: "relative" }} className="custom-dropdown-choice-div" onClick={(e) => props.onChange(e)} value={value}>
                    {value.name}
                    <div className="custom-dropdown-choice-hovered-div" id={value.name} value={value.name}>
                        {value.name}
                    </div>
                    <div style={{ position: "absolute", height: "100%", width: "20%", right: 12, top: "13%", display: "flex", justifyContent: "center" }}>
                        {value.warning && <Warning style={{ width: "75%", height: "100%" }} fill={"#fff"} />}
                    </div>
                </div>
            )
        }
        else
            return (
                <div key={props.id + '-' + value} id={value} className="custom-dropdown-choice-div" onClick={(e) => props.onChange(e)} value={value}>
                    {value}
                    <div className="custom-dropdown-choice-hovered-div" id={value} value={value}>
                        {value}
                    </div>
                </div>
            )
    }
    if (props.autocomplete === true && props.isOpen === true)
        document.onkeydown = checkKey;
    return (
        <div className='custom-dropdown'
            id={props.id}
            style={{
                height: props.height,
                width: props.width,
                marginLeft: props.marginLeft || 0,
                marginRight: props.marginRight || 0,
                marginTop: props.marginTop || 0,
                marginBottom: props.marginBottom || 0,
                backgroundColor: props.backgroundColor || '#ffffff',
                color: props.color || '#ffffff',
                borderRadius: props.borderRadius && props.borderRadius,
                fontSize: props.fontSize || "1.1vw",
                zIndex: dropdownOpen || props.isOpen ? 1000000 : props.zIndex ? props.zIndex : 0,
                // border: props.border || "2px solid red",
                position: props.position || "relative",
                top: props.top,
                bottom: props.bottom,
                right: props.right,
                left: props.left,
                fontWeight: props.fontWeight
            }}
            ref={dropdownRef}
            onClick={(e) => { props.onClick ? props.onClick(e) : changeDropdownOpen() }}>
            {props.displayedValue}
            <ArrowDown className='arrow-down-img' fill={props.arrowColor || "#666666"} style={{ width: props.arrowWidth && props.arrowWidth }} />
            {props.maxHeightDropdown ?

                < div className="custom-dropdown-choices"
                    style={{
                        display: dropdownOpen || props.isOpen ? 'block' : 'none',
                        backgroundColor: props.backgroundColor || '#ffffff',
                        height: props.maxHeightDropdown
                    }}>
                    <CustomScrollDiv id={props.id + 'dropdown'} height={props.maxHeightDropdown} scrolltopos={scrollToPos} update={Math.random()}>
                        {props.values && props.values.map((value) => {
                            return (getValue(value))
                        })
                        }
                    </CustomScrollDiv>

                </div> :
                < div className="custom-dropdown-choices"
                    style={{
                        display: dropdownOpen || props.isOpen ? 'block' : 'none',
                        backgroundColor: props.backgroundColor || '#ffffff',
                    }}>
                    {props.values && props.values.map((value) => {
                        return (
                            <div key={props.id + '-' + value} id={value} className="custom-dropdown-choice-div" onClick={(e) => props.onChange(e)} value={value}>
                                {value}
                                <div className="custom-dropdown-choice-hovered-div" id={value} value={value}>
                                    {value}
                                </div>
                            </div>
                        )
                    })
                    }

                </div>
            }
        </div >
    )
}

export default CustomDropdown;