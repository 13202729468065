import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import { setAllDatesPictures, setLoadingPictures, setPictures, setSelectedPicture } from "../../../actions_reducers/actions/pictures.action";
import { getAllDatesTrays, getTraysWithSamplesForDate } from "../../../apiRoutes/trays.route";
import PicturesDetails from "./PicturesDetails";
import PicturesCalendar from "./PicturesCalendar";
import PicturesTop from "./PicturesTop";
import PicturesDetailsRestaurants from "./PicturesDetailsRestaurants";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const Pictures = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        getDatesPictures()
    }, [])
    const getDatesPictures = async () => {
        let dates = await getAllDatesTrays(props.restaurant_name);
        dispatch(setAllDatesPictures(dates));
    }

    useEffect(() => {
        if (props.restaurant_name === "DESCOBERTAS" && props.pictures.length === 0)
            getAllPictures();
        else if (props.restaurant_name !== "DESCOBERTAS" && props.selectedDate != null && !props.pictures.find(a => a.date === props.selectedDate))
            getPicturesForDay()
    }, [props.selectedDate]);

    const getMass = (sample) => {
        const food = props.food_list.find(a => a.idFood === sample.aliment_id);
        const foodValue = props.food_values.find(a => a.aliment_id === sample.aliment_id)
        let mass = 0;
        let cal = 0;
        let prot = 0;
        if (food) {
            let volume = sample.volume;
            if (food.nameFood === "Salade")
                mass = (parseFloat(sample.surface) / 100) * parseFloat(food.surfaceMassFood);
            else mass = (parseFloat(volume) / 1000) * parseFloat(food.volumicMassFood);
            if (foodValue) {
                cal = foodValue.cal * mass / 100;
                prot = foodValue.prot * mass / 100;
            }
        }
        return {
            mass: mass,
            cal: cal,
            prot: prot
        };
    }
    const getAllPictures = async () => {
        dispatch(setLoadingPictures(true))
        let pictures = await getTraysWithSamplesForDate(props.restaurant_name, props.selectedDate);
        let allPictures = [];
        for (const picture of pictures) {
            let massTot = 0;
            let calTot = 0;
            let protTot = 0;
            for (const sample of picture.samples) {
                sample.mass = getMass(sample).mass;
                sample.cal = getMass(sample).cal;
                sample.pro = getMass(sample).prot;
                massTot += parseFloat(sample.mass);
                calTot += parseFloat(sample.cal);
                protTot += parseFloat(sample.prot);
            }
            picture.samples.sort((a, b) => b.mass - a.mass)
            picture.mass = massTot;
            picture.cal = calTot;
            picture.prot = protTot;
            let index = allPictures.findIndex(a => a.date === moment(picture.created_at).format("YYYY-MM-DD"))
            if (index !== -1) {
                allPictures[index].pictures.push(picture);
            }
            else {
                allPictures.push({
                    date: moment(picture.created_at).format("YYYY-MM-DD"),
                    pictures: [picture]
                })
            }
        }
        for (const date of allPictures) {
            date.pictures.sort((a, b) => b.mass - a.mass)
        }
        dispatch(setPictures(allPictures))
        dispatch(setLoadingPictures(false))

    }
    const getPicturesForDay = async () => {
        dispatch(setLoadingPictures(true))
        let pictures = await getTraysWithSamplesForDate(props.restaurant_name, props.selectedDate);
        for (const picture of pictures) {
            let massTot = 0;
            let calTot = 0;
            let protTot = 0;
            for (const sample of picture.samples) {
                sample.mass = getMass(sample).mass;
                sample.cal = getMass(sample).cal;
                sample.prot = getMass(sample).prot;
                massTot += parseFloat(sample.mass);
                calTot += parseFloat(sample.cal);
                protTot += parseFloat(sample.prot);
            }
            picture.samples.sort((a, b) => b.mass - a.mass)
            picture.mass = massTot;
            picture.cal = calTot;
            picture.prot = protTot;
        }
        pictures.sort((a, b) => b.mass - a.mass);
        let allPictures = JSON.parse(JSON.stringify(props.pictures));
        let newPictures = {
            date: props.selectedDate,
            pictures: pictures
        }
        allPictures.push(newPictures)
        dispatch(setPictures(allPictures))
        dispatch(setLoadingPictures(false))
    }

    const isRestaurantAdmin = () => {
        if (props.restaurant_name === "LEANPATH" && props.selectedDate === "2024-04-02")
            return true;
        else if (props.restaurant_name === "DESCOBERTAS")
            return true;
        else if (props.restaurant_type === "hopital")
            return true;
        else return false;
    }

    const getShowedPictures = () => {
        if (props.userState.login !== "admin" && !isRestaurantAdmin())
            return (
                <PicturesDetailsRestaurants
                    restaurant_name={props.restaurant_name}
                    alimsToDelete={props.alimsToDelete}
                    picturesToDelete={props.picturesToDelete}
                    samplesModif={props.samplesModif}
                    pictures={props.pictures}
                    selectedDate={props.selectedDate}
                    food_list={props.food_list}
                    selectedPicture={props.selectedPicture}
                    userState={props.userState}
                    selectedFilter={props.selectedFilter}
                    loading={props.loading} />)
        else
            return (
                <PicturesDetails
                    restaurant_name={props.restaurant_name}
                    alimsToDelete={props.alimsToDelete}
                    picturesToDelete={props.picturesToDelete}
                    samplesModif={props.samplesModif}
                    pictures={props.pictures}
                    selectedDate={props.selectedDate}
                    food_list={props.food_list}
                    selectedPicture={props.selectedPicture}
                    loading={props.loading}
                    numberPages={props.numberPages}
                    selectedFilter={props.selectedFilter}
                    userState={props.userState}
                    selectedMonth={props.selectedMonth}
                    restaurant_type={props.restaurant_type} />)
    }
    return (
        <div className="main-page-main-div" style={{ height: "85.8vh" }}
            onClick={(e) => {
                if (props.selectedPicture !== null && e.target.id !== props.selectedPicture && e.target.localName !== "path")
                    dispatch(setSelectedPicture(null))
            }}>
            {props.selectedDate != null &&
                <>
                    <div style={{ height: "8%" }}>
                        <PicturesTop
                            restaurant_name={props.restaurant_name}
                            pictures={props.pictures} selectedDate={props.selectedDate}
                            food_list={props.food_list}
                            picturesToDelete={props.picturesToDelete}
                            alerts={props.alerts}
                            dates={props.dates}
                            selectedAnalysis={props.selectedAnalysis}
                            modifiedAlerts={props.modifiedAlerts}
                            userState={props.userState}
                            selectedMonth={props.selectedMonth}
                            daysList={props.daysList} />
                    </div>
                    <div style={{ height: "3%" }} />
                </>}
            <div style={{ height: props.selectedDate != null ? "89%" : "100%", width: "100%" }}>
                {
                    props.selectedDate ?
                        getShowedPictures()
                        :
                        <PicturesCalendar dates={props.dates} lastSelectedDate={props.lastSelectedDate} />
                }
            </div>
        </div >
    )
}
function mapStateToProps(state) {
    return {
        restaurant_name: state.userReducer.selected_restaurant,
        selectedPicture: state.picturesReducer.selectedPicture,
        dates: state.picturesReducer.dates,
        analyzesDatas: state.datasReducer.analyzesDatas,
        selectedDate: state.picturesReducer.selectedDate,
        food_list: state.datasReducer.food_list,
        pictures: state.picturesReducer.pictures,
        samplesModif: state.picturesReducer.samplesModif,
        picturesToDelete: state.picturesReducer.picturesToDelete,
        alimsToDelete: state.picturesReducer.alimsToDelete,
        lastSelectedDate: state.picturesReducer.lastSelectedDate,
        userState: state.userReducer,
        selectedMonth: state.picturesReducer.selectedMonth,
        loading: state.picturesReducer.loading,
        numberPages: state.picturesReducer.numberPages,
        daysList: state.picturesReducer.daysList,
        selectedFilter: state.picturesReducer.selectedFilter,
        food_values: state.datasReducer.food_values,
        restaurant_type: state.userReducer.restaurant_type
    };
}

export default connect(mapStateToProps)(Pictures);