import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import { getTraysValueForCamAndHour } from "../../../customFunctions/trays.function";
import { useState } from "react";
import { ReactComponent as Information } from "../../../../assets/informationButton.svg";

const TraysValues = (props) => {
    const { t } = useTranslation();
    const [showInfo, setShowInfo] = useState(false)

    const getInfo = (type) => {
        return (
            <Information fill={"#FFFFFF"} style={{ width: "2vw", height: "3vh", top: "4%", right: "1%", position: "absolute" }}
                onMouseLeave={() => setShowInfo(false)}
                onMouseEnter={() => setShowInfo(true)} />)
    }
    const getAverageTrays = () => {
        if (props.analysis) {
            let trays = (props.analysis.trays / props.analysis.days.length).toFixed(0);
            return trays;
        }
        else return 0;
    }
    const getEmptyTrays = () => {
        let emptyTrays = "0 %";
        if (props.analysis && props.analysisTrays) {
            let empties = getTraysValueForCamAndHour(props.analysisTrays.empty, props.userState, "empty");
            emptyTrays = ((empties / props.analysis.trays) * 100).toFixed(0) + '%';
        }
        return emptyTrays;
    }
    const getSortedTrays = () => {
        let sortedTrays = "0 %";
        if (props.analysis && props.analysisTrays) {
            let nonOrgas = getTraysValueForCamAndHour(props.analysisTrays.nonOrga, props.userState, "nonOrga");
            sortedTrays = (((1 - nonOrgas / props.analysis.trays)) * 100).toFixed(0) + '%';
        }
        return sortedTrays;
    }
    return (
        <div style={{ width: '100%', height: '100%', minHeight: "100%" }}>
            <div style={{ width: '100%', height: '30%' }}>
                <CustomBlock width={'100%'} height={'100%'} backgroundColor={"#CACACA"} color={"#fff"} paddingLeft={"5%"} paddingBottom={"3%"}>
                    <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                        <div className="dashboard-block-tray-value">
                            {getAverageTrays()}
                        </div>
                        <div className="dashboard-graph-tray-text" style={{ lineHeight: "125%", paddingBottom: "2.5%" }}>
                            {t('components.averageTrays')}
                        </div>
                    </div>
                </CustomBlock>
            </div>
            <div style={{ height: "5%" }} />
            <div style={{ width: '100%', height: '30%' }}>
                <CustomBlock width={'100%'} height={'100%'} backgroundColor={"#CACACA"} color={"#fff"} paddingLeft={"5%"} paddingBottom={"3%"}>
                    <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                        <div className="dashboard-block-tray-value" style={{ paddingTop: "6%" }}>
                            {getEmptyTrays()}
                        </div>
                        <div className="dashboard-graph-tray-text" style={{ paddingBottom: "6%" }}>
                            {t('components.emptyTrays')}
                        </div>
                    </div>
                </CustomBlock>
            </div>
            <div style={{ height: "5%" }} />
            <div style={{ width: '100%', height: '30%' }}>
                <CustomBlock width={'100%'} height={'100%'} backgroundColor={"#CACACA"} color={"#fff"} position={"relative"} paddingLeft={"5%"} paddingBottom={"3%"}>
                    {getInfo()}
                    {showInfo && (
                        <div className="window-info" style={{
                            width: "20vw", position: "absolute", bottom: "100%",
                            right: "-19vw", zIndex: "20031997", backgroundColor: "#FFFFFF", padding: "10px", borderRadius: "10px", fontSize: "2vh",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            color: "#666666"
                        }}>
                            {t("components.sortedTraysInfo")}</div>
                    )}
                    <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                        <div className="dashboard-block-tray-value" style={{ paddingTop: "6%" }}>
                            {getSortedTrays()}
                        </div>
                        <div className="dashboard-graph-tray-text" style={{ paddingBottom: "6%" }}>
                            {t('components.sortedTrays')}
                        </div>
                    </div>
                </CustomBlock>
            </div>
        </div>
    )
}
export default TraysValues;
