import { useEffect, useState, useRef } from "react";
import { ReactComponent as ArrowDown } from "../../assets/arrowdown2.svg";
import CustomScrollDiv from "./CustomScrollBar";
import { useTranslation } from "react-i18next";

const CustomAutocomplete = (props) => {
    const { t } = useTranslation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(props.values);

    const dropdownRef = useRef(null);
    useEffect(() => {
        setFilteredOptions(props.values.filter(option =>
            option && option.toLowerCase().includes(inputValue.toLowerCase())
        ));
    }, [inputValue, props.values]);
    useEffect(() => { setInputValue(props.selectedValue || '') }, [props.selectedValue])
    const changeDropdownOpen = () => setDropdownOpen(!dropdownOpen)
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        setDropdownOpen(true);
    };
    return (
        <div className='custom-dropdown'
            ref={dropdownRef}
            id={props.id}
            style={{
                height: props.height,
                width: props.width,
                marginLeft: props.marginLeft || 0,
                marginRight: props.marginRight || 0,
                marginTop: props.marginTop || 0,
                marginBottom: props.marginBottom || 0,
                backgroundColor: props.backgroundColor || '#ffffff',
                color: props.color || '#ffffff',
                borderRadius: props.borderRadius && props.borderRadius,
                fontSize: props.fontSize || "1.1vw",
                zIndex: dropdownOpen ? 1000 : 1,
                border: props.border,
                position: props.position || "relative",
                top: props.top,
                bottom: props.bottom,
                right: props.right,
                left: props.left,
                fontWeight: props.fontWeight,
                boxShadow: props.boxShadow
            }}
            onClick={() => changeDropdownOpen()}>
            <input
                type="text"
                onClick={() => setInputValue('')}
                value={inputValue}
                onChange={handleInputChange}
                className="autocomplete-dropdown-input"
                style={{ '--placeholder-color': props.color || "#ffffff" }}
                placeholder={props.selectedValue || t('components.typeSearch')}
            />
            <ArrowDown className='arrow-down-img' fill={props.arrowColor || "#666666"} style={{ width: props.arrowWidth && props.arrowWidth }} />
            {dropdownOpen && < div className="custom-dropdown-choices"
                style={{
                    display: dropdownOpen ? 'block' : 'none',
                    backgroundColor: props.backgroundColor || '#ffffff',
                    height: props.maxHeightDropdown,
                    zIndex: dropdownOpen ? 1000 : 1
                }}>
                <CustomScrollDiv id={props.id + 'dropdown'} update={Math.random()}>

                    {filteredOptions.map((value) => {

                        return (<div key={props.id + '-' + value} id={value} className="custom-dropdown-choice-div" style={{ fontSize: "0.9vw" }} onClick={(e) => { setInputValue(value); props.onChange(e) }} value={value}>
                            {value}
                            <div className="custom-dropdown-choice-hovered-div" id={value} value={value}>
                                {value}
                            </div>
                        </div>)
                    })
                    }
                </CustomScrollDiv>

            </div>}
        </div >
    )
}

export default CustomAutocomplete;