import React from "react";
import CustomDropdown from "../../customComponents/CustomDropdown";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import 'moment/locale/fr';
import { setSelectedCamSearch, setSelectedRoomSearch, setSelectedServiceSearch } from "../../../actions_reducers/actions/search.action";
import CustomBlock from "../../customComponents/CustomBlock";

const SearchTop = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const getDisplayedCamValue = () => {
        let index = props.cams.findIndex(a => a.camName === props.selectedCam);
        let newName = props.cams[index].displayName;
        return newName
    }

    const updateCamValue = (e) => {
        let value = e.target.id;
        let index = props.cams.findIndex(a => a.displayName === value);
        let newName = props.cams[index].camName;
        dispatch(setSelectedCamSearch(newName));
    }

    const getCamsDropdown = () => {
        let values = props.cams.map(item => item.displayName);
        if (hasCamsDropdown())
            return (
                <CustomDropdown
                    id={'dropdown-search-top'}
                    width={"100%"}
                    height={"70%"}
                    color={"#666666"}
                    values={values}
                    displayedValue={getDisplayedCamValue()}
                    onChange={updateCamValue}
                />
            )
        else
            return (
                <CustomBlock
                    id={'dropdown-search-top'}
                    width={"100%"}
                    height={"70%"}
                    color={"#666666"}
                    display={"flex"}
                    justifyContent="center"
                    alignItems="center"
                >
                    {t('components.firstCam')}
                </CustomBlock>
            )
    }


    const updateServiceValue = (e) => {
        let value = e.target.id;
        if (value === t('hours.total'))
            dispatch(setSelectedServiceSearch('total'));
        else if (value === t('hours.noon'))
            dispatch(setSelectedServiceSearch('noon'));
        else if (value === t('hours.evening'))
            dispatch(setSelectedServiceSearch('evening'))
    }

    const getServicesDropdown = () => {
        let values = [t('hours.total'), t('hours.noon'), t('hours.evening')];
        if (hasHoursDropdown())
            return (
                <CustomDropdown
                    width={"100%"}
                    height={"70%"}
                    color={"#666666"}
                    values={values}
                    displayedValue={t(`hours.${props.selectedService}`)}
                    onChange={updateServiceValue}
                />
            )
        else
            return (
                <CustomBlock
                    width={"100%"}
                    height={"70%"}
                    color={"#666666"}
                    display={"flex"}
                    justifyContent="center"
                    alignItems="center"
                >
                    {t(`hours.noon`)}
                </CustomBlock>
            )
    }


    const updateRoomValue = (e) => {
        let value = e.target.id;
        if (value === t('dataTypes.total'))
            dispatch(setSelectedRoomSearch('total'));
        else if (value === t('dataTypes.restaurant'))
            dispatch(setSelectedRoomSearch('restaurant'));
        else if (value === t('dataTypes.kitchen'))
            dispatch(setSelectedRoomSearch('kitchen'))
    }

    const getRoomDropdown = () => {
        let values = [t('dataTypes.total'), t('dataTypes.restaurant'), t('dataTypes.kitchen')];
        if (hasRoomDropdown())
            return (
                <CustomDropdown
                    width={"100%"}
                    height={"70%"}
                    color={"#666666"}
                    values={values}
                    displayedValue={t(`dataTypes.${props.selectedRoom}`)}
                    onChange={updateRoomValue}
                />
            )
        else
            return (
                <CustomBlock
                    width={"100%"}
                    height={"70%"}
                    color={"#666666"}
                    display={"flex"}
                    justifyContent="center"
                    alignItems="center"
                >
                    {t(`dataTypes.${props.selectedRoom}`)}
                </CustomBlock>
            )
    }
    const hasCamsDropdown = () => {
        if (props.cams.length > 1)
            return true;
        else return false;
    }
    const hasHoursDropdown = () => {
        if (props.userReducer.evening && props.userReducer.noon)
            return true;
        else return false;
    }
    const hasRoomDropdown = () => {
        if (props.userReducer.has_kitchen)
            return true;
        else return false;
    }
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", justifyContent: 'flex-end' }}>
            <div style={{ width: "30%", height: "100%", display: 'flex', justifyContent: "flex-end" }}>
                {getCamsDropdown()}
            </div>
            <div style={{ width: "5%" }} />
            <div style={{ width: "30%", height: "100%", display: 'flex', justifyContent: "flex-end" }}>
                {getServicesDropdown()}
            </div>
            <div style={{ width: "5%" }} />
            <div style={{ width: "30%", height: "100%", display: 'flex', justifyContent: "flex-end" }}>
                {getRoomDropdown()}
            </div>
        </div>
    )
}

export default SearchTop;