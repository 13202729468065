import React from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import { FilesDrop } from "./FilesDrop";
import './Files.css';
import { FilesAnalyzes } from "./FilesAnalyzes";
import { useEffect } from "react";
import { setAnalyzesSynthesis, setOnBoardingDone, setRealTrays, setSelectedAnalysisFiles, setShowOnBoarding } from "../../../actions_reducers/actions/files.action";
import { useTranslation } from "react-i18next";
import { FilesNumberGuests } from "./FilesNumberGuests";
import { FilesOnBoarding } from "./FilesOnBoarding";
import { getOnboarding } from "../../../apiRoutes/onBoarding.routes";
import CustomButton from "../../customComponents/CustomButton";
import { setAllowedRefill, setAverageGuestsOnBoarding, setBreadLimit, setBreadMass, setBreadMoment, setBreadOrigin, setBreadPosition, setBreadRefill, setBreadType, setCamsOnBoarding, setHasActions, setHasSaturdayEvening, setHasSaturdayNoon, setHasSaturdayOnBoarding, setHasSundayEvening, setHasSundayNoon, setHasSundayOnBoarding, setHasWeekEvening, setHasWeekNoon, setHasWeekOnBoarding, setNumberBread, setNumberVege, setPortionType, setProjectContact, setProjectContactMail, setProjectContactPhone, setProjectReferent, setProjectReferentMail, setProjectReferentPhone, setReasonService, setSaturdayEveningHours, setSaturdayNoonHours, setSelfFoods, setServiceType, setStakeholders, setSundayEveningHours, setSundayNoonHours, setTeamMotivation, setTeamMotivationNote, setTypeActions, setVegeType, setWasteManagementMethodOnBoarding, setWeekEveningHours, setWeekNoonHours, setWhenRefill, setWhyNotActions, setWhyNotRefill } from "../../../actions_reducers/actions/onBoarding.action";
import { hasSynthesisAnalysis } from "../../../apiRoutes/files.routes";
import { getAnalyzes } from "../../../apiRoutes/analyzes.routes";
import { setAllAnalyzes } from "../../../actions_reducers/actions/datas.action";
import { FilesConfirm } from "./FilesConfirm";
import { getCaptedTraysByAnalysis } from "../../../apiRoutes/trays.route";

const Files = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.realTrays.length === 0)
            getRealTraysByAnalysis();
        getAnalyzesSynthesis()
        let selected_analysis = "analyse 1";
        let analyzes = JSON.parse(JSON.stringify(props.analyzesDatas)).sort((a, b) => b.analysisNumber - a.analysisNumber);
        let kitchenAnalyzes = JSON.parse(JSON.stringify(props.kitchenDatas)).sort((a, b) => b.analysisNumber - a.analysisNumber);
        if (analyzes[0])
            selected_analysis = t('arianne.detailedAnalysis', { complementValue: analyzes[0].analysisNumber })
        else if (kitchenAnalyzes[0])
            selected_analysis = t('arianne.detailedAnalysis', { complementValue: kitchenAnalyzes[0].analysisNumber });
        setOnboarding();
        dispatch(setSelectedAnalysisFiles(selected_analysis));
    }, []);

    const getRealTraysByAnalysis = async () => {
        let realTrays = await getCaptedTraysByAnalysis(props.restaurant_name);
        dispatch(setRealTrays(realTrays));
    }

    const getAnalyzesSynthesis = async () => {
        let analyzesSynthesis = [];
        let allAnalyzes = await getAnalyzes(props.restaurant_name);
        dispatch(setAllAnalyzes(allAnalyzes))
        for (const analysis of props.analyzesDatas) {
            let analysisName = 'analyse_' + analysis.analysisNumber;
            let restaurant_name = props.restaurant_name;
            let path = restaurant_name + "/synthesis/" + analysisName + "/";
            let hasSynthesis = await hasSynthesisAnalysis(path);
            let synthesis = {
                analysisNumber: analysis.analysisNumber,
                hasSynthesis: hasSynthesis
            }
            analyzesSynthesis.push(synthesis)
        }
        dispatch(setAnalyzesSynthesis(analyzesSynthesis));
    }

    const setOnboarding = async () => {
        const onBoarding = await getOnboarding(props.restaurant_name);
        if (onBoarding) {
            setOnBoardingValues(onBoarding)
            dispatch(setOnBoardingDone(true));
        }
    }
    const setOnBoardingValues = (onBoarding) => {
        dispatch(setHasWeekOnBoarding(onBoarding.hasWeek))
        dispatch(setHasSaturdayOnBoarding(onBoarding.hasSaturday))
        dispatch(setHasSundayOnBoarding(onBoarding.hasSunday))
        dispatch(setHasWeekNoon(onBoarding.hasWeekNoon))
        dispatch(setHasWeekEvening(onBoarding.hasWeekEvening))
        dispatch(setHasSaturdayNoon(onBoarding.hasSaturdayNoon))
        dispatch(setHasSaturdayEvening(onBoarding.hasSaturdayEvening))
        dispatch(setHasSundayNoon(onBoarding.hasSundayNoon))
        dispatch(setHasSundayEvening(onBoarding.hasSundayEvening))
        dispatch(setWeekNoonHours(onBoarding.weekNoonHours))
        dispatch(setWeekEveningHours(onBoarding.weekEveningHours))
        dispatch(setSaturdayNoonHours(onBoarding.saturdayNoonHours))
        dispatch(setSaturdayEveningHours(onBoarding.saturdayEveningHours))
        dispatch(setSundayNoonHours(onBoarding.sundayNoonHours))
        dispatch(setSundayEveningHours(onBoarding.sundayEveningHours))
        dispatch(setAverageGuestsOnBoarding(onBoarding.averageGuests))
        dispatch(setCamsOnBoarding(onBoarding.cams))
        dispatch(setServiceType(onBoarding.serviceType))
        dispatch(setReasonService(onBoarding.reasonService))
        dispatch(setSelfFoods(onBoarding.selfFoods))
        dispatch(setBreadPosition(onBoarding.breadPosition))
        dispatch(setBreadType(onBoarding.breadType))
        dispatch(setBreadMass(onBoarding.breadMass))
        dispatch(setBreadLimit(onBoarding.breadLimit))
        dispatch(setBreadOrigin(onBoarding.breadOrigin))
        dispatch(setNumberBread(onBoarding.numberBread))
        dispatch(setBreadRefill(onBoarding.breadRefill))
        dispatch(setBreadMoment(onBoarding.breadMoment))
        dispatch(setPortionType(onBoarding.portionType))
        dispatch(setAllowedRefill(onBoarding.allowedRefill))
        dispatch(setWhenRefill(onBoarding.whenRefill))
        dispatch(setWhyNotRefill(onBoarding.whyNotRefill))
        dispatch(setNumberVege(onBoarding.numberVege))
        dispatch(setVegeType(onBoarding.vegeType))
        dispatch(setWasteManagementMethodOnBoarding(onBoarding.wasteManagementMethod))
        dispatch(setHasActions(onBoarding.hasActions))
        dispatch(setTypeActions(onBoarding.typeActions))
        dispatch(setWhyNotActions(onBoarding.whyNotActions))
        dispatch(setTeamMotivation(onBoarding.teamMotivation))
        dispatch(setTeamMotivationNote(onBoarding.teamMotivationNote))
        dispatch(setStakeholders(onBoarding.stakeholders))
        dispatch(setProjectContact(onBoarding.projectContact))
        dispatch(setProjectContactMail(onBoarding.projectContactMail))
        dispatch(setProjectContactPhone(onBoarding.projectContactPhone))
        dispatch(setProjectReferent(onBoarding.projectReferent))
        dispatch(setProjectReferentMail(onBoarding.projectReferentMail))
        dispatch(setProjectReferentPhone(onBoarding.projectReferentPhone))
    }
    if (props.showOnBoarding)
        return (
            <div className="main-page-main-div">
                <div style={{ height: "85.8vh", width: "100%", display: "flex", flexDirection: "row" }}>
                    <FilesOnBoarding current_page_on_boarding={props.current_page_on_boarding}
                        onBoardingState={props.onBoardingState}
                        cams={props.cams}
                        restaurant_name={props.restaurant_name}
                        on_boarding_done={props.on_boarding_done}
                        user_type={props.user_type} />
                </div>
            </div>
        )
    else
        return (
            <div className="main-page-main-div">
                <div style={{ height: "85.8vh", width: "100%", display: "flex", flexDirection: "row" }}>
                    <FilesDrop
                        restaurant_name={props.restaurant_name}
                        files_list={props.files_list}
                        selected_analysis={props.selected_analysis}
                        userState={props.userState}
                        analyzesSynthesis={props.analyzesSynthesis}
                        allAnalyzes={props.allAnalyzes}
                        selected_analyzes={props.selected_analyzes}
                        loadingFiles={props.loadingFiles} />
                    <FilesAnalyzes analyzesDatas={props.analyzesDatas}
                        selected_analysis={props.selected_analysis}
                        kitchenDatas={props.kitchenDatas}
                        on_boarding_done={props.on_boarding_done}
                        user_type={props.user_type}
                        restaurant_name={props.restaurant_name}
                        analyzesSynthesis={props.analyzesSynthesis}
                        allAnalyzes={props.allAnalyzes}
                        has_synthesis={props.has_synthesis}
                        userState={props.userState} />
                </div>
                {props.userState.user_type === "admin" &&
                    <>
                        <div style={{ height: "5vh" }} />
                        <div style={{ height: "25vh" }}>
                            <FilesConfirm
                                analyzesDatas={props.analyzesDatas}
                                kitchenDatas={props.kitchenDatas}
                                selected_analysis={props.selected_analysis}
                                restaurant_name={props.restaurant_name} />
                        </div>
                        <div style={{ height: "5vh" }} />
                        <div style={{ height: "85.8vh" }}>
                            <FilesNumberGuests
                                analyzesDatas={props.analyzesDatas}
                                kitchenDatas={props.kitchenDatas}
                                selected_analysis={props.selected_analysis}
                                userState={props.userState}
                                trays_by_day={props.trays_by_day}
                                selected_cam={props.selected_cam}
                                restaurant_name={props.restaurant_name}
                                realTrays={props.realTrays}
                                analyzesBaseDatas={props.analyzesBaseDatas} />
                        </div>
                        <div style={{ height: "10vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomButton
                                width={"25%"}
                                height={"50%"}
                                color={"#fff"}
                                backgroundColor={"#9CCEBE"}
                                onClick={() => dispatch(setShowOnBoarding(true))}
                            >{t('buttons.seeOnBoarding')}</CustomButton>
                        </div>
                    </>
                }
            </div>
        )
}
function mapStateToProps(state) {
    return {
        restaurant_name: state.userReducer.selected_restaurant,
        files_list: state.filesReducer.files_list,
        analyzesDatas: state.datasReducer.analyzesDatas,
        kitchenDatas: state.datasReducer.kitchenAnalyzesDatas,
        selected_analysis: state.filesReducer.selected_analysis,
        userState: state.userReducer,
        trays_by_day: state.datasReducer.trays_by_day,
        selected_cam: state.filesReducer.selected_cam,
        showOnBoarding: state.filesReducer.show_on_boarding,
        current_page_on_boarding: state.filesReducer.current_page_on_boarding,
        cams: state.userReducer.cams,
        onBoardingState: state.onBoardingReducer,
        on_boarding_done: state.filesReducer.on_boarding_done,
        user_type: state.userReducer.user_type,
        analyzesSynthesis: state.filesReducer.analyzesSynthesis,
        allAnalyzes: state.datasReducer.all_analyzes,
        has_synthesis: state.userReducer.has_synthesis,
        selected_analyzes: state.filesReducer.selected_analyzes,
        loadingFiles: state.filesReducer.loadingFiles,
        realTrays: state.filesReducer.realTrays,
        analyzesBaseDatas: state.datasReducer.analyzesBaseDatas
    };
}

export default connect(mapStateToProps)(Files);