import React from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";

import fr from 'date-fns/locale/fr';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('fr', fr)

const CustomDatePicker = (props) => {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center",cursor:"pointer" }}>
            <style>
                {`.date-input-field{
                    width:${props.width || "100%"};
                    height:100%;
                    text-align:center;
                    background-color:${props.backgroundColor || "white"};
                    color:${props.color || "#666666"};
                    border: none;
                    cursor:pointer;
                }
                .date-input-field:focus{
                outline:none;
                }`}
            </style>
            <DatePicker

                className='date-input-field'
                selected={props.selected || new Date()}
                onChange={props.onChange}
                highlightDates={props.highlightRange ? props.highlightRange : props.highlightDates}
                locale="fr"
                dateFormat="dd/MM/yyyy"
                popperPlacement={props.popper || "bottom"}
            />
        </div>)
}
export default CustomDatePicker;