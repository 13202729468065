export function setAllInformations(allInformations) {
    return {
        type: 'SET_ALL_INFORMATIONS',
        allInformations: allInformations
    }
}

export function setSelectedClient(selectedClient) {
    return {
        type: 'SET_SELECTED_CLIENT',
        selectedClient: selectedClient
    }
}

export function setAllClientAnalyzes(allClientAnalyzes) {
    return {
        type: 'SET_ALL_CLIENT_ANALYZES',
        allClientAnalyzes: allClientAnalyzes
    }
}

export function setDisplayedClient(displayedClient) {
    return {
        type: 'SET_DISPLAYED_CLIENT',
        displayedClient: displayedClient
    }
}

export function setSelectedInformations(selectedInformations) {
    return {
        type: 'SET_SELECTED_INFORMATIONS',
        selectedInformations: selectedInformations
    }
}

export function setDemoAccounts(demoAccounts) {
    return {
        type: 'SET_DEMO_ACCOUNTS',
        demoAccounts: demoAccounts
    }
}

export function setAllAccounts(allAccounts) {
    return {
        type: 'SET_ALL_ACCOUNTS',
        allAccounts: allAccounts
    }
}

export function setSelectedRestaurantModifs(selectedRestaurantModifs) {
    return {
        type: 'SET_SELECTED_RESTAURANT_MODIFS',
        selectedRestaurantModifs: selectedRestaurantModifs
    }
}

export function setNewClientName(newClientName) {
    return {
        type: 'SET_NEW_CLIENT_NAME',
        newClientName: newClientName
    }
}
export function setNewClientMail(newClientMail) {
    return {
        type: 'SET_NEW_CLIENT_MAIL',
        newClientMail: newClientMail
    }
}
export function setNewClientPassword(newClientPassword) {
    return {
        type: 'SET_NEW_CLIENT_PASSWORD',
        newClientPassword: newClientPassword
    }
}
export function setNewRestaurantName(newRestaurantName) {
    return {
        type: 'SET_NEW_RESTAURANT_NAME',
        newRestaurantName: newRestaurantName
    }
}
export function setNewRestaurantDisplayName(newRestaurantDisplayName) {
    return {
        type: 'SET_NEW_RESTAURANT_DISPLAY_NAME',
        newRestaurantDisplayName: newRestaurantDisplayName
    }
}
export function setNewRestaurantSynthesisName(newRestaurantSynthesisName) {
    return {
        type: 'SET_NEW_RESTAURANT_SYNTHESIS_NAME',
        newRestaurantSynthesisName: newRestaurantSynthesisName
    }
}
export function setNewRestaurantMail(newRestaurantMail) {
    return {
        type: 'SET_NEW_RESTAURANT_MAIL',
        newRestaurantMail: newRestaurantMail
    }
}
export function setNewRestaurantPassword(newRestaurantPassword) {
    return {
        type: 'SET_NEW_RESTAURANT_PASSWORD',
        newRestaurantPassword: newRestaurantPassword
    }
}
export function setNewRestaurantMorning(newRestaurantMorning) {
    return {
        type: 'SET_NEW_RESTAURANT_MORNING',
        newRestaurantMorning: newRestaurantMorning
    }
}
export function setNewRestaurantNoon(newRestaurantNoon) {
    return {
        type: 'SET_NEW_RESTAURANT_NOON',
        newRestaurantNoon: newRestaurantNoon
    }
}
export function setNewRestaurantEvening(newRestaurantEvening) {
    return {
        type: 'SET_NEW_RESTAURANT_EVENING',
        newRestaurantEvening: newRestaurantEvening
    }
}
export function setNewRestaurantSaturday(newRestaurantSaturday) {
    return {
        type: 'SET_NEW_RESTAURANT_SATURDAY',
        newRestaurantSaturday: newRestaurantSaturday
    }
}
export function setNewRestaurantSunday(newRestaurantSunday) {
    return {
        type: 'SET_NEW_RESTAURANT_SUNDAY',
        newRestaurantSunday: newRestaurantSunday
    }
}
export function setNewRestaurantMultipleCams(newRestaurantMultipleCams) {
    return {
        type: 'SET_NEW_RESTAURANT_MULTIPLE_CAMS',
        newRestaurantMultipleCams: newRestaurantMultipleCams
    }
}
export function setNewRestaurantCamsList(newRestaurantCamsList) {
    return {
        type: 'SET_NEW_RESTAURANT_CAMS_LIST',
        newRestaurantCamsList: newRestaurantCamsList
    }
}
export function setNewRestaurantHasTheoricNumber(newRestaurantHasTheoricNumber) {
    return {
        type: 'SET_NEW_RESTAURANT_HAS_THEORIC_NUMBER',
        newRestaurantHasTheoricNumber: newRestaurantHasTheoricNumber
    }
}
export function setNewRestaurantUnit(newRestaurantUnit) {
    return {
        type: 'SET_NEW_RESTAURANT_UNIT',
        newRestaurantUnit: newRestaurantUnit
    }
}
export function setNewRestaurantCostUnit(newRestaurantCostUnit) {
    return {
        type: 'SET_NEW_RESTAURANT_COST_UNIT',
        newRestaurantCostUnit: newRestaurantCostUnit
    }
}
export function setNewRestaurantAverageTrays(newRestaurantAverageTrays) {
    return {
        type: 'SET_NEW_RESTAURANT_AVERAGE_TRAYS',
        newRestaurantAverageTrays: newRestaurantAverageTrays
    }
}
export function setNewRestaurantHasRestaurant(newRestaurantHasRestaurant) {
    return {
        type: 'SET_NEW_RESTAURANT_HAS_RESTAURANT',
        newRestaurantHasRestaurant: newRestaurantHasRestaurant
    }
}
export function setNewRestaurantHasKitchen(newRestaurantHasKitchen) {
    return {
        type: "SET_NEW_RESTAURANT_HAS_KITCHEN",
        newRestaurantHasKitchen: newRestaurantHasKitchen
    }
}
export function setNewRestaurantNumberWeeksYear(newRestaurantNumberWeeksYear) {
    return {
        type: 'SET_NEW_RESTAURANT_NUMBER_WEEKS_YEAR',
        newRestaurantNumberWeeksYear: newRestaurantNumberWeeksYear
    }
}
export function resetNewRestaurant() {
    return {
        type: 'RESET_NEW_RESTAURANT'
    }
}
export function resetNewClient() {
    return {
        type: 'RESET_NEW_CLIENT'
    }
}
export function hardResetAdmin() {
    return {
        type: 'HARD_RESET_ADMIN'
    }
}