import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Warning } from "../../../../assets/warning.svg";

const DayTitle = (props) => {
    const { t } = useTranslation();
    if (props.analysis.isOver) {
        return (
            <div className="dashboard-analysis-title">
                {t('components.analysisTitle', {
                    analysisNumber: props.analysis.analysisNumber,
                    dateStart: t('date', { value: { "date": props.analysis.dateStart, "format": "DD/MM/YYYY" } }),
                    dateEnd: t('date', { value: { "date": props.analysis.dateEnd, "format": "DD/MM/YYYY" } })
                })}
            </div>
        )
    }
    else {
        return (
            <div style={{ width: "100%", height: "100%", paddingRight: "5%" }}>
                <div className="dashboard-analysis-title" style={{ color: "#fff" }}>
                    {t('components.pendingAnalysis')}
                </div>
                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                    <div className="dashboard-analysis-warning-icon">
                            <Warning className="dashboard-analysis-warning-style" />
                    </div>
                    <div className="dashboard-analysis-message-text">
                        {t('longTexts.pendingAnalysis')}
                    </div>
                </div>
            </div>
        )
    }
}

export default DayTitle;