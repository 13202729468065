import React, { useState, useEffect } from "react";
import CustomBlock from "../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { setSelectedImageSamplesBin, setWeighIns } from "../../../actions_reducers/actions/datas.action";
import CustomScrollDiv from "../../customComponents/CustomScrollBar";
import { ReactComponent as Cross } from "../../../assets/cross.svg";
import { ReactComponent as Pen } from "../../../assets/pen.svg";
import CustomButton from "../../customComponents/CustomButton";
import CustomDropdown from "../../customComponents/CustomDropdown";
import CustomTextField from "../../customComponents/CustomTextField";
import { ReactComponent as Arrow } from "../../../assets/arrow.svg";

import { addWeighInForRestaurantAndDate, getWeighInsForDate } from "../../../apiRoutes/kitchenWaste.routes";

import 'moment/locale/fr';
import { updateKitchenDatas } from "../../customFunctions/kitchenDatas.function";
import { deleteSamplesBin, updateSamplesBin } from "../../../apiRoutes/samplesBin.route";
import { getBaseUrls } from "../../customFunctions/user.function";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);


const WeighIns = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let baseUrl = getBaseUrls().urlBin;
    const [selectedDate, setSelectedDate] = useState(props.selectedDate || null);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [selectedFood, setSelectedFood] = useState(null);
    const [foodMass, setFoodMass] = useState(0);
    const [precision, setPrecision] = useState(null);
    const [weighInsDay, setWeighInsDay] = useState([]);
    const [selectedFoodDay, setSelectedFoodDay] = useState(t('foods.allFoods'));
    const [selectedImageRef, setSelectedImageRef] = useState(null);
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);

    useEffect(() => {
        if (selectedDate != null) {
            const weighInsDayTmp = props.weighIns.find(a => a.date === selectedDate);
            if (weighInsDayTmp && weighInsDayTmp.weighIns) {
                setWeighInsDay(weighInsDayTmp.weighIns.sort((a, b) => a.created_at.localeCompare(b.created_at)));
            }
            else
                getPicturesForDate();
        }
        else {
            let days = getDays();
            setSelectedDate(moment(days[0], "DD-MM-YYYY").format("YYYY-MM-DD"))
            getPicturesForDate();
        }
    }, [selectedDate]);

    const closeModalAdd = () => {
        setSelectedFood(null);
        setPrecision(null);
        setFoodMass(0);
        setModalAddOpen(false);
    }

    useEffect(() => {
        if (!props.selectedDate && props.kitchenAnalysis && props.kitchenAnalysis.days) {
            let allDatesTmp = props.kitchenAnalysis.days.map(item => moment(item.date).format("YYYY-MM-DD"))
            allDatesTmp.sort((a, b) => a.localeCompare(b));
            setSelectedDate(allDatesTmp[0]);
        }
    }, [])

    const getPicturesForDate = async () => {
        let restaurant_name = props.userState.selected_restaurant;
        if (selectedDate != null) {
            let weighIns = await getWeighInsForDate(restaurant_name, selectedDate);
            let foodsOfDay = getAlimList(weighIns);
            let dateWeighIns = {
                date: selectedDate,
                weighIns: weighIns.sort((a, b) => b.mass - a.mass),
                foodsOfDay: foodsOfDay
            };
            setWeighInsDay(dateWeighIns.weighIns.sort((a, b) => a.created_at.localeCompare(b.created_at)));
            let allWeighIns = JSON.parse(JSON.stringify(props.weighIns));
            allWeighIns.push(dateWeighIns);
            dispatch(setWeighIns(allWeighIns));
        }
    }

    const getAlimentIdOfDay = (pictures) => {
        let result = [];
        for (const picture of pictures) {
            if (!result.find(a => a === picture.aliment_id))
                result.push(picture.aliment_id);
        }
        return result;
    }
    const getAlimList = (pictures) => {
        let result = [];
        const alimentIds = getAlimentIdOfDay(pictures);
        for (const id of alimentIds) {
            let food = props.food_list_kitchen.find(a => parseInt(a.idFood) === parseInt(id));
            if (food && food.categoryFood)
                result.push(food.nameFood);
        }
        result.sort((a, b) => a.localeCompare(b))
        result.unshift(t("foods.Tous les aliments"));
        return result;

    }

    const modifSample = () => {
        let sample = weighInsDay.find(a => a.image_ref === selectedImageRef);
        setSelectedFood(sample.nameFood);
        setPrecision(sample.precision);
        setFoodMass(sample.mass);
        setModalAddOpen(true)
    }
    const getValues = () => {
        let result = [];
        const weighInsDayTest = props.weighIns.find(a => a.date === selectedDate);
        if (weighInsDay && weighInsDayTest) {
            for (const [index, weighIns] of weighInsDay.entries()) {
                if (t(`foods.${weighIns.nameFood}`) === selectedFoodDay || selectedFoodDay === t(`foods.allFoods`)) {
                    let image_ref = weighIns.image_ref;
                    result.push(
                        <div key={image_ref} id={image_ref} style={{ width: "100%", height: image_ref === "" ? "15%" : "50%", display: "flex", flexDirection: "row", backgroundColor: index % 2 === 0 && "#F0F0F0" }}>
                            {image_ref === "" ?
                                <>
                                    <div className="dashboard-weighins-row" style={{ width: "10%" }}>
                                        {t(`foods.${weighIns.nameFood}`)}
                                    </div>
                                    <div className="dashboard-weighins-row" style={{ width: "30%" }}>
                                        {weighIns.precision || t('components.endServiceWaste')}
                                    </div>
                                </>
                                :
                                <>
                                    <div id={image_ref} style={{ width: "30%", padding: "2%", cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={(e) => dispatch(setSelectedImageSamplesBin(e.target.id))} >
                                        <img id={image_ref} style={{ width: "70%", height: "auto" }} src={baseUrl + image_ref} />
                                    </div>
                                    <div id={image_ref} className="dashboard-weighins-row" style={{ width: "10%" }}>
                                        {t(`foods.${weighIns.nameFood}`)}
                                    </div>
                                </>
                            }
                            <div id={image_ref} className="dashboard-weighins-row" style={{ width: "10%" }}>
                                {t('mass', { "value": { "mass": weighIns.mass * 1000, "unit": "kg", fixed: 2 } })}
                            </div>
                            <div id={image_ref} className="dashboard-weighins-row" style={{ width: "10%" }}>
                                {t('currency', { "value": { cost: weighIns.cost, fixed: 2 } })}
                            </div>
                            <div id={image_ref} className="dashboard-weighins-row" style={{ width: "25%" }}>
                                {t('components.co2', { value: t('mass', { "value": { "mass": weighIns.co2 * 1000, "unit": "kg", fixed: 2 } }) })}
                            </div>
                            <div id={image_ref} className="dashboard-weighins-row" style={{ width: "15%", cursor: "pointer" }}>
                                <div id={image_ref} style={{
                                    width: "50%", height: "100%",
                                    display: "flex", justifyContent: "center", alignItems: "center"
                                }} onClick={(e) => {
                                    setSelectedImageRef(e.target.id);
                                    modifSample()
                                }}>
                                    <div id={image_ref} style={{
                                        borderRadius: "50%", width: "5.5vh", height: "5.5vh",
                                        backgroundColor: "#90C261", paddingTop: "1.5%", paddingRight: "1%"
                                    }}>
                                        <Pen id={image_ref} width={'110%'} height={"110%"} style={{ color: "#fff", alignSelf: "center" }} />
                                    </div>
                                </div>
                                <div id={image_ref} style={{
                                    width: "15vh", height: "9.5vh",
                                    display: "flex", justifyContent: "center", alignItems: "center",
                                    paddingTop: "5%"
                                }} onClick={(e) => {
                                    if (e.target.localName !== "path") {
                                        setSelectedImageRef(e.target.id);
                                        setModalDeleteOpen(true)
                                    }
                                }
                                }>
                                    <Cross id={image_ref} width={"100%"} height={"100%"} fill={"#EA881F"} />
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        }
        return result;
    }
    const getFoods = () => {
        let result = [];
        for (const food of props.food_list_kitchen) {
            result.push(t(`foods.${food.nameFood}`))
        }
        result.sort((a, b) => a.localeCompare(b))
        return result;
    }
    const getOrigin = () => {
        let result = [
            t('components.endServiceWaste'),
            t('components.productionWaste')
        ]
        return result;
    }
    const addNewWeighIn = async () => {
        let food = props.food_list_kitchen.find(a => t(`foods.${a.nameFood}`) === selectedFood);
        let restaurant_name = props.userState.selected_restaurant;
        let cost = parseFloat(foodMass) * parseFloat(food.costfood);
        let co2 = parseFloat(foodMass) * parseFloat(food.co2Food);
        let categoryType = food.category === "Déchets inévitables" ? "inevitable" : "evitable";
        let newWeighIn = {
            restaurant_name: restaurant_name,
            alimId: food.idFood,
            alimName: food.nameFood,
            category: food.categoryFood,
            mass: parseFloat(foodMass),
            cost: cost,
            co2: co2,
            precision: precision,
            created_at: selectedDate
        }
        if (selectedImageRef === null) {
            updateKitchenDatas(JSON.parse(JSON.stringify(props.kitchenDatas)), props.analysisNumber, newWeighIn, categoryType, "add", dispatch);
            await addWeighInForRestaurantAndDate(newWeighIn);
            setModalAddOpen(false);
        }
        else {
            await updateSamplesBin(restaurant_name, [newWeighIn]);
            setSelectedImageRef(null);
            setModalAddOpen(false);
        }
    }
    const updateFoodValue = (e) => {
        setSelectedFood(e.target.id);
    }
    const updatePrecisonValue = (e) => {
        setPrecision(e.target.id);
    }
    const updateFoodMass = (e) => {
        setFoodMass(e.target.value);
    }
    const checkKey = (e) => {
        if (props.selectedImage) {
            if (e.keyCode === 39) {
                nextUrl()
            }
            else if (e.keyCode === 37) {
                previousUrl()
            }
        }
    }
    document.onkeydown = checkKey;
    const previousUrl = () => {
        let newImageRef = null;
        let index = weighInsDay.findIndex(a => a.image_ref === props.selectedImage);
        if (index === 0) {
            newImageRef = weighInsDay[weighInsDay.length - 1].image_ref;
        }
        else {
            newImageRef = weighInsDay[index - 1].image_ref;
        }
        dispatch(setSelectedImageSamplesBin(newImageRef))
    }
    const nextUrl = () => {
        let newImageRef = null;
        let index = weighInsDay.findIndex(a => a.image_ref === props.selectedImage);
        if (index === weighInsDay.length - 1) {
            newImageRef = weighInsDay[0].image_ref;
        }
        else {
            newImageRef = weighInsDay[index + 1].image_ref;
        }
        dispatch(setSelectedImageSamplesBin(newImageRef))
    }
    const imageModal = () => {
        return (
            <CustomBlock id={props.selectedImage} width={"90%"} height={"95%"} right={"5%"} top={'2.5%'} display={props.selectedImage === null ? "none" : "block"}
                position={"absolute"} zIndex={20000000000}>
                <div id={props.selectedImage} style={{ height: "90%", width: "100%", display: "flex", flexDirection: "row" }}>
                    <div id={props.selectedImage} style={{ width: "10%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                        onClick={() => previousUrl()}>
                        <Arrow id={props.selectedImage} className="arrow-left" />
                    </div>
                    <div id={props.selectedImage} style={{ width: "80%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img id={props.selectedImage} src={baseUrl + props.selectedImage} style={{ width: "60%", height: "auto" }} />
                    </div>
                    <div id={props.selectedImage} style={{ width: "10%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                        onClick={() => nextUrl()}>
                        <Arrow id={props.selectedImage} className="arrow-right" />
                    </div>
                </div>
                <div id={props.selectedImage} style={{ height: "10%", width: "100%", display: "flex", flexDirection: "row" }}>
                    <div id={props.selectedImage} style={{ width: "10%" }} />
                    <div id={props.selectedImage} style={{ width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {"image_ref : " + props.selectedImage}
                    </div>
                    <div id={props.selectedImage} style={{ width: "10%" }} />
                </div>
            </CustomBlock>
        )
    }
    const getModalAdd = () => {
        return (
            <CustomBlock
                width={"80%"}
                height={"40%"}
                position={"absolute"}
                top={"20%"}
                left={"10%"}
                zIndex={100000000}
                display={modalAddOpen ? "block" : "none"}
            >
                <div style={{ width: "100%", height: "70%", display: "flex", flexDirection: "row", alignItems: "center", paddingLeft: "4%", paddingRight: "4%" }}>
                    <div style={{ width: "30%", height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomDropdown
                            width={'100%'}
                            height={'50%'}
                            backgroundColor={'#fff'}
                            displayedValue={selectedFood ? selectedFood : t('table.alim')}
                            selectedValue={selectedFood}
                            values={getFoods()}
                            onChange={updateFoodValue}
                            maxHeightDropdown={"40vh"}
                            arrowColor={"#6D6C7A"}
                            color={"#949494"}
                            border={"1px solid #6D6C7A"} />
                    </div>
                    <div style={{ width: "5%" }} />
                    <div style={{ width: "30%", height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomDropdown
                            width={'100%'}
                            height={'50%'}
                            backgroundColor={'#fff'}
                            displayedValue={precision ? precision : t('components.origin')}
                            selectedValue={precision}
                            values={getOrigin()}
                            onChange={updatePrecisonValue}
                            arrowColor={"#6D6C7A"}
                            color={"#949494"}
                            border={"1px solid #6D6C7A"} />
                    </div>
                    <div style={{ width: "5%" }} />
                    <div style={{ width: "30%", height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomTextField
                            width={'100%'}
                            height={'50%'}
                            paddingLeft={"2%"}
                            paddingRight={"2%"}
                            backgroundColor={"#fff"}
                            border={"1px solid #6D6C7A"}
                            type={"number"}
                            borderRadius={"10px"}
                            value={foodMass}
                            onChange={updateFoodMass}
                            placeholder={t('table.weight')} />
                    </div>
                </div>
                <div style={{ width: "100%", height: "30%", display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButton
                            width={"50%"}
                            height={"40%"}
                            backgroundColor={"#666666"}
                            color={"#fff"}
                            zIndex={100000000}
                            onClick={() => closeModalAdd(false)}>

                            {t('buttons.cancel')}
                        </CustomButton>
                    </div>
                    <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButton
                            width={"50%"}
                            height={"40%"}
                            backgroundColor={"#54b0e3"}
                            color={"#fff"}
                            zIndex={100000000}
                            onClick={() => addNewWeighIn()}>

                            {t('buttons.confirm')}
                        </CustomButton>
                    </div>
                </div>
            </CustomBlock>
        )
    }

    const closeModalDelete = () => {
        setSelectedImageRef(null);
        setModalDeleteOpen(false);
    }
    const deleteSample = async () => {
        let sample = weighInsDay.find(a => a.image_ref === selectedImageRef);
        await deleteSamplesBin([sample.created_at], props.userState.selected_restaurant)
        setSelectedImageRef(null);
        setModalDeleteOpen(false);
    }
    const getModalDelete = () => {
        return (
            <div style={{
                display: modalDeleteOpen ? "block" : "none",
                height: "100vh",
                width: "100vw",
                zIndex: 10000000000,
                position: "fixed",
                top: 0,
                right: 0,
                backgroundColor: "#66666670",
            }}>
                <CustomBlock
                    position={"fixed"}
                    width={"50vw"}
                    height={"auto"}
                    backgroundColor={"#fff"}
                    top={"20%"}
                    left={"30.5%"}
                    paddingBottom={"1%"}
                    paddingTop={"1%"}
                    paddingLeft={"2%"}
                    paddingRight={"2%"}
                >
                    <div style={{ width: "100%", height: "6vh", fontSize: "1.2vw", fontWeight: "500", color: "#666666" }}>
                        {t('components.confirmMessage')}
                    </div>
                    <div style={{ width: "100%", height: "6vh", color: "#666666" }}>
                        {t('components.confirmDeleteWeighIn')}
                    </div>
                    <div style={{ width: "100%", height: "7vh", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center" }}>
                            <CustomButton
                                width={"50%"}
                                height={"70%"}
                                color={"#fff"}
                                backgroundColor={"#72B9A2"}
                                onClick={() => deleteSample()}>{t('buttons.confirm')}
                            </CustomButton>
                        </div>
                        <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center" }}>
                            <CustomButton
                                width={"50%"}
                                height={"70%"}
                                color={"#fff"}
                                backgroundColor={"#666666"}
                                onClick={() => closeModalDelete()}>{t('buttons.cancel')}
                            </CustomButton>
                        </div>
                    </div>
                </CustomBlock>
            </div>
        )
    }
    const getDisplayedDropdownDayValue = () => {
        return t(`date`, { "value": { "date": selectedDate, "format": "DD-MM-YYYY" } })
    }
    const changeSelectedDate = (e) => {
        setSelectedDate(moment(e.target.id, "DD-MM-YYYY").format("YYYY-MM-DD"));
    }
    const getDays = () => {
        let result = [];
        if (props.analysis) {
            for (const day of props.analysis.days) {
                result.push(moment(day.date).format("DD-MM-YYYY"));
            }
        }
        result.sort((a, b) => a.localeCompare(b));
        return result;
    }
    const getFoodsOfDay = () => {
        let result = [];
        for (const weighIn of weighInsDay) {
            let nameFood = weighIn.nameFood
            if (!result.find(a => a === t(`foods.${nameFood}`)))
                result.push(t(`foods.${nameFood}`))
        }
        result.sort((a, b) => a.localeCompare(b))
        result.unshift(t('foods.allFoods'))
        return result;
    }
    const changeSelectedFood = (e) => {
        setSelectedFoodDay(e.target.id)
    }
    return (
        <CustomBlock width={'100%'} height={'100%'}>
            {getModalAdd()}
            {getModalDelete()}
            {imageModal()}
            <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "13%" }}>
                    <div className="dashboard-block-title" style={{ width: "30%", height: "100%", flexDirection: "column" }}>

                        {t('components.weighIns')}
                        <CustomButton marginTop={"2%"} height={"48%"} width={"50%"} backgroundColor={"#89CD85"} color={"#fff"} onClick={() => setModalAddOpen(true)}>
                            {t('buttons.addWeighIn')}
                        </CustomButton>
                    </div>
                    <div style={{ width: "40%", height: "100%", display: "flex", justifyContent: "center" }}>
                        {!props.selectedDate &&
                            <CustomDropdown width={"60%"} height={"45%"}
                                backgroundColor={'#68B4E3'}
                                displayedValue={getDisplayedDropdownDayValue()}
                                selectedValue={t(`date`, { "value": { "date": selectedDate, "format": "DD-MM-YYYY" } })}
                                values={getDays()}
                                onChange={changeSelectedDate}
                                dropdownUpdate={Math.random()}
                                arrowColor={"#fff"}
                                color={"#fff"}
                                border={"1px solid #68B4E3"} />}
                    </div>
                    <div style={{ width: "30%", height: "100%", display: "flex", justifyContent: "flex-end" }}>
                        <CustomDropdown
                            width={"60%"}
                            height={"45%"}
                            backgroundColor={"#fff"}
                            color={"#6D6C7A"}
                            arrowColor={"#6D6C7A"}
                            displayedValue={selectedFoodDay}
                            selectedValue={selectedFoodDay}
                            values={getFoodsOfDay()}
                            maxHeightDropdown={"35vh"}
                            dropdownUpdate={Math.random()}
                            onChange={changeSelectedFood}
                            border={"1px solid #6D6C7A"}
                        />
                    </div>
                </div>
                <div className="dashboard-weighins-headers">
                    {weighInsDay[0] && weighInsDay[0].image_ref === "" ?
                        <>
                            <div className="dashboard-weighins-header" style={{ width: "10%" }}>
                                {t('table.alim')}
                            </div>
                            <div className="dashboard-weighins-header" style={{ width: "30%" }}>
                                {t('table.origins')}
                            </div>
                        </>
                        : <>
                            <div className="dashboard-weighins-header" style={{ width: "30%" }}>
                                {t('table.picture')}
                            </div>
                            <div className="dashboard-weighins-header" style={{ width: "10%" }}>
                                {t('table.alim')}
                            </div>
                        </>}
                    <div className="dashboard-weighins-header" style={{ width: "10%" }}>
                        {t('table.weight')}
                    </div>
                    <div className="dashboard-weighins-header" style={{ width: "10%" }}>
                        {t('table.price')}
                    </div>
                    <div className="dashboard-weighins-header" style={{ width: "25%" }}>
                        {t('table.co2Mass')}
                    </div>
                    <div className="dashboard-weighins-header" style={{ width: "15%" }}>
                        {t('table.actions')}
                    </div>
                </div>
                <div className="dashboard-weighIns-body">
                    <CustomScrollDiv update={Math.random()}>
                        {getValues()}
                    </CustomScrollDiv>
                </div>
            </div>
        </CustomBlock>
    )
}
export default WeighIns;